import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from '@angular/material';
import { AbsenceComponent } from '../absence.component';
import { NgxSpinnerService } from 'ngx-spinner';
import * as jsonpatch from 'fast-json-patch';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Absence } from 'src/app/models/absence.model';
import { AbsenceService } from 'src/app/services/absence.service';
import { AbsenceType } from 'src/app/models/absence-type.model';
import { AbsenceTypeService } from 'src/app/services/absence-type.service';
import { Employee } from 'src/app/models/employee.model';
import { AccountService } from 'inzo-portalempleado';
import { EmployeeManagementService } from 'src/app/services/employee-management.service';
import { disableDay, disableWeekEnd, getArrayDateString, getDateTimeString, loadDatesStringFromArrayStringAnyFormat, transformDate } from 'src/app/helpers/date.helper';

import * as moment from 'moment';
import {NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { CommonResources } from 'src/app/models/common-resources';
import { WorkCalendar } from 'src/app/models/work-calendar.model';
import { AbsenceChangesService } from 'src/app/services/absence-changes.service';
import { AbsenceStateCode } from 'src/app/models/absence-state-code-enum.model';
import { AbsenceState } from 'src/app/models/absence-state.model';
import { WorkCalendarService } from 'src/app/services/work-calendar.service';
import { ListDates } from 'src/app/models/list-dates.model';
import { FiltersApi } from 'src/app/models/filters-api.model';

const DATE_FORMAT = 'YYYY-MM-DD HH:mm';

@Component({
  selector: 'app-absence-create-range-dialog',
  templateUrl: './absence-create-range-dialog.component.html',
  styleUrls: ['./absence-create-range-dialog.component.css'],
  providers: [
    { provide: NGX_MAT_DATE_FORMATS, useValue: CommonResources.CUSTOM_DATETIME_FORMATS },
  ],
})
export class AbsenceCreateRangeDialogComponent {
  formGroupControl: FormGroup;
  absenceStates: AbsenceState[] = [];

  showReason: boolean = false;

  allAbsenceTypes = [];
  absenceId: string;
  absenceName: string;
  absence: Absence = null;

  currentEmployee: Employee = null;
  disabledDays: any[] = [];
  edit = false;

  defaultStartDate = [new Date().getHours(), 0 , 0];
  defaultFinishDate = [new Date().getHours(), 0 , 0];

  selectedDates: ListDates = new ListDates(DATE_FORMAT, true);
  filtersApiAbsemceType: FiltersApi = new FiltersApi();

  // Permissions
  canDirectAssignment: boolean = false;
  directAssignment: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AbsenceComponent>,
    public absenceTypeService: AbsenceTypeService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private toastrService: ToastrService,
    private accountService: AccountService,
    private employeeManagementService: EmployeeManagementService,
    private absenceService: AbsenceService,
    private absenceChangesService: AbsenceChangesService,
    private workCalendarService: WorkCalendarService,
  ) { }

  ngOnInit() {
    this.formGroupControl = new FormGroup({
      description: new FormControl('', Validators.required),
      startDate: new FormControl('', Validators.required),
      finishDate: new FormControl('', Validators.required),
      absenceType: new FormControl('', Validators.required),
      state: new FormControl(''),
      reason: new FormControl(''),
    });

    this.absenceChangesService.canDirectAssignment.subscribe(checked => {
      this.canDirectAssignment = checked;
    });

    this.absenceChangesService.check.subscribe(checked => {
      this.directAssignment = checked;
    });

    this.filtersApiAbsemceType.add(
      {
        field: "isMedicalLeave",
        value: "false"
      }
    );

    // Ordenación
    this.filtersApiAbsemceType.add(
      {
        field: "code",
        value: "asc"
      }
    );

    this.absenceTypeService.getAllAbsenceTypes(this.filtersApiAbsemceType.getStrinFilterApi()).subscribe(absenceTypesResponse => {
      this.allAbsenceTypes = absenceTypesResponse.items;
    });

    const userId = this.accountService.currentUser.id;

    if (this.data.currentEmployee) {
      this.currentEmployee = this.data.currentEmployee;

      if (this.data.absenceStates) {
        this.absenceStates = this.data.absenceStates;
      }
    } else {
      this.employeeManagementService.getEmployeeByUserId(userId, true).subscribe(employee => {
        this.currentEmployee = employee;
      });
    }

    this.absenceId = this.data.absenceId;
    this.absenceName = this.data.absenceName;
    this.absence = this.data.absence;

    if (this.currentEmployee && this.currentEmployee.workCalendar) {
      this.loadDisabledDays(this.currentEmployee.workCalendar);
    } else if (this.currentEmployee) {
      this.workCalendarService.getWorkCalendar(this.currentEmployee.workCalendarId).subscribe(response => {
        this.currentEmployee.workCalendar = response;
        this.loadDisabledDays(this.currentEmployee.workCalendar);
      });
    }

    if (this.absence) {
      this.edit = true;
      this.formGroupControl.setValue({
        description: this.absence.description,
        startDate: this.absence.startDate,
        finishDate: this.absence.finishDate,
        absenceType: this.absence.absenceType,
        state: this.absence.absenceStateId,
        reason: this.absence.reason,
      });
    }
  }

  loadDisabledDays(workCalendar: WorkCalendar): void {
    const disabledDays = [];

    workCalendar.wcEvents.forEach(event => {
      let startDate = moment(event.startDate);
      let endDate = moment(event.endDate);
      while (startDate.isSameOrBefore(endDate)) {
        disabledDays.push({date: startDate.toDate()})
        startDate.add(1, 'days');
      }
    });

    this.disabledDays = disabledDays;
  }

  selectedDatesChange(event) {
    this.selectedDates = event;
    this.selectedDates.format = DATE_FORMAT;

    if (this.selectedDates.empty()) {
      this.formGroupControl.get("startDate").reset();
      this.formGroupControl.get("finishDate").reset();
    } else {
      this.formGroupControl.patchValue(
        {
          startDate: "ss",//this.selectedDates.first(),
          finishDate: this.selectedDates.last(),
        }
      );
    }
  }

  create(send: boolean = false) {
    this.spinner.show();

    this.absence = new Absence({
      description     : this.formGroupControl.get('description').value,
      startDate       : getDateTimeString(this.selectedDates.first()),
      finishDate      : getDateTimeString(this.selectedDates.last()),
      absenceTypeId   : this.formGroupControl.get('absenceType').value.absenceTypeId,
      employeeId      : this.currentEmployee.employeeId,
      requestedDates  : loadDatesStringFromArrayStringAnyFormat(this.selectedDates.toDatesStrings()),
    });

    if (this.directAssignment) {
      let absenceStateId = this.formGroupControl.get('state').value;
      let reason = this.formGroupControl.get('reason').value;

      this.absenceService.createAbsenceToEmployee(this.absence, absenceStateId, reason).subscribe(response => {
        this.absence = response as Absence;
        this.absence.absenceType = this.formGroupControl.get('absenceType').value;

        this.createSuccessMessage(this.absence);
      }, error => {
        this.createErrorMessage(error);
        this.spinner.hide();
      }, () => {
        this.spinner.hide();
        this.dialogRef.close(this.absence);
      });
    } else {
      if (send) {
        let absenceState = this.absenceStates.find(element => element.stateCode == AbsenceStateCode.PENDING_APROVAL);
        this.absence.absenceStateId = absenceState.absenceStateId;
      }

      this.absenceService.createAbsence(this.absence, send).subscribe(response => {
        this.absence = response as Absence;
        this.absence.absenceType = this.formGroupControl.get('absenceType').value;

        this.createSuccessMessage(this.absence);
      }, error => {
        this.createErrorMessage(error);
        this.spinner.hide();
      }, () => {
        this.spinner.hide();
        this.dialogRef.close(this.absence);
      });
    }
  }

  update() {
    const observer = jsonpatch.observe(this.absence);
    this.absence.description = this.formGroupControl.get('description').value;
    this.absence.startDate = getDateTimeString(this.selectedDates.first());
    this.absence.finishDate = getDateTimeString(this.selectedDates.last());
    this.absence.absenceTypeId = this.formGroupControl.get('absenceType').value.absenceTypeId;
    this.absence.requestedDates = this.selectedDates.toDatesStrings();

    if (this.directAssignment) {
      this.absence.absenceStateId = this.formGroupControl.get('state').value;
      this.absence.reason = this.formGroupControl.get('reason').value;
    }

    const patch = jsonpatch.generate(observer);

    this.absenceService.modifyAbsence(this.absence.absenceId, patch).subscribe(response => {
      this.absence = response as Absence;
      this.absence.absenceType = this.formGroupControl.get('absenceType').value;

    }, error => {
      this.createErrorMessage(error);
      this.spinner.hide();
    }, () => {
      this.spinner.hide();
      this.dialogRef.close(this.absence);
    });
  }

  createSuccessMessage(absence: Absence, timeMSG = 3000) {
    this.translate.get('ABSENCE_MAINTENANCE.NAME').subscribe((componentTranslated: string) => {
      let toastTitle = '';
      let toastBody = '';
      this.translate.get('MESSAGES.SUCCESS.UPDATE').subscribe((a: string) => {toastTitle = a});
      this.translate.get('MESSAGES.SUCCESS.UPDATE_LONG', {type: componentTranslated,code: absence.description}).subscribe((a: string) => {toastBody = a});
      this.toastrService.success(toastBody, toastTitle, { timeOut: timeMSG });
    });
  }

  createErrorMessage(error, timeMSG = 3000) {
    let errorOverlappingDates = false;
    let dateFormat = "DD-MM-YYYY";

    let title = "MESSAGES.ERROR.CREATE_CONFLICT";
    let message = "MESSAGES.ERROR.CREATE_CONFLITCT_BODY";

    if (error.error.type == "OVERLAPPING_DATES") {
      errorOverlappingDates = true;

      title = `MESSAGES.ERROR.${error.error.type}`;
      message = `MESSAGES.ERROR.${error.error.type}_BODY`;
    } else if (error.error.type == "error") {
      title = 'MESSAGES.ERROR.UNEXPECTED';
      message = 'MESSAGES.ERROR.UNEXPECTED_BODY';
    }

    this.translate.get(title).subscribe((transitionStr: string) => {
      title = transitionStr;
    });

    if (errorOverlappingDates) {
      this.translate.get(
        message,
        {
          absenceDates: getArrayDateString(error.error.absencesOverlappingDates, dateFormat),
          holidaysDates: getArrayDateString(error.error.holidaysRequestOverlappingDates, dateFormat),
        }
      ).subscribe((transitionStr: string) => {
        message = transitionStr;
      });
    } else {
      this.translate.get(message).subscribe((transitionStr: string) => {
        message = transitionStr;
      });
    }

    this.toastrService.error(message, title, { timeOut: timeMSG });
  }

  assingAbsenceType(e) {
    this.formGroupControl.get('absenceType').setValue(e.option.value);
  }

  displayAbsenceType(absenceType: AbsenceType): string | undefined {
    return (absenceType) ? absenceType.description : undefined;
  }

  setDate($event, startDate = true){
    let value = $event.value ?
      $event.value
      : moment(this.formGroupControl.get(startDate ? 'startDate' : 'finishDate').value);
    let temp: moment.Moment = value.set("seconds", 0);

    $event.value = temp;

    if (startDate) {
      this.defaultFinishDate = [temp.toDate().getHours(), 0 , 0];
    } else {
      this.defaultStartDate = [temp.toDate().getHours(), 0 , 0];
    }

    return $event;
  }

  /**
   * Comprueba los días que se deben deshabilitar del calendario
   *
   * @param d fecha en formato objeto momentjs
   * @returns
   */
  getDisabledDays (d: any | null): boolean {
    let result = true;
    d = moment(d);

    if ((d.day() == 0 || d.day() == 6)) {
      result = disableWeekEnd(d, this.currentEmployee.workCalendar);
    } else {
      result = disableDay(d, this.disabledDays);
    }

    return result;
  }

  getAbsenceState(state: string) {
    let currentState = null;

    for(let state of this.absenceStates) {
      if (state.absenceStateId == this.formGroupControl.get("state").value) {
        currentState = state;
        break;
      }
    }

    this.showReason = currentState.stateCode == AbsenceStateCode[state];
  }
}
