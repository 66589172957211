import { Component, OnInit, OnDestroy, ElementRef, ViewChild, EventEmitter, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { ResponseNotificationService, BaseListComponent, SecurityStateManagementService, AccountService, Hotkeys, Role } from 'inzo-portalempleado';
import { AppPermissions } from 'src/app/models/app-permission.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatPaginator, MatTableDataSource, PageEvent } from '@angular/material';
import { Employee } from 'src/app/models/employee.model';
import { EmployeeManagementService } from 'src/app/services/employee-management.service';
import { SelectionModel } from '@angular/cdk/collections';
import { FiltersApi } from 'src/app/models/filters-api.model';
import { debounceTime } from 'rxjs/operators';
import { EmployeeSituation } from 'src/app/models/employee-situation.model';
import { EmployeeSituationService } from 'src/app/services/employee-situation.service';
import { EmployeeSituationCode } from 'src/app/models/employee-situation-enum.model';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IUserRolesPermissions } from 'src/app/modules/pe-common/interfaces/permissions.interface';
import * as _ from 'lodash';

@Component({
  selector: 'app-employeeManagement-list',
  templateUrl: 'employeeManagement-list.component.html',
  styleUrls: ['./../../maintenance.components.css', '../../maintenance-list.component.css', './employeeManagement-list.component.css'],
})
export class EmployeeManagementListComponent extends BaseListComponent<Employee> implements OnInit, OnDestroy {
  displayedColumns: string[] = [
    'code',
    'firstSurname',
    'secondSurname',
    'name',
    'documentType',
    'documentNumber',
    'companyEmail',
    'companyPhone',
  ];
  searchEmployeeCriteria: string[] = this.displayedColumns;
  employees: Employee[] = [];
  employeeSituations: EmployeeSituation[];
  employeeSituationsFiltered: EmployeeSituation[];
  defaultEmployeeFilter: EmployeeSituationCode = EmployeeSituationCode.ACTIVE;

  subscriptions: Subscription[] = [];
  selection;
  isManager = false;
  enabled = false;

  roles: Role[];

  selected;

  mediaSize: string;
  watcher: Subscription;
  canAdd = false;
  canDelete = false;

  userRolesPermissions: IUserRolesPermissions = {
    isAdmin: false,
    isRRHH: false,
    isRA: false,
    isRP: false,
    isEmployee: false,
  }

  filtersApiEmployee: FiltersApi = new FiltersApi();

  dataSource: MatTableDataSource<Employee> = new MatTableDataSource([]);
  dataPageSize: number = 10;
  pageSizeOptions = [10, 20, 30, 40];
  pageIndex = 0;
  pageSize  = 0;
  pageLength  = 0;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  // @ViewChild(MatPaginator, {static: false})
  // set paginator(value: MatPaginator) {
  //   this.dataSource.paginator = value;
  // }

  filterState = new FormGroup(
    {
      state: new FormControl(''),
    }
  );
  // Search
  @Output() onDebounce : EventEmitter<string> = new EventEmitter();
  debouncer: Subject<string> = new Subject();
  searching = true;
  filter = '';

  constructor(
    private employeeManagementService: EmployeeManagementService,
    private employeeSituationService: EmployeeSituationService,
    public route: ActivatedRoute,
    public router: Router,
    public SSMService: SecurityStateManagementService,
    private RNService: ResponseNotificationService,
    public accountService: AccountService,
    public hotkeys: Hotkeys,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
  ) {
    super(accountService, SSMService, router, hotkeys);

    this.baseRoute = '/managements/employeeManagement';
    this.selection = new SelectionModel<Employee>(false, []);

    this.viewPermission = [
      AppPermissions.ROLE_DATA.RRHH,
      AppPermissions.ROLE_DATA.RA,
      AppPermissions.ROLE_DATA.RP,
    ];
    this.createPermission = [
      AppPermissions.ROLE_DATA.RRHH,
    ];
    this.deletePermission = [
      AppPermissions.ROLE_DATA.RRHH,
    ];

    if (this.accountService) {
      this.roles = this.accountService.roles;
    }

    this.onBuild = () => {
    };

    this.setApiPaginator(0);
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.subscriptions.push(
      this.employeeManagementService.employeeManagementObservable.subscribe(employees => {
        this.employees = employees;
      })
    );

    this.subscriptions.push(
      this.debouncer
        .pipe(debounceTime(300))
        .subscribe((filter) => {
          this.applyFilter(filter);
        })
    );

    this.registerPermissionsLoadedSubscription();
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }

    if (this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  registerPermissionsLoadedSubscription() {
    if (!this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription = this.accountService.userPermissionsLoaded.subscribe(() => {
        this.accountService.userRolesLoaded.subscribe((rolesResponse) => {
          this.roles = this.accountService.roles;

          this.assignPermissions();
          this.loadEmployeeFilters();
        });
      });
    } else {
      this.assignPermissions();
      this.loadEmployeeFilters();
    }
  }

  assignPermissions() {
    this.userRolesPermissions.isAdmin = this.accountService.currentUser.isAdmin;
    if (this.roles != undefined && this.roles != null) {
      this.userRolesPermissions.isRRHH = this.roles.some(x => x.name === AppPermissions.ROLE_DATA.RRHH);
      this.userRolesPermissions.isRA = this.roles.some(x => x.name === AppPermissions.ROLE_DATA.RA);
      this.userRolesPermissions.isRP = this.roles.some(x => x.name === AppPermissions.ROLE_DATA.RP);
    }

    if (
      !this.userRolesPermissions.isAdmin &&
      (
        this.userRolesPermissions.isRA ||
        this.userRolesPermissions.isRP
      )
    ) {
      this.isManager = true;
      this.enabled = true;
    }

    if (
      this.userRolesPermissions.isAdmin ||
      this.userRolesPermissions.isRRHH
    ) {
      this.canAdd = true;
      this.canDelete = true;
      this.enabled = true;
      this.isManager = false;
    }
  }
  //#endregion
  loadEmployeeFilters() {
    this.subscriptions.push(
      this.employeeSituationService.getAllEmployeeSituation().subscribe(response => {
        this.employeeSituations = response.items;
        this.employeeSituationsFiltered = _.filter(this.employeeSituations, item => item.stateCode != EmployeeSituationCode.NONE);
        let selectedFilter = this.employeeSituationsFiltered.find(item => item.stateCode == this.defaultEmployeeFilter);

        this.filterState.get('state').setValue(selectedFilter);
        this.filtersApiEmployee.add(
          {
            field: "Situation",
            value: EmployeeSituationCode[selectedFilter.stateCode]
          },
          true
        );

        this.loadEmployees();
      })
    );
  }

  loadEmployees() {
    // this.spinner.show();

    // this.subscriptions.push(
    //   this.employeeManagementService.getAllEmployees(this.filtersApiEmployee.getStrinFilterApi()).subscribe(employees => {
    //     this.employeeManagementService.updateEmployeeManagements(employees.items);

    //     const {
    //       items,
    //       currentPage,
    //       pageSize,
    //       totalCount,
    //       ...resto
    //     } = employees;

    //     this.dataSource.data = items;

    //     this.pageIndex = (currentPage > 0)
    //       ? currentPage - 1
    //       : currentPage; // El contador de páginas de Inzo empieza en 1 y el de MatPaginator en 0
    //     this.pageSize = pageSize;
    //     this.pageLength = totalCount;

    //     this.dataSource.filterPredicate = (data: Employee, filter: string): boolean => {
    //       const dataStr = Object.keys(data).reduce((currentTerm: string, key: string) => {
    //         return (currentTerm + (data as { [key: string]: any })[key] + '◬');
    //       }, '').normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

    //       const transformedFilter = filter.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();


    //       return dataStr.indexOf(transformedFilter) !== -1;
    //     };

    //     this.spinner.hide();
    //   }, error => {
    //     this.spinner.hide();
    //     this.RNService.showError(error);
    //   })
    // );
    this.spinner.hide();
  }

  //#region ASSIGNMENT AND DISPLAY METHODS
  /* ################################################################################################################## */
  /* ## ASSIGNMENT AND DISPLAY METHODS
  /* ################################################################################################################## */
  /* ****************************************************************************************************************** */
  /* ** FILTERS
  /* ****************************************************************************************************************** */
  selectEmployee(event) {
    this.router.navigateByUrl(`${this.baseRoute}/${event.employee.employeeId}`);
  }

  keyFilterPressed() {
    this.debouncer.next(this.filter);
  }

  applyFilter(filterValue: string) {
    // this.dataSource.filter = filterValue.trim().toLowerCase();
    // this.resetPaginator();

    if (filterValue === "") {
      this.filtersApiEmployee.delete("filtersOr");
    } else {
      this.filtersApiEmployee.add(
        {
          field: "filtersOr",
          value: "true"
        },
        true
      );
    }

    for(let searchCriteria of this.searchEmployeeCriteria) {
      if (filterValue === "") {
        this.filtersApiEmployee.delete(searchCriteria);
      } else {
        this.filtersApiEmployee.add(
          {
            field: searchCriteria,
            value: filterValue
          },
          true
        );
      }
    }

    this.loadEmployees();
  }

  employeeFilter(value) {
    this.spinner.show();

    if (value == "all") {
      this.filtersApiEmployee.delete("Situation")
    } else {
      this.filtersApiEmployee.add(
        {
          field: "Situation",
          value: EmployeeSituationCode[value.stateCode]
        },
        true
      );
    }

    this.loadEmployees();
  }

  getEmployeeSituationFilter(item: EmployeeSituation) {
    let result = "";

    if (item != undefined && item != null) {
      this.translate.get(`EMPLOYEE_STATE.LABEL_STATECODE.${EmployeeSituationCode[item.stateCode]}`).subscribe((a: string) => {
        result = a;
      });
    }

    return result;
  }
  //#endregion

  //#region PAGINATOR
  /* ################################################################################################################## */
  /* ## PAGINATOR
  /* ################################################################################################################## */
  setApiPaginator(currentPage: number, pageSize: number = this.dataPageSize) {
    if (pageSize != 0) {
      this.filtersApiEmployee.add(
        {
          field: "currentPage",
          value: currentPage.toString()
        },
        true
      );
      this.filtersApiEmployee.add(
        {
          field: "pageSize",
          value: pageSize.toString()
        },
        true
      );
    } else {
      this.resetPaginator();
    }
  }

  resetPaginator() {
    this.paginator.firstPage();
    this.paginator.length = 0;
    this.paginator.pageSize = this.dataPageSize;

    this.filtersApiEmployee.add(
      {
        field: "currentPage",
        value: "0"
      },
      true
    );
    this.filtersApiEmployee.add(
      {
        field: "pageSize",
        value: this.dataPageSize.toString()
      },
      true
    );
  }

  getEmployeesPage(event: PageEvent): PageEvent {
    let goPage = this.paginator.pageIndex - 1;

    if (event.pageIndex > this.pageIndex) {
      goPage = this.paginator.pageIndex + 1;
    }

    goPage = (goPage < 0)
      ? 0
      : goPage;

    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.pageLength = event.length;

    this.setApiPaginator(goPage, this.paginator.pageSize);

    this.loadEmployees();
    return event;
  }
  //#endregion

  //#region UTILS
  /* ################################################################################################################## */
  /* ## UTILS
  /* ################################################################################################################## */
  //#endregion
}
