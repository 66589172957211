import { Area } from "./area.model";
import { Employee } from './employee.model';

export class AreaManagerHistory {
  areaManagerHistoryId  : string;
  startDate             : string;
  finishDate?           : string;

  //Relations
  areaId                : string;
  area                  : Area;
  managerId             : string;
  manager               : Employee;

  constructor(obj?: Partial<AreaManagerHistory>) {
    Object.assign(this, obj);
  }
}
