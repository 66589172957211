import { FilterApi } from '../interfaces/filter-api.interface';
import { encode } from 'url-encode-decode';
import { IncludesApi } from './includes-api.model';

export class FiltersApi {
  filters: FilterApi[] = [];
  includes: IncludesApi = new IncludesApi();

  constructor() { }

  /**
   * Añade un include nuevo.
   *
   * @param include nuevo include
   * @returns boolean con el resultado de la operación
   */
  addInclude(include: string) {
    this.includes.add(include);
  }

  /**
   * Elimina el filtro con el nombre especificado.
   *
   * @param includeName nombre del filtro a eliminar
   * @returns boolean con el resultado de la operación
   */
  deleteInclude(includeName: string) {
    return this.includes.delete(includeName);
  }

  /**
   * Añade un filtro nuevo.
   *
   * @param filterApi nuevo filtro
   * @param replace indica que si el filtro que se pretende añadir ya existe se debe actualizar
   * @returns boolean con el resultado de la operación
   */
  add(filterApi: FilterApi, replace = false) {
    let result = false;

    if (replace) {
      result = this.replace(filterApi, replace);
    } else {
      let item = this.search(filterApi.field);

      if (!item) {
        this.filters.push(filterApi);
        result = true;
      }
    }

    return result;
  }

  /**
   * Añade un filtro nuevo con un array de valores.
   *
   * @param filterName nombre del nuevo filtro
   * @param multipleValues lista de valores a asignar
   * @param replace indica que si el filtro que se pretende añadir ya existe se debe actualizar
   * @param serialize indica si se deben serializar los valores del filtro
   * @returns boolean con el resultado de la operación
   */
  addMultipleValue(filterName: string, multipleValues: string[] = [], replace = false, serialize = false) {
    let filterApi: FilterApi;
    let result = false;

    if (multipleValues.length > 0) {
      filterApi =
        {
          field: filterName,
          value: !serialize
            ? `[${multipleValues.join(",")}]`
            : JSON.stringify(multipleValues)
        };

      result = this.add(filterApi, replace);
    }

    return result;
  }

  /**
   * Elimina el filtro con el nombre especificado.
   *
   * @param filterName nombre del filtro a eliminar
   * @returns boolean con el resultado de la operación
   */
  delete(filterName: string) {
    let result = false;

    let item = this.search(filterName);

    if (item) {
      const index = this.filters.indexOf(item);

      if (index > -1) {
        this.filters.splice(index, 1);
        result = true;
      }
    }

    return result;
  }

  /**
   * Elimina todos ls filtros.
   */
  reset(): void {
    this.filters = [];
  }

  /**
   * Reemplaza un filtro con la información del filtro actualizado.
   *
   * @param filterApi filtro actualizado
   * @param addNew indica que si el filtro que se pretende sustituir no existe se debe añadir
   * @returns boolean con el resultado de la operación
   */
  replace(filterApi: FilterApi, addNew = false) {
    let result = false;

    let item = this.search(filterApi.field);

    if (item) {
      const index = this.filters.indexOf(item);

      if (index > -1) {
        this.filters[index]= filterApi;
        result = true;
      }
    } else if (addNew) {
      this.filters.push(filterApi);
      result = true;
    }

    return result;
  }

  /**
   * Busca el filtro pasado en el array.
   *
   * @param filterName key del filtro a buscar
   * @returns filtro
   */
  search(filterName: string): FilterApi {
    let result: FilterApi = null;

    for (let item of this.filters) {
      if (item.field == filterName) {
        result = item;
        break;
      }
    }

    return result;
  }

  /**
   * Transforma el array de filtros en un string para encadenar en una url.
   *
   * @returns un string válido para incluír en una petición get
   */
  getStrinFilterApi() {
    let result = ``;
    let filtersAdded = new Array();

    for (let i = 0; i < this.filters.length; i++) {
      let item = this.filters[i];

      if (!filtersAdded.includes(item.field)) {
        filtersAdded.push(item.field);

        if (i == 0) {
          result += "?";
        } else {
          result += "&";
        }

        result += `${item.field}=${encode(item.value)}`;
      }
    }

    if (this.includes.includes.length > 0) {
      let firstChar = false;

      if (result.length == 0) {
        firstChar = true;
      } else {
        result += "&";
      }

      result += `${this.includes.getStrinIncludeApi(firstChar)}`
    }

    return result;
  }
}
