import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import * as jsonpatch from 'fast-json-patch';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { BehaviorSubject, forkJoin, Observable, Subscription } from 'rxjs';
import { BaseFormComponent, AccountService, Hotkeys, ResponseNotificationService, Permission, Role } from 'inzo-portalempleado';
import { AppPermissions } from 'src/app/models/app-permission.model';
import { Employee } from 'src/app/models/employee.model';
import { EmployeeManagementService } from 'src/app/services/employee-management.service';
import { Address } from 'src/app/models/address.model';
import { DateAdapter, MatDialog, MatHorizontalStepper, MAT_DATE_FORMATS } from '@angular/material';
import { DcrDialogComponent } from '../../dcr/dcr-dialog/dcr-dialog.component';
import { AttributeChange } from 'src/app/models/attribute-chage.model';
import { DataChangeRequest } from 'src/app/models/data-chage-request.model';
import { DCRState, DCRStateCode } from 'src/app/models/dcr-state.model';
import { DCRStateService } from 'src/app/services/dcr-state.service';
import { DataChangeRequestService } from 'src/app/services/data-change-request.service';
import { CommonResources } from 'src/app/models/common-resources';
import { checkFormDate, getDateString, getMinDate } from 'src/app/helpers/date.helper';
import { LanguageService } from 'src/app/services/language.service';
import { DegreeService } from 'src/app/services/degree.service';
import { EmployeeManagementChangesService } from 'src/app/services/employee-management-changes.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Language } from 'src/app/models/language.model';
import { Degree } from 'src/app/models/degree.model';
import { map, startWith } from 'rxjs/operators';
import { IdentificationDocumentFormValidator } from 'src/app/helpers/identificationDocumentValidator';
import { BankAccountFormValidator } from 'src/app/helpers/accountBankValidator';
import { NSSFormValidator } from 'src/app/helpers/nssValidator';
import { TCRDialogComponent, IInfoTCRDialog } from '../tcr-dialog/tcr-dialog.component';
import { TrainingChangeRequest } from 'src/app/models/training-change-request.model';
import { TCRStateService } from 'src/app/services/tcr-state.service';
import { TCRState, TCRStateCode } from 'src/app/models/tcr-state.model';
import { TrainingChangeRequestService } from 'src/app/services/training-change-request.service';
import { Course } from '../../../models/course.model';
import { CourseService } from 'src/app/services/course.service';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { STEPPER_GLOBAL_OPTIONS, STEP_STATE } from '@angular/cdk/stepper';
import { Country } from 'src/app/models/country.model';
import { EmployeeManagementInfo } from 'src/app/models/employee-management-info.model';
import { WorkCalendar } from 'src/app/models/work-calendar.model';
import { Headquarters } from 'src/app/models/headquarters.model';
import { Centre } from 'src/app/models/centre.model';
import { Category } from 'src/app/models/category.model';
import { Area } from 'src/app/models/area.model';
import { EmployeeHistoryDlgComponent } from '../../employee-history/employee-history-dialog/employee-history-dialog.component';
import { EmployeeService } from '../../../services/employee.service';
import moment = require('moment');

@Component({
  selector: 'app-personal-data-form',
  templateUrl: 'personal-data-form.component.html',
  styleUrls: [
    './personal-data-form.component.css',
    './../../maintenance.components.css'
  ],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: CommonResources.CUSTOM_DATE_FORMAT },
    { provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false } },
  ],
})
export class PersonalDataFormComponent extends BaseFormComponent<Employee> implements OnInit, OnDestroy {
  //#region ATRRIBUTES
  /* ################################################################################################################## */
  /* ## ATRRIBUTES
  /* ################################################################################################################## */
  //#region CUSTOM
  employeeView: boolean = true;
  employeeManagementInfo: EmployeeManagementInfo;
  selectedDesc: string = undefined;
  employee: Employee;
  homeAddress: Address;

  disabilityChecked: boolean;

  roles: Role[];

  employeeSubject : BehaviorSubject<Employee>;
  private employeeSubscription: Subscription;

  // --------------------------------------------------------------------------------------------------------------
  loadFormationsData;

  // CV
  addCV: boolean = false;

  // COURSES
  addCourse: boolean = false;
  allCourses: Course[] = [];
  certificationCoursesId = [];
  coursesId = [];
  certificationCourseByEmployee: Course[] = [];
  courseByEmployee: Course[] = [];
  filteredCourses: Observable<Course[]>;
  filteredCertificationCourses: Observable<Course[]>;

  // DEGREES
  addDegree: boolean = false;
  allDegrees: Degree[] = [];
  degreesId = [];
  degreeByEmployee: Degree[] = [];
  filteredDegrees: Observable<Degree[]>;

  // LANGUAGES
  addLanguage: boolean = false;
  allLanguages: Language[] = [];
  languagesId = [];
  languageByEmployee: Language[] = [];
  filteredLanguages: Observable<Language[]>;
  // --------------------------------------------------------------------------------------------------------------

  filteredCountries: Observable<Country[]>;

  dcrStates: DCRState[] = [];
  tcrStates: TCRState[] = [];

  canAdd = false;

  enabled = true;
  detail = false;
  onEdit = false;
  editable:boolean = false;
  permissionsLoaded = false;
  permissionList: Permission[];
  userPermission: Permission;

  subscriptions: Subscription[] = [];

  genders = CommonResources.GENDERS;
  identiDocumTypes = CommonResources.IDENTIFICATION_DOCUMENT_TYPES;
  disabilityPercentages = CommonResources.DISABILITY_PERCENTAGES;

  @ViewChild('stepper', { static: false }) stepper: MatHorizontalStepper;
  //#endregion

  //#region INHERITED
  /* ****************************************************************************************************************** */
  /* ** INHERITED
  /* ****************************************************************************************************************** */
  canUpdate = false;
  canDelete = false;

  componentTypeTranslated: string;
  //#endregion
  //#endregion

  //#region FORMS
  /* ****************************************************************************************************************** */
  /* ** FORMS
  /* ****************************************************************************************************************** */
 //#region ProfileFormGroup
  // ------------------------------------------------------------------------------------------------------------------
  // ProfileFormGroup
  // ------------------------------------------------------------------------------------------------------------------
  ProfileFormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    firstSurname: new FormControl('', Validators.required),
    secondSurname: new FormControl('', Validators.required),
    documentType: new FormControl('', Validators.required),
    documentNumber: new FormControl('',Validators.required),
    documentExpirationDate: new FormControl(new Date(), Validators.required),
    gender: new FormControl('',Validators.required),
    nss: new FormControl('',Validators.required),
    country: new FormControl('', Validators.required),
    birthdate: new FormControl(new Date(), Validators.required),
    birthplace: new FormControl(''),
    birthplaceProvince: new FormControl(''),
    disability: new FormControl(''),
    disabilityPercentage: new FormControl(''),
    bankAccount: new FormControl('', Validators.required),
    code: new FormControl('', Validators.required),

    // CONTACT DATA
    personalEmail: new FormControl('', [Validators.required, Validators.pattern(CommonResources.EMAILPATTERN)]),
    companyEmail: new FormControl('', Validators.pattern(CommonResources.EMAILPATTERN)),
    personalPhone: new FormControl('', [Validators.required, Validators.pattern(CommonResources.PHONEPATTERN)]),
    companyPhone: new FormControl('', Validators.pattern(CommonResources.PHONEPATTERN)),
  }, { validators: [IdentificationDocumentFormValidator(),BankAccountFormValidator(),NSSFormValidator()] });
  //#endregion

  //#region HomeAddressFormGroup
  // ------------------------------------------------------------------------------------------------------------------
  // HomeAddressFormGroup
  // ------------------------------------------------------------------------------------------------------------------
  HomeAddressFormGroup = new FormGroup({
    country: new FormControl('', Validators.required),
    flat: new FormControl('', Validators.required),
    location: new FormControl('', Validators.required),
    location2: new FormControl('', Validators.required),
    number: new FormControl('', Validators.required),
    portal: new FormControl('', Validators.required),
    postalCode: new FormControl('', Validators.required),
    province: new FormControl('', Validators.required),
    street: new FormControl('', Validators.required),
    typeStreet: new FormControl('', Validators.required),
  });
  //#endregion

  //#region CompanyFormGroup
  // ------------------------------------------------------------------------------------------------------------------
  // CompanyFormGroup
  // ------------------------------------------------------------------------------------------------------------------
  CompanyFormGroup = new FormGroup({
    area: new FormControl('', Validators.required),
    centre: new FormControl(''),
    headquarter: new FormControl(''),
    manager: new FormControl(''),
    validator: new FormControl(''),
    user: new FormControl('', Validators.required),
    workcalendar: new FormControl(''),
  });
  //#endregion

  //#region FormationsFormGroup
  // ------------------------------------------------------------------------------------------------------------------
  // FormationsFormGroup
  // ------------------------------------------------------------------------------------------------------------------
  FormationsFormGroup = new FormGroup({
    certificationCourse: new FormControl(''),
    course: new FormControl(''),
    language: new FormControl(''),
    languageCategory: new FormControl(''),
    degree: new FormControl(''),
    degreeCode: new FormControl(''),
  });
  //#endregion

  //#region ContractualDataFormGroup
  // ------------------------------------------------------------------------------------------------------------------
  // ContractualDataFormGroup
  // ------------------------------------------------------------------------------------------------------------------
  ContractualDataFormGroup = new FormGroup({
    situation: new FormControl({value: '', disabled: true}),
    registrationDate: new FormControl(new Date(), Validators.required),
    leaveDate: new FormControl(new Date()),
    seniorityDate: new FormControl(new Date()),

    category: new FormControl(''),
    quotationGroupEquivalence: new FormControl('', Validators.required),
  });
  //#endregion

  //#region SbaDataFormGroup
  // ------------------------------------------------------------------------------------------------------------------
  // SbaDataFormGroup
  // ------------------------------------------------------------------------------------------------------------------
  SbaDataFormGroup = new FormGroup({
    startDate: new FormControl(''),
    coefficient: new FormControl(''),
    sba: new FormControl(''),
  });
  //#endregion

  //#region EmployeeHistoryFormGroup
  // ------------------------------------------------------------------------------------------------------------------
  // EmployeeHistoryFormGroup
  // ------------------------------------------------------------------------------------------------------------------
  EmployeeHistoryFormGroup  = new FormGroup({});
  //#endregion
  //#endregion

  //#region CONSTRUCTOR
  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    private dcrDialog: MatDialog,
    private dcrStateService: DCRStateService,
    private tcrStateService: TCRStateService,
    private dataChangeRequestService: DataChangeRequestService,
    private trainingChangeRequestService: TrainingChangeRequestService,
    private toastrService: ToastrService,
    private employeeService: EmployeeService,
    public accountService: AccountService,
    public router: Router,
    public hotkeys: Hotkeys,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private RNService: ResponseNotificationService,
    private employeeManagementChanges: EmployeeManagementChangesService,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
  ) {
    super(accountService, router, hotkeys);

    this.baseRoute = 'personal_data';

    this.employee = new Employee();

    // Para observar los cambios en this.employee y actualizar su estado
    this.employeeSubject = new BehaviorSubject(this.employee);
    this.employeeSubscription = this.employeeSubject.subscribe((changes) => {
      this.employee = changes;
    });

    this.viewPermission = [
      AppPermissions.ROLE_DATA.RRHH,
      AppPermissions.ROLE_DATA.RA,
      AppPermissions.ROLE_DATA.RP,
      AppPermissions.ROLE_DATA.Empleado
    ];
    this.createPermission = [
      AppPermissions.ROLE_DATA.RRHH,
      AppPermissions.ROLE_DATA.RA,
      AppPermissions.ROLE_DATA.RP,
      AppPermissions.ROLE_DATA.Empleado
    ];
    this.deletePermission = [
      AppPermissions.ROLE_DATA.RRHH,
      AppPermissions.ROLE_DATA.RA,
      AppPermissions.ROLE_DATA.RP,
      AppPermissions.ROLE_DATA.Empleado
    ];
    if (this.accountService.userHasPermission(AppPermissions.ROLE_DATA.RRHH) ||
      this.accountService.userHasPermission(AppPermissions.ROLE_DATA.RA) ||
      this.accountService.userHasPermission(AppPermissions.ROLE_DATA.RP) ||
      this.accountService.userHasPermission(AppPermissions.ROLE_DATA.Empleado)
    ) {
      this.canAdd = true;
      this.canDelete = true;
      this.employeeView = true;
    }

    this.permissionList = [
      new Permission("Employee", "Employee", "Employee Permissions", "Permiso para empleado"),
      new Permission("Manager", "Manager", "Manager Permissions", "Permiso para manager"),
      new Permission("RRHH", "RRHH", "RRHH Permissions", "Permiso para rrhh")
    ]

    this.PK = 'employeeId';

    this.onCleanForm = () => {
    };

    this.onCancelForm = () => {
    };

    this.onEnableForm = () => {
      if (this.enabled) {
      }
    };

    this.onInit = this.init;
    this.translate.get('EMPLOYEE_MAINTENANCE.LIST.HEADER').subscribe((a: string) => {
      this.componentTypeTranslated = a;
    });

    this.employeeManagementChanges.canEdit.subscribe( canEdit => {
      this.editable = canEdit;
    });
  }
  //#endregion

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.registerPermissionsLoadedSubscription();
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }

    if (this.employeeSubscription) {
      this.employeeSubscription.unsubscribe();
    }

    if (this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  registerPermissionsLoadedSubscription() {
    if (!this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription = this.accountService.userPermissionsLoaded.subscribe(() => {
        this.accountService.userRolesLoaded.subscribe((rolesResponse) => {
          this.roles = this.accountService.roles;

          this.initStates();
          this.onInit();
        });
      });
    } else {
      this.initStates();
      this.onInit();
    }
  }
  //#endregion

  //#region DATA MANIPULATION
  /* ################################################################################################################## */
  /* ## DATA MANIPULATION
  /* ################################################################################################################## */
  private init() {
    this.disableForm();
    this.onEdit = false;
    this.editable = false;
    this.employee = new Employee();

    this.spinner.show()

    this.subscriptions.push(
      this.route.paramMap.subscribe(params => {
        this.subscriptions.push(
          this.employeeService.getMyEmployeeInfo().subscribe(employeeResponse => {
            this.employeeService.updateEmployeeRoutes(employeeResponse.employee.employeeId);
            this.employeeManagementInfo = employeeResponse;

            if (employeeResponse.employee.employeeId) {
              this.detail = false;
              this.enabled = true;
              this.employee = employeeResponse.employee;
              this.employeeSubject.next(employeeResponse.employee); // Se notifican los cambios a los observadores
              this.disableForm();
              this.setFormValues();
              this.selectedDesc = employeeResponse.employee.name;

              this.initFormations();
            } else {
              this.selectedDesc = undefined;
              this.detail = false;
              this.enabled = false;
              this.employee = new Employee();
              this.employeeSubject.next(new Employee()); // Se notifican los cambios a los observadores
            }

            this.spinner.hide();
          }, error => {
            this.spinner.hide();
          })
        );
      })
    );
  }
  //#endregion

  //#region CUSTOM METHODS
  /* ################################################################################################################## */
  /* ## CUSTOM METHODS
  /* ################################################################################################################## */
  private initStates() {
    this.subscriptions.push(
      this.dcrStateService.getAllDCRStateRequest().subscribe(dcrStateRequests => {
        this.dcrStateService.updateDCRStateRequests(dcrStateRequests.items);
        this.dcrStates = dcrStateRequests.items;
      }, error => {
        this.RNService.showError(error);
      }),

      this.tcrStateService.getAllTCRStateRequest().subscribe(tcrStateRequests => {
        this.tcrStateService.updateTCRStateRequests(tcrStateRequests.items);
        this.tcrStates = tcrStateRequests.items;
      }, error => {
        this.RNService.showError(error);
      })
    );
  }


  //#region FORMS
  /* ****************************************************************************************************************** */
  /* ** FORMS
  /* ****************************************************************************************************************** */
  setFormValues() {
    //#region ProfileFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    // ProfileFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    this.ProfileFormGroup.setValue({
      name: this.employee.name,
      firstSurname: this.employee.firstSurname,
      secondSurname: this.employee.secondSurname,
      documentNumber: this.employee.documentNumber,
      documentType: this.employee.documentType,
      documentExpirationDate: this.employee.documentExpirationDate,
      gender: this.employee.gender,
      nss: this.employee.nss,
      country: (this.employee.country)
        ? this.employee.country.name
        : null,
      birthdate: this.employee.birthdate,
      birthplace: this.employee.birthplace,
      birthplaceProvince: this.employee.birthplaceProvince,
      disability: this.employee.disability,
      disabilityPercentage: this.employee.disabilityPercentage,
      bankAccount: this.employee.bankAccount,
      code: this.employee.code ? this.employee.code : null,

      //contact data
      personalEmail: this.employee.personalEmail,
      companyEmail: this.employee.companyEmail,
      personalPhone: this.employee.personalPhone,
      companyPhone: this.employee.companyPhone,
    });
    this.disabilityChecked = this.employee.disability;
    //#endregion

    //#region HomeAddressFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    // HomeAddressFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    this.HomeAddressFormGroup.setValue({
      country: this.employee.homeAddress.country,
      flat: this.employee.homeAddress.flat,
      location: this.employee.homeAddress.location,
      location2: this.employee.homeAddress.location2,
      number: this.employee.homeAddress.number,
      portal: this.employee.homeAddress.portal,
      postalCode: this.employee.homeAddress.postalCode,
      province: this.employee.homeAddress.province,
      street: this.employee.homeAddress.street,
      typeStreet: this.employee.homeAddress.typeStreet,
    });
    //#endregion

    //#region CompanyFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    // CompanyFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    this.CompanyFormGroup.setValue({
      area: (this.employee.area)
        ? this.employee.area.description
        : null,
      centre: (this.employee.centre)
        ? this.employee.centre.name
        : null,
      headquarter: (this.employee.headquarters)
        ? this.employee.headquarters.name
        : null,
      manager: (this.employee.manager)
        ? this.employee.manager.name
        : null,
      user: (this.accountService.currentUser)
        ? this.accountService.currentUser.userName
        : null,
      validator: (this.employee.validator)
        ? this.employee.validator.name
        : null,
      workcalendar: (this.employee.workCalendar)
        ? this.employee.workCalendar.name
        : null,
    });
    //#endregion

    //#region ContractualDataFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    // ContractualDataFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    this.ContractualDataFormGroup.setValue({
      situation: (this.employee.employeeSituation)
        ? this.employee.employeeSituation.description
        : null,
      registrationDate: this.employee.registrationDate,
      leaveDate: this.employee.leaveDate,
      seniorityDate: this.employee.seniorityDate,

      category: (this.employee.category)
        ? this.employee.category.careerPlan
        : null,
      quotationGroupEquivalence: (this.employee.category)
        ? this.employee.category.quotationGroupEquivalence
        : null,
    });
    //#endregion

    //#region SbaDataFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    // SbaDataFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    if (this.employee.sba != undefined && this.employee.sba != null) {
      this.SbaDataFormGroup.setValue({
        startDate: this.employee.sba.startDate,
        coefficient:this.employee.sba.coefficient.toFixed(2),
        sba: this.employee.sba.sba.toFixed(2),
      });
    }
    //#endregion

    // ------------------------------------------------------------------------------------------------------------------
    // Filtros
    // ------------------------------------------------------------------------------------------------------------------
    this.addFilters();
  }

  cleanForm() {
    this.setFormValues();

    // Quita las marcas "dirty" de los inputs
    this.ProfileFormGroup.markAsPristine();
    this.CompanyFormGroup.markAsPristine();
  }

  disableForm() {
    // ------------------------------------------------------------------------------------------------------------------
    // ProfileFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    Object.keys(this.ProfileFormGroup.controls).forEach((name) => {
      const currentControl = this.ProfileFormGroup.controls[name];

      currentControl.disable();
    });

    // ------------------------------------------------------------------------------------------------------------------
    // HomeAddressFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    Object.keys(this.HomeAddressFormGroup.controls).forEach((name) => {
      const currentControl = this.HomeAddressFormGroup.controls[name];

      currentControl.disable();
    });

    // ------------------------------------------------------------------------------------------------------------------
    // FormationsFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    Object.keys(this.FormationsFormGroup.controls).forEach((name) => {
      const currentControl = this.FormationsFormGroup.controls[name];

      currentControl.disable();
    });

    // ------------------------------------------------------------------------------------------------------------------
    // CompanyFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    Object.keys(this.CompanyFormGroup.controls).forEach((name) => {
      const currentControl = this.CompanyFormGroup.controls[name];

      currentControl.disable();
    });

    // ------------------------------------------------------------------------------------------------------------------
    // ContractualDataFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    Object.keys(this.ContractualDataFormGroup.controls).forEach((name) => {
      const currentControl = this.ContractualDataFormGroup.controls[name];

      currentControl.disable();
    });

    // ------------------------------------------------------------------------------------------------------------------
    // SbaDataFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    Object.keys(this.SbaDataFormGroup.controls).forEach((name) => {
      const currentControl = this.SbaDataFormGroup.controls[name];

      currentControl.disable();
    });
  }

  enableForm() {
    // ------------------------------------------------------------------------------------------------------------------
    // ProfileFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    let enableItemsProfileFormGroup = [
      'bankAccount',
      'companyEmail',
      'companyPhone',
      'disability',
      'disabilityPercentage',
      'documentExpirationDate',
      'personalEmail',
      'personalPhone',
    ];

    Object.keys(this.ProfileFormGroup.controls).forEach((name) => {
      const currentControl = this.ProfileFormGroup.controls[name];

      if (enableItemsProfileFormGroup.includes(name)) {
        if (name != 'disabilityPercentage') {
          currentControl.enable();
        } else if (this.ProfileFormGroup.get('disability').value == true) {
          currentControl.enable();
        }
      }
    });

    // ------------------------------------------------------------------------------------------------------------------
    // HomeAddressFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    Object.keys(this.HomeAddressFormGroup.controls).forEach((name) => {
      const currentControl = this.HomeAddressFormGroup.controls[name];

      currentControl.enable();
    });

    // ------------------------------------------------------------------------------------------------------------------
    // FormationsFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    Object.keys(this.FormationsFormGroup.controls).forEach((name) => {
      const currentControl = this.FormationsFormGroup.controls[name];

      currentControl.enable();
    });
  }

  updateDisabled() {
    // return (this.ProfileFormGroup.valid); //TODO santi: update enabled when form is valid.
    var res = (
      this.editable &&
      (
        this.ProfileFormGroup.get('documentType').valid &&
        this.ProfileFormGroup.get('documentNumber').valid &&
        this.ProfileFormGroup.get('documentExpirationDate').valid &&
        this.ProfileFormGroup.get('bankAccount').valid &&
        this.ProfileFormGroup.get('disabilityPercentage').valid &&
        this.ProfileFormGroup.get('personalEmail').valid &&
        this.ProfileFormGroup.get('personalPhone').valid &&
        this.ProfileFormGroup.get('companyEmail').valid &&
        this.ProfileFormGroup.get('companyPhone').valid &&
        this.ProfileFormGroup.get('location').valid &&
        this.ProfileFormGroup.get('street').valid &&
        this.ProfileFormGroup.get('number').valid &&
        this.ProfileFormGroup.get('portal').valid &&
        this.ProfileFormGroup.get('flat').valid &&
        this.ProfileFormGroup.get('postalCode').valid &&
        this.ProfileFormGroup.get('province').valid &&
        this.ProfileFormGroup.get('countryAddress').valid
      )
    );

    return !res;
  }

  cancel() {
    this.init(); //TODO verificar

    this.spinner.hide();

    // Quita las marcas "dirty" de los inputs
    this.ProfileFormGroup.markAsPristine();
    this.CompanyFormGroup.markAsPristine();
  }

  back() {
    this.cancel();
  }

  edit() {
    if(this.editable==false){
      this.enabled = true;
      this.enableForm();
      this.editable = true;

      // Update variable "editable"
      this.employeeManagementChanges.onChangeCheck(this.editable);
    } else {
      this.disableForm();
      this.editable = false;

      //Reset selected option
      this.FormationsFormGroup.get('course').setValue('');
      this.FormationsFormGroup.get('degree').setValue('');
      this.FormationsFormGroup.get('language').setValue('');

      // Update variable "editable"
      this.employeeManagementChanges.onChangeCheck(this.editable);

      this.cancel();
    }
  }

  addFilters() {
    // ------------------------------------------------------------------------------------------------------------------
    // ProfileFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    this.filteredCountries = this.ProfileFormGroup.controls['country'].valueChanges.pipe(startWith(""), map( val =>
      this.employeeManagementInfo.allCountries.filter(dgr =>
        this.customContains(dgr.name, val)
      ))
    );

    // ------------------------------------------------------------------------------------------------------------------
    // FormationsFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    this.filteredCertificationCourses = this.FormationsFormGroup.controls['certificationCourse'].valueChanges.pipe(startWith(""), map(
      val => this.employeeManagementInfo.allCertificationCourses.filter( cour => {
        if (!this.certificationCourseAlreadyAssociated(cour)) {
          return this.customContains(cour.description, val);
        }
      }))
    );
    this.filteredCourses = this.FormationsFormGroup.controls['course'].valueChanges.pipe(startWith(""), map(
      val => this.employeeManagementInfo.allCourses.filter( cour => {
        if (!this.courseAlreadyAssociated(cour)) {
          return this.customContains(cour.description, val);
        }
      }))
    );
      this.FormationsFormGroup.get('certificationCourse').setValue('');
    this.filteredDegrees = this.FormationsFormGroup.controls['degree'].valueChanges.pipe(startWith(""), map(
      val => this.employeeManagementInfo.allDegrees.filter( dgr => {
        if (!this.degreeAlreadyAssociated(dgr)) {
          return this.customContains(dgr.description + " " + dgr.code, val);
        }
      }))
    );
    this.filteredLanguages = this.FormationsFormGroup.controls['language'].valueChanges.pipe(startWith(""), map(
      val => this.employeeManagementInfo.allLanguages.filter( lng => {
        if (!this.languageAlreadyAssociated(lng)) {
          return this.customContains(lng.description, val);
        }
      }))
    );
  }

  private initFormations() {
    // COURSES
    this.certificationCourseByEmployee = [];
    this.courseByEmployee = [];

    this.certificationCoursesId = [];
    this.coursesId = [];

    for (let employeeCourse of this.employee.employeeCertificationCourses) {
      this.certificationCoursesId.push(employeeCourse.courseId);
      this.certificationCourseByEmployee.push(employeeCourse.course);
    }

    for (let employeeCourse of this.employee.employeeCourses) {
      this.coursesId.push(employeeCourse.courseId);
      this.courseByEmployee.push(employeeCourse.course);
    }

    // DEGREES
    this.degreeByEmployee = [];

    for (let employeeDegree of this.employee.employeeDegrees) {
      this.degreesId.push(employeeDegree.degreeId);
      this.degreeByEmployee.push(employeeDegree.degree);
    }

    // LANGUAGES
    this.languageByEmployee = [];

    for (let employeeLanguage of this.employee.employeeLanguages) {
      this.languagesId.push(employeeLanguage.languageId);
      this.languageByEmployee.push(employeeLanguage.language);
    }
  }
  //#endregion
  //#endregion

  //#region CRUD
  /* ################################################################################################################## */
  /* ## CRUD
  /* ################################################################################################################## */
  /* ****************************************************************************************************************** */
  /* ** UPDATE
  /* ****************************************************************************************************************** */
  update() {
    this.spinner.show();

    let dicAttr = this.getDictionaryAttr(this.employee, ["homeAddress"]);
    let profileChangedProperties: AttributeChange[] = this.getChangedProperties(this.ProfileFormGroup, dicAttr);
    let homeAddressProperties: AttributeChange[] = this.getChangedProperties(this.HomeAddressFormGroup, dicAttr);

    let requestAttr: AttributeChange[] = profileChangedProperties.concat(
      homeAddressProperties
    );

    if (requestAttr.length > 0) {
      let request: DataChangeRequest = new DataChangeRequest();

      request.employeeId = this.employee.employeeId;
      request.attrPatchChangesJson = this.createAttrPatchChangesJson(requestAttr);
      request.employee = this.employee;
      request.attributeChanges = requestAttr;
      request.state = this.dcrStates.find(element => element.stateCode == DCRStateCode.REGISTERED);
      // request.dcrStateId = request.state.dcrStateId;

      const confirmationDialog = this.dcrDialog.open(DcrDialogComponent, {
        width: '550px',
        data: request
      });

      confirmationDialog.afterClosed().subscribe((confirm) => {
        let dcrStateId = request.dcrStateId;
        let msgType = "success";

        if (confirm === true || confirm == "save") {
          this.spinner.show();

          let scrState: DCRState;
          if (confirm === true) {
            scrState = this.dcrStates.find(element => element.stateCode == DCRStateCode.PENDING_APROVAL);
          }

          if(scrState) {
            request.dcrStateId = dcrStateId;
            request.state = null;

            this.dataChangeRequestService.createDataChangeRequest(request).subscribe(
              (response: DataChangeRequest) => {
                request = response;

                if (dcrStateId != "") {
                  let title: string;
                  let msg: string;

                  this.translate.get('DATA_CHANGE_REQUEST_ACTION.FORM.REQUEST_CONFIRMATION.TITLE').subscribe((a: string) => {
                    title = a;
                  });

                  if (msgType === "success") {
                    this.translate.get('DATA_CHANGE_REQUEST_ACTION.FORM.REQUEST_CONFIRMATION.CONFIRM_ACTION').subscribe((a: string) => {
                      msg = a;
                    });
                  } else {
                    this.translate.get('DATA_CHANGE_REQUEST_ACTION.FORM.REQUEST_CONFIRMATION.CANCEL_ACTION').subscribe((a: string) => {
                      msg = a;
                    });
                  }

                  this.spinner.hide();
                  this.toastrService[msgType](msg, title, { timeOut: 3000 });
                }
              },
              error => {
                let title: string;

                this.spinner.hide();

                this.translate.get('MESSAGES.ERROR.UPDATE_CONFLICT').subscribe((a: string) => {
                  title = a;
                  this.toastrService.error(error.error, title, { timeOut: 3000 });
                });
              },
            );
          } else {
            this.translate.get('MESSAGES.ERROR.CRITICAL_BODY').subscribe((a: string) => {
              this.toastrService.error(a, '', { timeOut: 3000 });
            });
          }
        } else {
          msgType = "info";
        }
      });

    }

    this.cancel();
  }
  //#endregion

  /* ****************************************************************************************************************** */
  /* ** COURSE
  /* ****************************************************************************************************************** */
  associateCourse() {
    this.spinner.show();

    let request: TrainingChangeRequest = this.createTrainingChangeRequest(false);
    let infoTCRDialog : IInfoTCRDialog;

    request.course = this.FormationsFormGroup.get('course').value;
    request.courseId = request.course.courseId;

    this.translate.get('TRAINING_CHANGE_REQUEST_ACTION.TARGET.COURSE').subscribe((translation: string) => {
      infoTCRDialog = {
        title           : translation,
        description     : request.course.description,
        asassociate     : true,
        pendingAnnulled : false,
      };
    });

    this.launchTCR(request, infoTCRDialog);
  }

  disassociateCourse(course) {
    this.spinner.show();

    let request: TrainingChangeRequest = this.createTrainingChangeRequest(true);
    let infoTCRDialog : IInfoTCRDialog;

    request.course = course;
    request.courseId = course.courseId;

    this.translate.get('TRAINING_CHANGE_REQUEST_ACTION.TARGET.COURSE').subscribe((translation: string) => {
      infoTCRDialog = {
        title           : translation,
        description     : request.course.description,
        asassociate     : false,
        pendingAnnulled : true,
      };
    });

    this.launchTCR(request, infoTCRDialog);
  }

  /* ****************************************************************************************************************** */
  /* ** CV
  /* ****************************************************************************************************************** */
  associateCV() {
    // TODO: implementar lógica
  }

  disassociateCV($event) {
    // TODO: implementar lógica
  }

  /* ****************************************************************************************************************** */
  /* ** DEGREE
  /* ****************************************************************************************************************** */
  associateDegree() {
    this.spinner.show();

    let request: TrainingChangeRequest = this.createTrainingChangeRequest(false);
    let infoTCRDialog : IInfoTCRDialog;

    request.degree = this.FormationsFormGroup.get('degree').value;
    request.degreeId = request.degree.degreeId;

    this.translate.get('TRAINING_CHANGE_REQUEST_ACTION.TARGET.DEGREE').subscribe((translation: string) => {
      infoTCRDialog = {
        title           : translation,
        description     : request.degree.description,
        asassociate     : true,
        pendingAnnulled : false,
      };
    });

    this.launchTCR(request, infoTCRDialog);
  }

  disassociateDegree(degree: Degree) {
    this.spinner.show();

    let request: TrainingChangeRequest = this.createTrainingChangeRequest(true);
    let infoTCRDialog : IInfoTCRDialog;

    request.degree = degree;
    request.degreeId = degree.degreeId;

    this.translate.get('TRAINING_CHANGE_REQUEST_ACTION.TARGET.DEGREE').subscribe((translation: string) => {
      infoTCRDialog = {
        title           : translation,
        description     : request.degree.description,
        asassociate     : false,
        pendingAnnulled : true,
      };
    });

    this.launchTCR(request, infoTCRDialog);
  }

  /* ****************************************************************************************************************** */
  /* ** LANGUAGE
  /* ****************************************************************************************************************** */
  associateLanguage() {
    this.spinner.show();

    let request: TrainingChangeRequest = this.createTrainingChangeRequest(false);
    let infoTCRDialog : IInfoTCRDialog;

    request.language = this.FormationsFormGroup.get('language').value;
    request.languageId = request.language.languageId;

    this.translate.get('TRAINING_CHANGE_REQUEST_ACTION.TARGET.LANGUAGE').subscribe((translation: string) => {
      infoTCRDialog = {
        title           : translation,
        description     : request.language.description,
        asassociate     : true,
        pendingAnnulled : false,
      };
    });

    this.launchTCR(request, infoTCRDialog);
  }

  disassociateLanguage(language: Language) {
    this.spinner.show();

    let request: TrainingChangeRequest = this.createTrainingChangeRequest(true);
    let infoTCRDialog : IInfoTCRDialog;

    request.language = language;
    request.languageId = language.languageId;

    this.translate.get('TRAINING_CHANGE_REQUEST_ACTION.TARGET.LANGUAGE').subscribe((translation: string) => {
      infoTCRDialog = {
        title           : translation,
        description     : request.language.description,
        asassociate     : false,
        pendingAnnulled : true,
      };
    });

    this.launchTCR(request, infoTCRDialog);
  }

  /* ****************************************************************************************************************** */
  /* ** TrainingChangeRequest
  /* ****************************************************************************************************************** */
  createTrainingChangeRequest(pendingAnnulled = false): TrainingChangeRequest {
    let request: TrainingChangeRequest = new TrainingChangeRequest();
    request.employeeId = this.employee.employeeId;
    request.employee = this.employee;

    if (pendingAnnulled) {
      request.state = this.tcrStates.find(element => element.stateCode == TCRStateCode.PENDING_ANNULLED);
    } else {
      // Estado por defectgo
      request.state = this.tcrStates.find(element => element.stateCode == TCRStateCode.REGISTERED);
    }

    request.tcrStateId = request.state.tcrStateId;

    return request;
  }

  launchTCR(request: TrainingChangeRequest, infoTCRDialog : IInfoTCRDialog) {
    const confirmationDialog = this.dialog.open(TCRDialogComponent, {
      width: '550px',
      data: {
        request,
        infoTCRDialog
      }
    });

    confirmationDialog.afterClosed().subscribe((confirm) => {
      let tcrStateId = request.tcrStateId;
      let msgType = "success";

      if (
        confirm === true ||
        (
          confirm.action != undefined &&
          confirm.action != null &&
          (confirm.action == "send" || confirm.action == "save")
        )
      ) {
        this.spinner.show();

        if (confirm === true || confirm.action == "send") {
          if (request.state.stateCode == TCRStateCode.REGISTERED) {
            tcrStateId = this.tcrStates.find(element => element.stateCode == TCRStateCode.PENDING_APROVAL).tcrStateId;
          }

          request.tcrStateId = tcrStateId;
        }

        request.employee = null;
        request.state = null;

        if (confirm.finishDate) {
          request.finishDate = confirm.finishDate;
        } else {
          request.finishDate = getDateString(getMinDate());
        }

        request.course = null;
        request.degree = null;
        request.employeeCV = null;
        request.language = null;

        this.trainingChangeRequestService.createTrainingChangeRequest(request).subscribe(
          (response: TrainingChangeRequest) => {
            request = response;

            if (tcrStateId != "") {
              let title: string;
              let msg: string;

              this.translate.get('DATA_CHANGE_REQUEST_ACTION.FORM.REQUEST_CONFIRMATION.TITLE').subscribe((a: string) => {
                title = a;
              });

              if (msgType === "success") {
                this.translate.get('DATA_CHANGE_REQUEST_ACTION.FORM.REQUEST_CONFIRMATION.CONFIRM_ACTION').subscribe((a: string) => {
                  msg = a;
                });
              } else {
                this.translate.get('DATA_CHANGE_REQUEST_ACTION.FORM.REQUEST_CONFIRMATION.CANCEL_ACTION').subscribe((a: string) => {
                  msg = a;
                });
              }

              this.toastrService[msgType](msg, title, { timeOut: 3000 });
            }
          },
          error => {
            let title: string;
            this.translate.get('MESSAGES.ERROR.UPDATE_CONFLICT').subscribe((a: string) => {
              title = a;
              this.toastrService.error(error.error, title, { timeOut: 3000 });
            });

            this.spinner.hide();
          },
        );

      } else {
        msgType = "info";
      }

      this.spinner.hide();
    });
  }

  /* ################################################################################################################## */
  /* ## UTILS METHODS
  /* ################################################################################################################## */
  /**
   * Recoge los cambios de un formulario
   *
   * @param formGroup formulario a procesar
   * @returns los cambios hechos en el formulario
   */
  getChangedProperties(formGroup: FormGroup, dicAttr): AttributeChange[] {
    let changedProperties = [];
    let requestAttr: AttributeChange[] = [];

    //https://stackoverflow.com/questions/40914413/how-can-i-get-all-dirty-modified-fields-using-angular2-forms
    Object.keys(formGroup.controls).forEach((name) => {
      const currentControl = formGroup.controls[name];

      if (currentControl.dirty) {
        changedProperties.push(name);
      }
    });

    for (let i = 0; i < changedProperties.length; i++) {
      let path = dicAttr[changedProperties[i]];
      let pathArray = path.split("/");
      pathArray = pathArray.filter((item) => item != ""); // Limpia el array de elementos vacíos

      let dirtyInput = {
        attribute: changedProperties[i],
        path: path,
        newValue: formGroup.get(changedProperties[i]).value,

        // https://stackoverflow.com/questions/11922383/how-can-i-access-and-process-nested-objects-arrays-or-json
        oldValue: pathArray.reduce(
          (a, v) => a[v], this.employee
        ),
      }

      requestAttr.push(dirtyInput);
    }

    return requestAttr;
  }

  createAttrPatchChangesJson(attributeChanges) {
    let result = new Array();

    for (let i = 0; i < attributeChanges.length; i++) {
      let item = attributeChanges[i];

      result.push(
        {
          "op": "replace",
          "path": item.path,
          "value": item.newValue
        }
      );
    }

    return JSON.stringify(result);
  }

  // HELPER: DICTIONARY ATTRIBUTES
  getDictionaryAttr(obj, includes = []) {
    let result = {};

    for (let attr in obj) {
      if (!(this.employee[attr] instanceof Object)) {
        result[attr] = attr;
      } else {
        if (includes.includes(attr)) {
          let aux = this.getDictionaryAttr(obj[attr], includes);

          for (let auxAttr in aux) {
            result[auxAttr] = `/${attr}/${aux[auxAttr]}`;
          }
        }
      }
    }

    return result;
  }

  setDate($event) {
    $event.value = moment($event.target.value).format('DD/MM/YYYY');

    return $event;
  }

  //DISABILITY
  onChangeDisability(e) {
    if (e.checked) {
      this.ProfileFormGroup.get('disabilityPercentage').enable();
    } else {
      this.ProfileFormGroup.get('disabilityPercentage').setValue(0);
      this.ProfileFormGroup.get('disabilityPercentage').disable();
    }
  }

  getEditButtonName() {
    let textButton = "";

    if(this.editable==false){
      this.translate.get('ACTION.REQUEST').subscribe((a:string)=>{
        textButton = a;
      })
    }else{
      this.translate.get('ACTION.CANCEL').subscribe((a:string)=>{
        textButton = a;
      })
    }

    return textButton;
  }

  customStartsWith(attribute: String, value: String): boolean {
    if ((typeof value === "string" || value instanceof String)) {
      if (value == " ") {
        return true;
      }
      let normalizedAttribute = attribute.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
      let normalizedValue = value.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
      return normalizedAttribute.toLowerCase().indexOf(normalizedValue.toLowerCase()) == 0;
    } else {
      return true;
    }
  }

  customContains(attribute: String, value: any): boolean {
    if ((typeof value === "string" || value instanceof String)) {
      if (value == " ") {
        return true;
      }
      let normalizedAttribute = attribute.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
      let normalizedValue = value.normalize('NFD').replace(/[\u0300-\u036f]/g, "");

      return normalizedAttribute.toLowerCase().indexOf(normalizedValue.toLowerCase()) != -1;
    } else {
      return true;
    }
  }

  /* ****************************************************************************************************************** */
  /* ** CERTIFICATION COURSE
  /* ****************************************************************************************************************** */
  assignCertificationCourse(e) {
    this.FormationsFormGroup.get('certificationCourse').setValue(e.option.value);

    if (e.option.value != null) {
      this.addCourse=true;
    } else {
      this.addCourse=false;
    }
  }

  displayCertificationCourse(course: Course): string | undefined {
    return (course)
      ? course.description
      : undefined;
  }

  certificationCourseAlreadyAssociated(course: Course): boolean {
    let result: boolean = false;

    let courseFound = this.certificationCourseByEmployee.find(item => item.courseId == course.courseId);

    if (courseFound != undefined && courseFound != null) {
      result = true;
    }

    return result;
  }

  /* ****************************************************************************************************************** */
  /* ** COURSE
  /* ****************************************************************************************************************** */
  assingCourse(e) {
    this.FormationsFormGroup.get('course').setValue(e.option.value);

    if (e.option.value != null) {
      this.addCourse=true;
    } else {
      this.addCourse=false;
    }
  }

  displayCourse(couse: Course): string | undefined {
    return (couse) ? couse.description : undefined;
  }

  courseAlreadyAssociated(course: Course): boolean {
    let result: boolean = false;

    let courseFound = this.courseByEmployee.find(item => item.courseId == course.courseId);

    if (courseFound != undefined && courseFound != null) {
      result = true;
    }

    return result;
  }

  /* ****************************************************************************************************************** */
  /* ** CV
  /* ****************************************************************************************************************** */

  /* ****************************************************************************************************************** */
  /* ** DEGREE
  /* ****************************************************************************************************************** */
  assingDegree(e) {
    this.FormationsFormGroup.get('degree').setValue(e.option.value);
    // this.FormationsFormGroup.get('degreeCode').setValue('');
    // this.filteredDegreesCode = this.CompanyFormGroup.controls['degreeCode'].valueChanges.pipe(startWith(""), map(val => this.allDegrees.filter(dgrCd => dgrCd.description === (e.option.value as Degree).description).filter(code => this.customStartsWith(code.code, val))));

    if (e.option.value != null) {
      this.addDegree=true;
    } else {
      this.addDegree=false;
    }
  }

  displayDegree(degree: Degree): string | undefined {
    return (degree) ? degree.code : undefined; //TODO seleccionar la descripción y aplicar otro campo para mostrar los códigos que corresponden con esa descripción
  }

  degreeAlreadyAssociated(degree: Degree): boolean {
    let result: boolean = false;

    let degreeFound = this.degreeByEmployee.find(item => item.degreeId == degree.degreeId);

    if (degreeFound != undefined && degreeFound != null) {
      result = true;
    }

    return result;
  }

  /* ****************************************************************************************************************** */
  /* ** LANGUAGE
  /* ****************************************************************************************************************** */
  assingLanguage(e) {
    this.FormationsFormGroup.get('language').setValue(e.option.value);

    if (e.option.value != null) {
      this.addLanguage=true;
    } else {
      this.addLanguage=false;
    }
  }

  displayLanguage(language: Language): string | undefined {
    return (language) ? language.description : undefined;
  }

  languageAlreadyAssociated(language: Language): boolean {
    let result: boolean = false;

    let languageFound = this.languageByEmployee.find(item => item.languageId == language.languageId);

    if (languageFound != undefined && languageFound != null) {
      result = true;
    }

    return result;
  }

  //#region FORMS
  /* ****************************************************************************************************************** */
  /* ** FORMS
  /* ****************************************************************************************************************** */
  assignEmployeeData() {
    if (this.employee == undefined || this.employee == null) {
      this.employeeSubject.next(new Employee()); // Se notifican los cambios a los observadores
    }

    // ProfileFormGroup
    this.employee.name                                = this.ProfileFormGroup.get('name').value;
    this.employee.firstSurname                        = this.ProfileFormGroup.get('firstSurname').value;
    this.employee.secondSurname                       = this.ProfileFormGroup.get('secondSurname').value;
    this.employee.gender                              = this.ProfileFormGroup.get('gender').value;

    this.employee.documentType                        = this.ProfileFormGroup.get('documentType').value;
    this.employee.documentNumber                      = this.ProfileFormGroup.get('documentNumber').value;
    this.employee.documentExpirationDate              = this.ProfileFormGroup.get('documentExpirationDate').value;
    this.employee.countryId                           = (this.ProfileFormGroup.get('country').value.countryId);

    this.employee.birthdate                           = this.ProfileFormGroup.get('birthdate').value;
    this.employee.birthplace                          = this.ProfileFormGroup.get('birthplace').value;
    this.employee.birthplaceProvince                  = this.ProfileFormGroup.get('birthplaceProvince').value;

    this.employee.disability                          = this.ProfileFormGroup.get('disability').value || false;
    this.employee.disabilityPercentage                = this.ProfileFormGroup.get('disabilityPercentage').value || 0;

    // Holidays data
    this.employee.totalAvailableHolidays              = this.ProfileFormGroup.get('totalAvailableHolidays').value;

    // HolidayWeekend
    this.employee.totalHolidayWeekend                 = this.ProfileFormGroup.get('totalHolidaysWeekend').value;

    this.employee.bankAccount                         = this.ProfileFormGroup.get('bankAccount').value;
    this.employee.nss                                 = this.ProfileFormGroup.get('nss').value;

    this.employee.code                                = this.ProfileFormGroup.get('code').value;

    // ContactsFormGroup
    this.employee.personalEmail                       = this.ProfileFormGroup.get('personalEmail').value;
    this.employee.companyEmail                        = this.ProfileFormGroup.get('companyEmail').value;
    this.employee.personalPhone                       = this.ProfileFormGroup.get('personalPhone').value;
    this.employee.companyPhone                        = this.ProfileFormGroup.get('companyPhone').value;

    // CompanyFormGroup
    this.employee.areaId                              = this.CompanyFormGroup.get('area').value.areaId;
    this.employee.centreId                            = this.CompanyFormGroup.get('centre').value.centreId;
    this.employee.headquartersId                      = this.CompanyFormGroup.get('headquarter').value.headquartersId;

    this.employee.workCalendarId                      = this.CompanyFormGroup.get('workcalendar').value.workCalendarId;
    this.employee.login                               = this.CompanyFormGroup.get('user').value.userName;
    this.employee.userId                              = this.CompanyFormGroup.get('user').value.id;

    this.employee.managerId                           = this.CompanyFormGroup.get('manager').value.employeeId;
    this.employee.validatorId                         = this.CompanyFormGroup.get('validator').value.employeeId;

    // ContractualDataFormGroup
    this.employee.categoryId                          = this.ContractualDataFormGroup.get('category').value.categoryId;

    if (this.employee.registrationDate != this.ContractualDataFormGroup.get('registrationDate').value) {
      this.employee.registrationDate                  = this.assignDate(this.ContractualDataFormGroup.get('registrationDate').value);
    }
    if (this.employee.leaveDate != this.ContractualDataFormGroup.get('leaveDate').value) {
      this.employee.leaveDate                         = this.assignDate(this.ContractualDataFormGroup.get('leaveDate').value);
    }
    if (this.employee.seniorityDate != this.ContractualDataFormGroup.get('seniorityDate').value) {
      this.employee.seniorityDate                     = this.assignDate(this.ContractualDataFormGroup.get('seniorityDate').value);
    }
  }

  /* ****************************************************************************************************************** */
  /* ** HOMEADDRESS
  /* ****************************************************************************************************************** */
  assignHomeAddress() {
    if (this.homeAddress == undefined || this.homeAddress == null) {
      this.homeAddress = new Address();
    }

    this.homeAddress.country    = this.HomeAddressFormGroup.get('country').value;
    this.homeAddress.flat       = this.HomeAddressFormGroup.get('flat').value;
    this.homeAddress.location   = this.HomeAddressFormGroup.get('location').value;
    this.homeAddress.location2  = this.HomeAddressFormGroup.get('location2').value;
    this.homeAddress.number     = this.HomeAddressFormGroup.get('number').value;
    this.homeAddress.portal     = this.HomeAddressFormGroup.get('portal').value;
    this.homeAddress.postalCode = this.HomeAddressFormGroup.get('postalCode').value;
    this.homeAddress.province   = this.HomeAddressFormGroup.get('province').value;
    this.homeAddress.street     = this.HomeAddressFormGroup.get('street').value;
    this.homeAddress.typeStreet = this.HomeAddressFormGroup.get('typeStreet').value;
  }

  assignDate(date: string) {
    let result;

    if (date != "") {
      result = new Date(date) || undefined;
    }

    return result;
  }
  //#endregion

  //#region ACTIONS METHODS
  /* ################################################################################################################## */
  /* ## ACTIONS METHODS
  /* ################################################################################################################## */
  /**
   * Abre un cuadro de diálogo cargando el histórico que se pasa como argumento.
   *
   * @param target nombre del histórico que se quiere mostrar
   */
  openEmployeeContractualHistorical(target: string) {
    const employeeHistoryDialog = this.dialog.open(EmployeeHistoryDlgComponent, {
      maxWidth: '80vw',
      // maxHeight: '75vh',
      data: {
        selectedView  : target,
        employee      : this.employee,

        areas         : this.employeeManagementInfo.allAreas,
      }
    });
  }
  //#endregion

  //#region UTILS
  /* ################################################################################################################## */
  /* ## UTILS
  /* ################################################################################################################## */
  /**
   * Comprueba si un formulario ha sido cambiado
   *
   * @param formGroup formulario a procesar
   * @param nameMask mascara para limintar el nombre de los inputs a verificar
   * @returns los cambios hechos en el formulario
   */
  isFormChanges(formGroup: FormGroup, nameMask: string = ''): boolean {
    let changedProperties = [];

    //https://stackoverflow.com/questions/40914413/how-can-i-get-all-dirty-modified-fields-using-angular2-forms
    Object.keys(formGroup.controls).forEach((name) => {
      if (
        nameMask == '' ||
        (nameMask != '' && name.includes(nameMask))
      ) {
        const currentControl = formGroup.controls[name];

        if (currentControl.dirty) {
          changedProperties.push(name);
        }
      }
    });

    return (changedProperties.length > 0) ? true : false;
  }

  /**
   * Se cambia el valor (string) del control del formulario por un objeto entre la colección pasada.
   *
   * @param arrayValues Colección de valores en los que buscar
   * @param formGroup Formulario al que asignar el valor buscado
   * @param controlName Nombre del control del formulario al que se reasociará el objeto
   * @param property Nombre de la propiedad a evaluar la búsqueda
   */
  assignObjectFromArrayToForm(arrayValues, formGroup, controlName, property) {
    const currentControl = formGroup.controls[controlName];

    const tempData = arrayValues.find((element) => element[property] == currentControl.value);

    currentControl.setValue(tempData);
  }
  //#endregion
  //#endregion
}
