import { Component } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AccountService, Hotkeys, ResponseNotificationService, SecurityStateManagementService } from 'inzo-portalempleado';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { HolidayRequestStateCode } from 'src/app/models/holiday-request-state-code-enum.model';
import { EmployeeManagementService } from 'src/app/services/employee-management.service';
import { HolidayRequestStateService } from 'src/app/services/holiday-request-state.service';
import { HolidayRequestService } from 'src/app/services/holiday-request.service';
import { HolidayRequestManagementGroupsComponent } from '../../holiday-management/holiday-request-management-groups/holiday-request-management-groups.component';
import { HolidayRequestChangesService } from 'src/app/services/holiday-request-changes.service';
import { AppPermissions } from 'src/app/models/app-permission.model';
import { PermissionsService } from 'src/app/modules/pe-common/services/permissions.service';
import { HolidayRequest } from 'src/app/models/holiday-request.model';
import { EmployeeSelectionService } from 'src/app/modules/employee/services/employee-selection.service';

@Component({
  selector: 'app-holiday-request-groups',
  templateUrl: './holiday-request-groups.component.html',
  styleUrls: [
    './holiday-request-groups.component.css',
    './../../holiday-management/holiday-request-management-groups/holiday-request-management-groups.component.css',
    '../../maintenance.components.css',
    '../../maintenance-list.component.css',
  ]
})
export class HolidayRequestGroupsComponent extends HolidayRequestManagementGroupsComponent {
  constructor(
    protected holidayRequestService: HolidayRequestService,
    public holidayRequestStateService: HolidayRequestStateService,
    protected holidayRequestChangesService: HolidayRequestChangesService,
    public route: ActivatedRoute,
    public router: Router,
    public SSMService: SecurityStateManagementService,
    protected RNService: ResponseNotificationService,
    public accountService: AccountService,
    public employeeManagementService: EmployeeManagementService,
    public hotkeys: Hotkeys,
    protected spinner: NgxSpinnerService,
    protected translate: TranslateService,
    protected dialog: MatDialog,
    protected toastrService: ToastrService,
    protected employeeSelectionService: EmployeeSelectionService,
    protected permissionsService: PermissionsService,
    ) {
    super(
      holidayRequestService,
      holidayRequestStateService,
      holidayRequestChangesService,
      route,
      router,
      SSMService,
      RNService,
      accountService,
      employeeManagementService,
      hotkeys,
      spinner,
      translate,
      dialog,
      toastrService,
      employeeSelectionService,
      permissionsService,
    );

    this.baseRoute = '/actions/holiday_request';

    this.employeeView = true;

    this.canDirectAssignment = false;
    this.holidayRequestChangesService.onChangecanDirectAssignment(this.canDirectAssignment);

    this.viewPermission = [
      AppPermissions.ROLE_DATA.RRHH,
      AppPermissions.ROLE_DATA.RA,
      AppPermissions.ROLE_DATA.RP,
      AppPermissions.ROLE_DATA.Empleado
    ];
    this.createPermission = [
      AppPermissions.ROLE_DATA.RRHH,
      AppPermissions.ROLE_DATA.RA,
      AppPermissions.ROLE_DATA.RP,
      AppPermissions.ROLE_DATA.Empleado
    ];
    this.deletePermission = [
      AppPermissions.ROLE_DATA.RRHH,
      AppPermissions.ROLE_DATA.RA,
      AppPermissions.ROLE_DATA.RP,
      AppPermissions.ROLE_DATA.Empleado
    ];

    this.loadData = this.customLoadData;

    this.onInit = this.init;
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.subscriptions.push(
      this.holidayRequestService.holidayRequestGroupObservable.subscribe(holidays => {
        this.loadHolidayRequests(holidays);
      })
    );
  }

  /* ################################################################################################################## */
  /* ## DATA MANIPULATION
  /* ################################################################################################################## */
  /**
   * Recupera todas las solicitudes de vacaciones y muestra un toast. Se debe emplear sólo si se ha logrado actualizar
   * bien la solicitud pasada.
   *
   * @param holidayRequest - La solicitud de vacaciones a actualizada
   * @param newState - Nuevo estado de la solicitud de vacaciones
   */
  getAllHolidayRequestGroupBy(holidayRequest: HolidayRequest, newState: string) {
    this.holidayRequestService.getAllHolidayRequestGroupBy(this.filtersApiHolidays.getStrinFilterApi(), this.filtersRequestEmployeeIds.filters).subscribe(holidayRequestsGroups => {
      const filteredItems = this.assignState(holidayRequestsGroups.items);

      this.holidayRequestService.updateHolidayRequestsGroup(filteredItems);
      this.loadHolidayRequests(filteredItems);
      this.refreshSelectedEmployees(filteredItems);

      this.successToastRequest(newState, holidayRequest.reason);
      this.spinner.hide();
    });
  }

  // TODO: refacotrizar para simplificar
  loadHolidayRequests(items) {
    // Se vacían los arrays
    this.holidayRequestsGroups = new Array();
    this.holidayRequests = new Array();

    // Se prepara el array nominativo
    for (let stateName of this.tabStates) {
      this.holidayRequestsGroups[stateName] = [];
    }

    // Se rellena el array con los distintos items
    for (let item of items) {
      let temp = {
        ALL: new Array()
      };

      // Se recorren las HolidayRequest de cada item y se clasifican
      for (let holidayRequest of item) {
        if (!this.excludeStates.includes(holidayRequest.holidaysRequestState.stateCode)) {
          let indexName = HolidayRequestStateCode[holidayRequest.holidaysRequestState.stateCode];
          if (indexName.includes("PENDING")) {
            indexName = "PENDING";
          }

          if (temp.hasOwnProperty(indexName)) {
            temp[indexName].push(holidayRequest);
          } else {
            temp[indexName] = [holidayRequest];
          }

          this.holidayRequests.push(holidayRequest);
          temp["ALL"].push(holidayRequest);
        }
      }

      // Se añaden las HolidayRequest ya clasificadas a la lista agrupadas por Employee
      for (let element in this.holidayRequestsGroups) {
        if (Object.keys(temp).includes(element)) {
          this.holidayRequestsGroups[element].push(temp[element]);
        }
      }
    }
  }

  customLoadData() {
    /**
     * BUG: Si creamos una solicitud de vacaciones por ejemplo, se llama a loadData y como ya se ha lanzado
     * previamente pues no entra y no las refresca, la lógica estaba mal planteada ya que desde el principio no se puede
     * permitir que se hayan 7 peticiones al mismo servicio, para eso está lo de loadDataAlreadyLaunched que antes
     * era ignorado pero ahora al habilitarlo correctamente (para prevenir esas 7 llamadas) se produce un error
     * al crear...
     */
    this.spinner.show();
    const userId = this.accountService.currentUser.id;

    this.subscriptions.push(
      this.route.paramMap.subscribe(params => {
        this.employeeManagementService.getEmployeeByUserId(userId, false).subscribe(employeeAssociatedToUserLogged => {
          this.employeeAssociatedToUserLoggedId = employeeAssociatedToUserLogged.employeeId;

          this.currentEmployee = employeeAssociatedToUserLogged;
          this.employees = [ employeeAssociatedToUserLogged ];

          this.loadDataAlreadyLaunched = true;

          this.holidayRequestService.getAllHolidayRequestGroupBy(this.filtersApiHolidays.getStrinFilterApi()).subscribe(holidayRequestsGroups => {
            const filteredItems = this.assignState(holidayRequestsGroups.items);

            this.holidayRequestService.updateHolidayRequestsGroup(filteredItems);
            this.loadHolidayRequests(filteredItems);
          });

          this.spinner.hide();
        })
      })
    );
  }

  /* ################################################################################################################## */
  /* ## CRUD
  /* ################################################################################################################## */
  /**
   * Pasa la solicitud de vacaciones recibida como param y el nuevo estado.
   * @param holidayRequest - La solicitud de vacaciones a actualizar
   * @param newState - Estado al que debe camibar la solicitud de vacaciones
   */
  async updateHolidayRequest(holidayRequest: HolidayRequest, newState: string, reasonReject: string = "") {
    let holidayRequestNewState = this.holidayRequestStates.find(element => element.stateCode == HolidayRequestStateCode[newState]);
    this.spinner.show();

    this.holidayRequestService.changeStateHolidayRequest(holidayRequest.holidaysRequestId, holidayRequestNewState.holidaysRequestStateId, reasonReject).subscribe(response => {
      this.holidayRequestChangesService.refreshData(true);
      this.customLoadData();
    }, error => {
      this.translate.get('MESSAGES.ERROR.UPDATE_CONFLICT').subscribe((lang: string) => {
        this.toastrService.error(error.error, lang, { timeOut: 3000 });
      });

      this.spinner.hide();
    });
  }

  /**
   * Selecciona los estados que se deben manejar y los que se deben mostrar.
   */
  // setRequestStates() {
  //   this.tabStates = [
  //     // "REGISTERED",
  //     "PENDING",
  //     "APPROVED",
  //     "REJECTED",
  //     "ANNULLED",
  //     "ALL",
  //   ];

  //   this.excludeStates = [
  //     HolidayRequestStateCode.CANCELED,
  //     HolidayRequestStateCode.REGISTERED,
  //   ];

  //   this.visibleStatesCalendar = [
  //     HolidayRequestStateCode.APPROVED,
  //     HolidayRequestStateCode.PENDING_APROVAL,
  //     HolidayRequestStateCode.PENDING_ANNULLED,
  //     HolidayRequestStateCode.PENDING_CHANGE_APROVAL,
  //   ];

  //   this.setFilterHolidaysRequestsStates();
  // }
}
