import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { transformDate } from 'src/app/helpers/date.helper';

@Component({
  selector: 'app-area-manager-history-associate-dialog',
  templateUrl: './area-manager-history-associate-dialog.component.html',
  styleUrls: ['./area-manager-history-associate-dialog.component.css']
})
export class AreaManagerHistoryAssociateDialogComponent implements OnInit {
  /* ################################################################################################################## */
  /* ## ATRRIBUTES
  /* ################################################################################################################## */
  text  : string = '';

  formGroupControl = new FormGroup({
    startDate: new FormControl('', Validators.required),
    finishDate: new FormControl(''),
  });

  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    if (this.data) {
      if (this.data.text) {
        this.text = this.data.text;
      }

      if (this.data.startDate) {
        this.formGroupControl.get('startDate').setValue(this.data.startDate);
      }
      if (this.data.finishDate) {
        this.formGroupControl.get('finishDate').setValue(this.data.finishDate);
      }
    }
  }

  /* ################################################################################################################## */
  /* ## CUSTOM METHODS
  /* ################################################################################################################## */
  returnDate() {
    let  startDate = (this.formGroupControl.get('startDate').value && this.formGroupControl.get('startDate').value != '')
      ? transformDate(this.formGroupControl.get('startDate').value, 'YYYY-MM-DD')
      : undefined;
    let  finishDate = (this.formGroupControl.get('finishDate').value && this.formGroupControl.get('finishDate').value != '')
      ? transformDate(this.formGroupControl.get('finishDate').value, 'YYYY-MM-DD')
      : undefined;

    this.dialogRef.close(
      {
        startDate,
        finishDate,
      }
    );
  }
}
