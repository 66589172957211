import { Component, OnInit, ElementRef, ViewChild, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatDialog, MatPaginator, MatTableDataSource, PageEvent } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { ResponseNotificationService, Role } from 'inzo-portalempleado';

import { Absence } from 'src/app/models/absence.model';
import { AbsenceChangesService } from 'src/app/services/absence-changes.service';
import { AbsenceDocumentDialogComponent } from '../../absence-document-dialog/absence-document-dialog.component';
import { AbsenceEvent } from 'src/app/interfaces/events.interface';
import { AbsenceService } from 'src/app/services/absence.service';
import { AbsenceState } from 'src/app/models/absence-state.model';
import { AbsenceStateCode, AbsenceStateColor } from 'src/app/models/absence-state-code-enum.model';
import { AbsenceStateService } from 'src/app/services/absence-state.service';
import { AbsenceType } from 'src/app/models/absence-type.model';
import { AbsenceTypeService } from 'src/app/services/absence-type.service';
import { Employee } from '../../../models/employee.model';
import { EmployeeSelectionService } from 'src/app/modules/employee/services/employee-selection.service';
import { FiltersApi } from 'src/app/models/filters-api.model';
import { FiltersApiIds } from 'src/app/models/filters-api-ids.model';
import { ITLeaveManagementCalendarComponent } from '../it-leave-management-calendar/it-leave-management-calendar.component';
import { IUserRolesPermissions } from 'src/app/modules/pe-common/interfaces/permissions.interface';
import { RequestGroupsItems, RequestStateFilters } from 'src/app/interfaces/request.interface';
import { AbsenceStateLabel } from 'src/app/interfaces/absence.interface';
import { PermissionsService } from 'src/app/modules/pe-common/services/permissions.service';
import { IInzoPaginator } from 'src/app/modules/pe-common/interfaces/inzo-mat-paginator.interface';
import { InzoMatPaginatorComponent } from 'src/app/modules/pe-common/components/inzo-mat-paginator/inzo-mat-paginator.component';
import { isMinDate } from 'src/app/helpers/date.helper';

@Component({
  selector: 'app-it-leave-management-list',
  templateUrl: './it-leave-management-list.component.html',
  styleUrls: [
    './it-leave-management-list.component.css',
    './../../maintenance.components.css',
    '../../maintenance-list.component.css',
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ITLeaveManagementListComponent implements OnInit {
  @Input() filtersRequestsEmployeeIds: FiltersApiIds = new FiltersApiIds();

  @Input() absenceStateLabels: AbsenceStateLabel[] = [];
  @Input() absenceStates: AbsenceState[] = [];
  @Input() allAbsenceTypes: AbsenceType[] = [];
  @Input() requestStateFilters: RequestStateFilters;

  @Input() visibleStatesCalendar: AbsenceStateCode[] = [];
  filtersApi: FiltersApi = new FiltersApi();

  subscriptions: Subscription[] = [];

  currentEmployee: Employee;

  searching = true;
  filter = '';

  @ViewChild('inputsearch', { static: false }) inputSearch: ElementRef;
  @Input() calendar: ITLeaveManagementCalendarComponent;
  @Output() actionAbsence: EventEmitter<AbsenceEvent> = new EventEmitter();
  @Output() refreshData: EventEmitter<boolean> = new EventEmitter();

  // Permissions
  canDirectAssignment: boolean = false;
  directAssignment: boolean = false;

  roles: Role[];

  userRolesPermissions: IUserRolesPermissions = {
    isAdmin: false,
    isRRHH: false,
    isRA: false,
    isRP: false,
    isEmployee: false,
  }

  // ******************************************************************************************
  // Table
  // ******************************************************************************************
  columnsToDisplay = ['owner', 'counter', 'icon'];
  expandedElement: any;

  dataSource: MatTableDataSource<RequestGroupsItems> = new MatTableDataSource([]);

  inzoPaginatorFirstPage : IInzoPaginator = {
    currentPage: 1,
    pageSize: 10,
  };

  @ViewChild("requestPaginator", { static: false })
    requestPaginator: InzoMatPaginatorComponent = new InzoMatPaginatorComponent();
  // ******************************************************************************************

  constructor(
    protected employeeSelectionService: EmployeeSelectionService,
    protected absenceChangesService: AbsenceChangesService,
    protected absenceService: AbsenceService,
    public absenceStateService: AbsenceStateService,
    public absenceTypeService: AbsenceTypeService,
    protected spinner: NgxSpinnerService,
    protected RNService: ResponseNotificationService,
    protected dialog: MatDialog,
    protected translate: TranslateService,
    protected toastrService: ToastrService,
    protected permissionsService: PermissionsService,
  ) {
    this.requestPaginator.showFirstLastButtons = true ;
    this.requestPaginator.pageSizeOptions = [10, 20, 30, 40];
    this.requestPaginator.pageSizeDefault = this.inzoPaginatorFirstPage.pageSize;
    this.requestPaginator.currentPageDefault = this.inzoPaginatorFirstPage.currentPage;

    this.requestPaginator.inzoPaginator = this.inzoPaginatorFirstPage;
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.setDefaultFilters();

    // userRolesPermissions
    this.subscriptions.push(
      this.permissionsService.userRolesPermissions.subscribe(data => {
        this.userRolesPermissions = data;

        // acciones después de cargar permisos
      })
    );

    // roles
    this.subscriptions.push(
      this.permissionsService.roles.subscribe(data => {
        this.roles = data;
      })
    );

    this.subscriptions.push(
      this.absenceChangesService.canDirectAssignment.subscribe(checked => {
        this.canDirectAssignment = checked;
      })
    );

    this.subscriptions.push(
      this.absenceChangesService.check.subscribe(checked => {
        this.directAssignment = checked;
      })
    );

    this.subscriptions.push(
      this.absenceChangesService.refresh.subscribe((response) => {
        if (response == true) {
          this.loadData();

          this.absenceChangesService.refreshData(false);
        }
      })
    );

    this.subscriptions.push(
      this.employeeSelectionService.refresh.subscribe((response) => {
        if (response == true) {
          this.loadData();

          this.employeeSelectionService.refreshData(false);
        }
      })
    );

    this.subscriptions.push(
      this.employeeSelectionService.filterListApi.subscribe((filtersApi) => {
        if (filtersApi === null || filtersApi.filters.length == 0) {
          this.filtersApi.delete("validator");
          this.filtersApi.delete("hierarchy");
        } else {
          for (let filter of filtersApi.filters) {
            this.filtersApi.add(filter, true);
          }
        }

        this.loadData();
      })
    );

    this.subscriptions.push(
      this.employeeSelectionService.currentEmployee.subscribe((response) => {
        this.currentEmployee = response;
      })
    );

    this.subscriptions.push(
      this.employeeSelectionService.selectionEmployees.subscribe((response) => {
        let employeeIds: string[] = [];

        this.filtersRequestsEmployeeIds.reset();

        for (let index in response) {
          let item = response[index] as Employee;
          employeeIds.push(item.employeeId);
        }

        this.filtersRequestsEmployeeIds.addMultiple(employeeIds);
        this.loadData();
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnDestroy(): void {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
  //#endregion

  refresh() {
    this.refreshData.emit(true);
  }

  loadData() {
    this.spinner.show();

    this.subscriptions.push(
      this.absenceService.getAllAbsenceHierarchyGroupBy(this.filtersApi.getStrinFilterApi(), this.filtersRequestsEmployeeIds.filters).subscribe(absencesGroupGroups => {
        this.spinner.show();

        const {
          items,
          currentPage,
          pageSize,
          totalCount,
        } = absencesGroupGroups;

        this.requestPaginator.inzoPaginator = {
          currentPage,
          pageSize,
          totalCount
        };

        this.dataSource.data = items;

        if (this.currentEmployee) {
          for(let item of items) {
            const tempAbsence = item[0];
            if (tempAbsence.employeeId == this.currentEmployee.employeeId) {
              this.absenceChangesService.onChangeAbsences(item);
              break;
            }
          }
        } else {
          this.absenceChangesService.onChangeAbsences(null);
        }

        this.dataSource.filterPredicate = (data: RequestGroupsItems, filter: string): boolean => {
          const dataStr = Object.keys(data).reduce((currentTerm: string, key: string) => {
            return (currentTerm + (data as { [key: string]: any })[key] + '◬');
          }, '').normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

          const transformedFilter = filter.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

          return dataStr.indexOf(transformedFilter) !== -1;
        };

        this.spinner.hide();
      })
    );
  }

  setDefaultFilters() {
    this.setFirstPage();

    this.filtersApi.add(
      {
        field: "validator",
        value: "true"
      },
      true
    );

    this.filtersApi.add(
      {
        field: "hierarchy",
        value: "true"
      },
      true
    );

    this.filtersApi.add(
      {
        field: "related",
        value: "true"
      },
      true
    );

    this.filtersApi.add(
      {
        field: "Management",
        value: "true"
      },
      true
    );

    this.filtersApi.add(
      {
        field: "getAll",
        value: "true"
      },
      true
    );

    this.filtersApi.add(
      {
        field: "StateCode",
        value: AbsenceStateCode[AbsenceStateCode.APPROVED]
      },
      true
    );

    // Se incluye isMedicalLeave a true para obtener las bajas IT.
    this.filtersApi.add(
      {
        field: "isMedicalLeave",
        value: "true"
      },
      true
    );
  }

  getOwnerName(element) {
    return `(${element[0].employee.code}) ${element[0].employee.firstSurname} ${element[0].employee.secondSurname}, ${element[0].employee.name}`;
  }

  getRequestCounter(element) {
    return element.length;
  }

  assignState(items) {
    for (let item of items) {
      for (let absence of item) {
        // Se recupera el estado
        absence.absenceState = this.absenceStates.find(element => element.absenceStateId == absence.absenceStateId);
        absence.absenceType = this.allAbsenceTypes.find(element => element.absenceTypeId == absence.absenceTypeId);
      }
    }

    return items;
  }

  /**
   * Pasa la solicitud de ausencias recibida como param a estado 'CANCELED', para ello previamente se abrirá un
   * dialog en el que se deberá escribir la razón por la cual se rechaza la solicitud.
   *
   * @param absence - La solicitud de ausencias a cancelar
   */
  cancelAbsence(absence: Absence): void {
    let eventType = "CANCELED";

    this.actionAbsence.emit(
      {
        absence,
        eventType
      }
    );
  }

  /**
   * Lanza la edición de una Absence.
   * @param absence
   */
  editAbsence(absence: Absence) {
    this.calendar.currentEmployee = absence.employee;
    this.calendar.userRolesPermissions = this.userRolesPermissions;
    this.calendar.loadAndEditAbsence(absence);
  }

  /**
   * Recoge un evento del componente de listado de solicitudes y ejecuta la acción correspondiente a ese evento.
   *
   * @param absenceEvent evento recogido
   */
  actionAbsenceEvent(absenceEvent: AbsenceEvent) {
    this.actionAbsence.emit(absenceEvent);
  }

  /**
   * Pasa la solicitud de ausencias recibida como param a estado 'CANCELED'
   * @param absence - La solicitud de ausencias que será pasada al estado 'CANCELED'
   */
  abortAbsence(absence: Absence): void {
    this.actionAbsence.emit(
      {
        absence,
        eventType: "CANCELED"
      }
    );
  }

  /**
   * Abre una ventana de diálogo para opererar con los documentos asociados a una ausencia.
   *
   * @param absence ausencia para gestionar
   */
  openDocumentsDialog(absence: Absence) {
    let filtersApi: FiltersApi = new FiltersApi();

    filtersApi.add(
      {
        field: "related",
        value: "true"
      }
    );
    filtersApi.add(
      {
        field: "employeeId",
        value: absence.employeeId
      }
    );

    const absenceDocumentDialog = this.dialog.open(AbsenceDocumentDialogComponent, {
      width: '850px',
      data: {
        absenceService        : this.absenceService,
        translate             : this.translate,
        toastrService         : this.toastrService,
        RNService             : this.RNService,

        absence               : absence,
        filtersApi            : filtersApi,
      }
    });

    absenceDocumentDialog.afterClosed().subscribe((response) => {
      this.refresh();
    });
  }

  shownEmployeesChanged(shownEmployees: Employee[]) {
    if (shownEmployees && shownEmployees.length > 0) {
      let employeeIds: string[] = shownEmployees.map((emp) => emp.employeeId);

      this.filtersApi.addMultipleValue(
        "employeeId",
        employeeIds,
        true
      );

      this.loadData();
    }
  }

  //#region PAGINATOR
  /* ################################################################################################################## */
  /* ## PAGINATOR
  /* ################################################################################################################## */
  setApiPaginator(inzoPaginator: IInzoPaginator): void {
    this.filtersApi.add(
      {
        field: "currentPage",
        value: inzoPaginator.currentPage.toString(),
      },
      true
    );

    this.filtersApi.add(
      {
        field: "pageSize",
        value: inzoPaginator.pageSize.toString(),
      },
      true
    );
  }

  getPage(event: IInzoPaginator): void {
    this.setApiPaginator(event);

    this.loadData();
  }

  setFirstPage() {
    this.setApiPaginator(this.inzoPaginatorFirstPage);
  }
  //#endregion

  //#region UTILS
  /* ################################################################################################################## */
  /* ## UTILS
  /* ################################################################################################################## */
  /**
   * Función que determina si se deben mostrar los botones de aprobar o rechazar la solicitud
   *
   * @param absence - solicitud a valorar por la condición que dictará si mostrar el botón o no.
   * @returns
   */
   showApprovalButton(absence: Absence): boolean {
    let result = false;

    if(
      absence.absenceState.stateCode == AbsenceStateCode.PENDING_ANNULLED ||
      absence.absenceState.stateCode == AbsenceStateCode.PENDING_APROVAL
    ) {
      result = true;
    }

    return result;
  }

  /**
   * Función que muestra el botón de cancelar solicitud de vacaciones en cuanto a una condición
   *
   * @param absence - solicitud a valorar por la condición que dictará si mostrar el botón o no.
   * @returns
   */
   showCancelButton(absence: Absence): boolean {
    //const showButton = (moment(absence.startDate).isAfter(moment())) ? true : false;
    //se comenta la validación para que responsables y rrhh puedan anular en cualquier momento.
    let result = false;

    if(
      absence.absenceState.stateCode == AbsenceStateCode.PENDING_ANNULLED ||
      absence.absenceState.stateCode == AbsenceStateCode.PENDING_APROVAL
    ) {
      result = true;
    }

    return result;
  }

  isFinished(item: Absence): boolean {
    let result: boolean = false;

    if (item) {
      result = !isMinDate(item.finishDate);
    }

    return result;
  }

  // CALENDAR
  /**
   * Determina si se puede mostrar o no la solicitud en el calendario
   */
  toggleAbsenceLoadOnCalendar(absence: Absence) {
    this.spinner.show();

    if (this.visibleStatesCalendar.includes(absence.absenceState.stateCode)) {
      this.calendar.toggleAbsenceLoadOnCalendar(absence);
    }
  }

  /**
   * Selecciona las clases que debe tener la solicitud para su visualización correcta
   */
  getClassesItem(absence: Absence) {
    let assignedClasses = "mat-line my-auto";

    if (this.visibleStatesCalendar.includes(absence.absenceState.stateCode)) {
      assignedClasses += " pointer";

      assignedClasses += (absence.visibleOnYearCalendar) ? '' : ' unselect';
    }

    return assignedClasses;
  }

  /**
   * Selecciona el color del estado de la solicitud
   */
  getColorItem(absence: Absence) {
    let color = "";

    if (this.visibleStatesCalendar.includes(absence.absenceState.stateCode) && absence.visibleOnYearCalendar) {
      let stateName = AbsenceStateCode[absence.absenceState.stateCode];

      color = AbsenceStateColor[stateName];
    }

    return color;
  }

  requestFiltersToString(): string[] {
    let result : string[] = [];

    this.requestStateFilters.forEach(item => {
      result.push(item.toString());
    });

    return result;
  }
  //#endregion
}
