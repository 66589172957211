import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from './components/layout.component';
import { AuthGuard } from 'inzo-portalempleado';
import { DepartmentComponent } from './components/department/department.component';
import { DepartmentFormComponent } from './components/department/department-form/department-form.component';
import { HeadquartersComponent } from './components/headquarters/headquarters.component';
import { HeadquartersFormComponent } from './components/headquarters/headquarters-form/headquarters-form.component';
import { CentreComponent } from './components/centre/centre.component';
import { CentreFormComponent } from './components/centre/centre-form/centre-form.component';
import { IRPFComponent } from './components/irpf/irpf.component';
import { IRPFFormComponent } from './components/irpf/irpf-form/irpf-form.component';
import { EmployeeTypeComponent } from './components/employee-type/Employee-type.component';
import { EmployeeTypeFormComponent } from './components/employee-type/employee-type-form/employee-type-form.component';
import { CVComponent } from './components/cv/cv.component';
import { CVFormComponent } from './components/cv/cv-form/cv-form.component';
import { PayrollComponent } from './components/payroll/payroll.component';
import { PayrollFormComponent } from './components/payroll/payroll-form/payroll-form.component';
import { WorkCalendarComponent } from './components/work-calendar/work-calendar.component';
import { WorkCalendarFormComponent } from './components/work-calendar/work-calendar-form/work-calendar-form.component';
import { LanguageComponent } from './components/language/language.component';
import { LanguageFormComponent } from './components/language/language-form/language-form.component';
import { CourseComponent } from './pages/formations/course/course.component';
import { CourseFormComponent } from './components/course/course-form/course-form.component';
import { CourseTypeComponent } from './components/course-type/course-type.component';
import {CourseTypeFormComponent} from './components/course-type/course-type-form/course-type-form.component';
import { AreaComponent } from './components/area/area.component';
import { AreaFormComponent } from './components/area/area-form/area-form.component';
import { EmployeeManagementComponent } from './components/employeeManagement/employeeManagement.component';
import { EmployeeManagementFormComponent } from './components/employeeManagement/employeeManagement-form/employeeManagement-form.component';
import { DegreeComponent } from './components/degree/degree.component';
import { DegreeFormComponent } from './components/degree/degree-form/degree-form.component';
import { PersonalDataComponent } from './components/personal-data/personal-data.component';
import { DcrComponent } from './components/dcr/dcr.component';
import { PersonalIRPFComponent } from './components/personal-irpf/personal-irpf.component';
import { PersonalCourseComponent } from './components/personal-course/personal-course.component';
import { PersonalDegreeComponent } from './components/personal-degree/personal-degree.component';
import { PersonalLanguageComponent } from './components/personal-language/personal-language.component';
import { PersonalPayrollComponent } from './components/personal-payroll/personal-payroll.component';
import { PersonalCVComponent } from './components/personal-cv/personal-cv.component';
import { HolidayRequestManagementComponent } from './components/holiday-management/holiday-request-management.component';
import { AbsenceManagementComponent } from './components/absence-management/absence-management.component';
import { ITLeaveManagementComponent } from './components/it-leave-management/it-leave-management.component';
import { EmployeeManagementListComponent } from './components/employeeManagement/employeeManagement-list/employeeManagement-list.component';
import { AbsenceComponent } from './components/absence/absence.component';
import { ITLeaveComponent } from './components/it-leave/it-leave.component';
import { HolidayRequestComponent } from './components/holiday-request/holiday-request.component';
import { CloseYearLayoutComponent } from './modules/close-year/components/close-year-layout/close-year-layout.component';
import { TrainingCenterComponent } from './components/training-center/training-center.component';
import { TrainingCenterFormComponent } from './components/training-center/training-center-form/training-center-form.component';
import { AbsenceTypeMaintenanceComponent } from './components/absence-type-maintenance/absence-type-maintenance.component';
import { AbsenceTypeMaintenanceFormComponent } from './components/absence-type-maintenance/absence-type-maintenance-form/absence-type-maintenance-form.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { CertificationCourseComponent } from './pages/formations/certification-course/certification-course.component';
import { CertificationCourseFromComponent } from './components/course/course-form/certification-course-from.component';
import { DcrComponentEmployee } from './components/dcr/dcr.component-employee';
import { CategoryComponent } from './pages/managements/category/category.component';
import { CategoryFormComponent } from './components/category/category-form/category-form.component';
import { AnnualWorkHourComponent } from './pages/managements/annual-work-hour/annual-work-hour.component';
import { AnnualWorkHourFormComponent } from './components/annual-work-hour/annual-work-hour-form/annual-work-hour-form.component';
import { LaborAgreementComponent } from './pages/managements/labor-agreement/labor-agreement.component';
import { LaborAgreementFormComponent } from './components/labor-agreement/labor-agreement-form/labor-agreement-form.component';
import { ContractTypeComponent } from './pages/managements/contract-type/contract-type.component';
import { ContractTypeFormComponent } from './components/contract/contract-type-form/contract-type-form.component';
import { ContractDurationTypeComponent } from './pages/managements/contract-duration-type/contract-duration-type.component';
import { ContractDurationTypeFormComponent } from './components/contract/contract-duration-type-form/contract-duration-type-form.component';
import { ContractWorkingHoursTypeComponent } from './pages/managements/contract-working-hours-type/contract-working-hours-type.component';
import { ContractWorkingHoursTypeFormComponent } from './components/contract/contract-working-hours-type-form/contract-working-hours-type-form.component';
import { PrcContractComponent } from './pages/managements/prc-contract/prc-contract.component';
import { PrcContractFormComponent } from './components/prc-contract/prc-contract-form/prc-contract-form.component';


// PERSONAL DATA
const personalData = [
  {
    path: '',
    component: PersonalDataComponent,
    children: []
  }
];

// MAINTENANCES
const maintenances = [
  {
    path: 'employeeType',
    component: EmployeeTypeComponent,
    children: [
      {
        path: '',
        component: EmployeeTypeFormComponent
      },
      {
        path: ':id',
        component: EmployeeTypeFormComponent,
      }
    ]
  },
  {
    path: 'area',
    component: AreaComponent,
    children: [
      {
        path: '',
        component: AreaFormComponent
      },
      {
        path: ':id',
        component: AreaFormComponent,
      }
    ]
  },
  {
    path: 'headquarters',
    component: HeadquartersComponent,
    children: [
      {
        path: '',
        component: HeadquartersFormComponent
      },
      {
        path: ':id',
        component: HeadquartersFormComponent,
      }
    ]
  },
  {
    path: 'department',
    component: DepartmentComponent,
    children: [
      {
        path: '',
        component: DepartmentFormComponent
      },
      {
        path: ':id',
        component: DepartmentFormComponent,
      }
    ]
  },
  {
    path: 'course_type',
    component: CourseTypeComponent,
    children: [
      {
        path: '',
        component: CourseTypeFormComponent
      },
      {
        path: ':id',
        component: CourseTypeFormComponent,
      }
    ]
  },
  {
    path: 'training_center',
    component: TrainingCenterComponent,
    children: [
      {
        path: '',
        component: TrainingCenterFormComponent
      },
      {
        path: ':id',
        component: TrainingCenterFormComponent,
      }
    ]

  },
  {
    path: 'centre',
    component: CentreComponent,
    children: [
      {
        path: '',
        component: CentreFormComponent
      },
      {
        path: ':id',
        component: CentreFormComponent,
      }
    ]
  },
  {
    path: 'category',
    component: CategoryComponent,
    children: [
      {
        path: '',
        component: CategoryFormComponent
      },
      {
        path: ':id',
        component: CategoryFormComponent,
      }
    ]
  },
  {
    path: 'annual_workhours',
    component: AnnualWorkHourComponent,
    children: [
      {
        path: '',
        component: AnnualWorkHourFormComponent
      },
      {
        path: ':id',
        component: AnnualWorkHourFormComponent,
      }
    ]
  },
  {
    path: 'labor_agreements',
    component: LaborAgreementComponent,
    children: [
      {
        path: '',
        component: LaborAgreementFormComponent
      },
      {
        path: ':id',
        component: LaborAgreementFormComponent,
      }
    ]
  },
  {
    path: 'contract_types',
    component: ContractTypeComponent,
    children: [
      {
        path: '',
        component: ContractTypeFormComponent
      },
      {
        path: ':id',
        component: ContractTypeFormComponent,
      }
    ]
  },
  {
    path: 'contract_duration_types',
    component: ContractDurationTypeComponent,
    children: [
      {
        path: '',
        component: ContractDurationTypeFormComponent
      },
      {
        path: ':id',
        component: ContractDurationTypeFormComponent,
      }
    ]
  },
  {
    path: 'contract_workinghours_types',
    component: ContractWorkingHoursTypeComponent,
    children: [
      {
        path: '',
        component: ContractWorkingHoursTypeFormComponent
      },
      {
        path: ':id',
        component: ContractWorkingHoursTypeFormComponent,
      }
    ]
  },
  {
    path: 'prc_contracts',
    component: PrcContractComponent,
    children: [
      {
        path: '',
        component: PrcContractFormComponent
      },
      {
        path: ':id',
        component: PrcContractFormComponent,
      }
    ]
  },
  {
    path: 'absence_type',
    component: AbsenceTypeMaintenanceComponent,
    children: [
      {
        path: '',
        component: AbsenceTypeMaintenanceFormComponent
      },
      {
        path: ':id',
        component: AbsenceTypeMaintenanceFormComponent,
      }
    ]
  },
  {
    path: 'work_calendar',
    component: WorkCalendarComponent,
    children: [
      {
        path: '',
        component: WorkCalendarFormComponent
      },
      {
        path: ':id',
        component: WorkCalendarFormComponent,
      }
    ]
  },
  {
    path: 'close_year',
    component: CloseYearLayoutComponent,
    children: [
      {
        path: '',
        component: CloseYearLayoutComponent
      },
      {
        path: ':id',
        component: CloseYearLayoutComponent,
      }
    ]
  },
];

// FORMATIONS
const formations = [
  {
    path: 'degree',
    component: DegreeComponent,
    children: [
      {
        path: '',
        component: DegreeFormComponent
      },
      {
        path: ':id',
        component: DegreeFormComponent,
      }
    ]
  },
  {
    path: 'language',
    component: LanguageComponent,
    children: [
      {
        path: '',
        component: LanguageFormComponent
      },
      {
        path: ':id',
        component: LanguageFormComponent,
      }
    ]
  },
  {
    path: 'course',
    component: CourseComponent,
    children: [
      {
        path: '',
        component: CourseFormComponent
      },
      {
        path: ':id',
        component: CourseFormComponent,
      }
    ]
  },
  {
    path: 'certificationCourse',
    component: CertificationCourseComponent,
    children: [
      {
        path: '',
        component: CertificationCourseFromComponent
      },
      {
        path: ':id',
        component: CertificationCourseFromComponent,
      }
    ]
  },
  {
    path: 'cv',
    component: CVComponent,
    children: [
      {
        path: '',
        component: CVFormComponent
      },
      {
        path: ':id',
        component: CVFormComponent,
      }
    ]
  },
];

// MANAGEMENTS
const managements = [
  {
    path: 'employeeManagement',
    component: EmployeeManagementComponent,
    children: [
      {
        path: ':id',
        component: EmployeeManagementFormComponent,
      },
      {
        path: '',
        component: EmployeeManagementListComponent
      },
    ]
  },
  {
    path: 'holiday_request_management',
    component: HolidayRequestManagementComponent,
  },
  {
    path: 'absence_management',
    component: AbsenceManagementComponent,
  },
  {
    path: 'it_leave_management',
    component: ITLeaveManagementComponent,
  },
  {
    path: 'payroll',
    component: PayrollComponent,
    children: [
      {
        path: '',
        component: PayrollFormComponent
      },
      {
        path: ':id',
        component: PayrollFormComponent,
      }
    ]
  },
  {
    path: 'irpf',
    component: IRPFComponent,
    children: [
      {
        path: '',
        component: IRPFFormComponent
      },
      {
        path: ':id',
        component: IRPFFormComponent,
      }
    ]
  },
  {
    path: 'dataChange',
    component: DcrComponent,
  },
];

// ACTIONS
const actions = [
  {
    path: 'dataChange',
    component: DcrComponentEmployee,
    children: [],
  },
  {
    path: 'holiday_request',
    component: HolidayRequestComponent,
    children: [],
  },
  {
    path: 'absence',
    component: AbsenceComponent,
    children: [],
  },
  {
    path: 'it_leave',
    component: ITLeaveComponent,
    children: [],
  }
];

// DOCUMENTS
const documents = [
  {
    path: 'personalIRPF',
    children: [
        {
            path: '',
            component: PersonalIRPFComponent,
        },
        {
            path: ':id',
            component: PersonalIRPFComponent,
        },
    ],
  },
  {
    path: 'personalCourses',
    children: [
        {
            path: '',
            component: PersonalCourseComponent,
        },
        {
            path: ':id',
            component: PersonalCourseComponent,
        },
    ],
  },
  {
    path: 'personalDegrees',
    children: [
        {
            path: '',
            component: PersonalDegreeComponent,
        },
        {
            path: ':id',
            component: PersonalDegreeComponent,
        },
    ],
  },
  {
    path: 'personalLanguages',
    children: [
        {
            path: '',
            component: PersonalLanguageComponent,
        },
        {
            path: ':id',
            component: PersonalLanguageComponent,
        },
    ],
  },
  {
    path: 'personalPayrolls',
    children: [
        {
            path: '',
            component: PersonalPayrollComponent,
        },
        {
            path: ':id',
            component: PersonalPayrollComponent,
        },
    ],
  },
  {
    path: 'personalCVs',
    children: [
        {
            path: '',
            component: PersonalCVComponent,
        },
        {
            path: ':id',
            component: PersonalCVComponent,
        },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot([
      {
        path: '',
        pathMatch: 'prefix',
        component: LayoutComponent,
        canActivateChild: [AuthGuard],
        children: [
          {
            path: 'core',
            loadChildren: () => import('./lib-routing.module').then(m => m.LibRoutingModule)
          },
          {
            path: '',
            redirectTo: 'personal_data',
            pathMatch: 'full'
          },
          {
            path: 'dashboard',
            component: DashboardComponent,
            children: []
          },
          {
            path: 'personal_data',
            component: PersonalDataComponent,
            children: []
          },
          {
            path: 'maintenances',
            children: maintenances
          },
          {
            path: 'managements',
            children: managements
          },
          {
            path: 'formations',
            children: formations
          },
          {
            path: 'actions',
            children: actions
          },
          {
            path: 'documents',
            children: documents
          },
        ],
      },
      {
        path: 'security',
        pathMatch: 'prefix',
        loadChildren: () => import('./app-security-routing.module').then(m => m.AppSecurityRoutingModule)
      },
    ]),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
