import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AccountService, Hotkeys, ResponseNotificationService, SecurityStateManagementService } from 'inzo-portalempleado';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AbsenceStateLabel } from 'src/app/interfaces/absence.interface';
import { AbsenceStateCode } from 'src/app/models/absence-state-code-enum.model';
import { AppPermissions } from 'src/app/models/app-permission.model';
import { EmployeeSelectionService } from 'src/app/modules/employee/services/employee-selection.service';
import { PermissionsService } from 'src/app/modules/pe-common/services/permissions.service';
import { AbsenceChangesService } from 'src/app/services/absence-changes.service';
import { AbsenceStateService } from 'src/app/services/absence-state.service';
import { AbsenceTypeService } from 'src/app/services/absence-type.service';
import { AbsenceService } from 'src/app/services/absence.service';
import { EmployeeManagementService } from 'src/app/services/employee-management.service';
import { ITLeaveManagementGroupsComponent } from '../../it-leave-management/it-leave-management-groups/it-leave-management-groups.component';

@Component({
  selector: 'app-it-leave-groups',
  templateUrl: './it-leave-groups.component.html',
  styleUrls: ['./it-leave-groups.component.css']
})
export class ITLeaveGroupsComponent extends ITLeaveManagementGroupsComponent {
  absenceStateLabels: AbsenceStateLabel[] = [];
  searching = true;
  filter = '';

  constructor(
    protected absenceService: AbsenceService,
    public absenceStateService: AbsenceStateService,
    public absenceTypeService: AbsenceTypeService,
    public route: ActivatedRoute,
    public router: Router,
    public SSMService: SecurityStateManagementService,
    protected RNService: ResponseNotificationService,
    public accountService: AccountService,
    public employeeManagementService: EmployeeManagementService,
    public hotkeys: Hotkeys,
    protected spinner: NgxSpinnerService,
    protected translate: TranslateService,
    protected dialog: MatDialog,
    protected toastrService: ToastrService,
    protected absenceChangesService: AbsenceChangesService,
    protected employeeSelectionService: EmployeeSelectionService,
    protected permissionsService: PermissionsService,
    ) {
    super(
      absenceService,
      absenceStateService,
      absenceTypeService,
      route,
      router,
      SSMService,
      RNService,
      accountService,
      employeeManagementService,
      hotkeys,
      spinner,
      translate,
      dialog,
      toastrService,
      absenceChangesService,
      employeeSelectionService,
      permissionsService,
    );

    this.baseRoute = '/actions/it_leave';

    this.employeeView = true;

    this.viewPermission = [
      AppPermissions.ROLE_DATA.RRHH,
      AppPermissions.ROLE_DATA.Empleado,
      AppPermissions.ROLE_DATA.Empleado
    ];
    this.createPermission = [
      AppPermissions.ROLE_DATA.RRHH,
      AppPermissions.ROLE_DATA.Empleado,
      AppPermissions.ROLE_DATA.Empleado
    ];
    this.deletePermission = [
      AppPermissions.ROLE_DATA.RRHH,
      AppPermissions.ROLE_DATA.Empleado,
      AppPermissions.ROLE_DATA.Empleado
    ];

    let textTab = '';
    this.translate.get(`ABSENCE_STATES.APPROVED`).subscribe((a: string) => {
      textTab = a;
    });

    this.absenceStateLabels.push({alias: textTab, stateName: 'APPROVED'});

    // Modifica las solicitudes a mostrar
    this.tabStates.unshift("REGISTERED");
    this.excludeStates.shift();
    this.visibleStatesCalendar.unshift(AbsenceStateCode.REGISTERED);

    this.loadData = () => {
      this.spinner.show();
      const userId = this.accountService.currentUser.id;

      this.subscriptions.push(
        this.route.paramMap.subscribe(params => {
          this.employeeManagementService.getEmployeeByUserId(userId, false).subscribe(employeeAssociatedToUserLogged => {
            this.employeeAssociatedToUserLoggedId = employeeAssociatedToUserLogged.employeeId;

            this.currentEmployee = employeeAssociatedToUserLogged;
            this.employees = [ this.currentEmployee ];

            if (!this.loadDataAlreadyLaunched) {
              // Se añade el validatorId a los filtros
              // Filtros para búsqueda de solicitudes
              // this.filtersApiAbsences.add(
              //   {
              //     field: "employeeId",
              //     value: this.employeeAssociatedToUserLoggedId
              //   },
              // );
              // Incluimos el isMedicalLeave a true para obtener las bajas por enfermedad común o accidente (IT).
              this.filtersApiAbsences.add(
                {
                  field: "isMedicalLeave",
                  value: "true"
                },
              );

              this.loadDataAlreadyLaunched = true;
              this.absenceService.getAllAbsenceGroupBy(this.filtersApiAbsences.getStrinFilterApi()).subscribe(absencesGroups => {
                const filteredItems = this.assignState(absencesGroups.items);

                this.absenceService.updateAbsencesGroup(filteredItems);

                this.loadAbsences(filteredItems);
              });
            }

            this.spinner.hide();
          })
        })
      );
    };
  }

  /**
   * Selecciona los estados que se deben manejar y los que se deben mostrar.
   */
  setRequestStates() {
    this.tabStates = ["APPROVED"];

    this.excludeStates = [
      AbsenceStateCode.REGISTERED,
      AbsenceStateCode.CANCELED,
      AbsenceStateCode.REJECTED,
      AbsenceStateCode.PENDING_APROVAL,
      AbsenceStateCode.PENDING_ANNULLED,
    ];

    this.visibleStatesCalendar = [
      AbsenceStateCode.APPROVED,
    ];
  }
}
