import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { Subscription } from "rxjs";
import { Router, ActivatedRoute } from "@angular/router";
import {
  ResponseNotificationService,
  BaseListComponent,
  SecurityStateManagementService,
  AccountService,
  Hotkeys,
  Role,
} from "inzo-portalempleado";
import { AppPermissions } from "src/app/models/app-permission.model";
import { NgxSpinnerService } from "ngx-spinner";
import { Payroll } from "src/app/models/payroll.model";
import { PayrollService } from "src/app/services/payroll.service";
import { EmployeeManagementService } from "src/app/services/employee-management.service";
import { FiltersApi } from "src/app/models/filters-api.model";
import { EmployeeDocument } from '../../../models/employee-document.model';
import { FileService } from "src/app/services/file.service";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-personal-payroll-list",
  templateUrl: "personal-payroll-list.component.html",
  styleUrls: [
    "./../../maintenance.components.css",
    "../../maintenance-list.component.css",
  ],
})
export class PersonalPayrollListComponent
  extends BaseListComponent<Payroll>
  implements OnInit, OnDestroy
{
  payrolls: Payroll[] = [];
  subscription: Subscription;
  searching = true;
  filter = "";
  mediaSize: string;
  watcher: Subscription;

  filtersApi: FiltersApi = new FiltersApi();

  canAdd = false;
  canDelete = false;

  @ViewChild("inputsearch", { static: false }) inputSearch: ElementRef;

  years: number[] = createYearRange();

  selectYearControl = new FormControl(
    this.years[0]
  );
  roles: Role[];

  constructor(
    private fileService: FileService,
    private payrollService: PayrollService,
    public route: ActivatedRoute,
    public router: Router,
    public SSMService: SecurityStateManagementService,
    private RNService: ResponseNotificationService,
    public accountService: AccountService,
    public hotkeys: Hotkeys,
    private spinner: NgxSpinnerService,
    private employeeManagementService: EmployeeManagementService
  ) {
    super(accountService, SSMService, router, hotkeys);
    this.baseRoute = "/documents/personalPayrolls";

    this.viewPermission = [
      AppPermissions.PERMISSIONS_DATA.RRHH.value,
      AppPermissions.PERMISSIONS_DATA.Manager.value,
      AppPermissions.PERMISSIONS_DATA.Employee.value,
    ];
    this.createPermission = [AppPermissions.PERMISSIONS_DATA.RRHH.value];
    this.deletePermission = [AppPermissions.PERMISSIONS_DATA.RRHH.value];
    if (
      this.accountService.userHasPermission(
        AppPermissions.PERMISSIONS_DATA.RRHH.value
      )
    ) {
    }

    this.onBuild = () => {};

    this.filtersApi.add(
      {
        field: "related",
        value: "true",
      }
    );

    this.filtersApi.add(
      {
        field: "year",
        value: this.years[0].toString(),
      }, true
    );

    this.loadData = () => {
      this.spinner.show();

      this.payrollService
        .GetAllPayrollsByEmployee(this.filtersApi.getStrinFilterApi())
        .subscribe(
          (payrolls) => {
            this.payrollService.updatepayrolls(payrolls.items);
            this.payrolls = payrolls.items;
            this.spinner.hide();
          },
          (error) => {
            this.RNService.showError(error);
            this.spinner.hide();
          }
        );
    };

    this.onInit = () => {
      this.loadData();

      this.payrollService.payrollObservable.subscribe((payrolls) => {
        this.payrolls = payrolls;
      });
    };
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.registerPermissionsLoadedSubscription();
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  registerPermissionsLoadedSubscription() {
    if (!this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription = this.accountService.userPermissionsLoaded.subscribe(() => {
        this.accountService.userRolesLoaded.subscribe((rolesResponse) => {
          this.roles = this.accountService.roles;

          this.onInit();
        });
      });
    } else {
      this.onInit();
    }
  }
  //#endregion

  yearFilter(value) {
    this.spinner.show();

    this.filtersApi.add(
      {
        field: "year",
        value: value.toString(),
      }, true
    );

    this.loadData();
  }

  downloadDocument(document: EmployeeDocument) {
    this.fileService.getFile(document.fileId).subscribe(response => {
      this.fileService.downloadBase64File(response);
    });
  }

  openDocument(document: EmployeeDocument) {
    this.fileService.getFile(document.fileId).subscribe(response => {
      this.fileService.openFile(response);
    });
  }
}

export function createYearRange(startYear: number = 2015): number[] {
  let result: number[] = [];

  const now = new Date();
  const finishYear = now.getFullYear();

  for (let i = finishYear; i >= startYear; i--) {
    result.push(i);
  }

  return result;
}
