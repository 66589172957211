import { AreaManagerHistory } from './area-manager-history.model';
import { Company } from './company.model';
import { Employee } from './employee.model';

export class Area {
  areaId: string;
  code: string;
  description: string;

  //Relations
  companyId: string;
  company: Company;
  managers: Employee[];
  areaManagersHistory: AreaManagerHistory[];

  constructor(obj?: Partial<Area>) {
    Object.assign(this, obj);
  }
}
