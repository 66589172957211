import { getDateString } from "../helpers/date.helper";
import { AbsenceDocument } from "./absence-document.model";
import { AbsenceState } from "./absence-state.model";
import { AbsenceType } from "./absence-type.model";
import { AHRChanges } from "./ahr-changes.model";
import { Employee } from "./employee.model";

export class Absence {
  absenceId             : string;
  description           : string;
  startDate             : any;
  finishDate            : any;
  expectedFinishDate    : any;
  expectedFinishDates   : any[];
  requestedDates        : any[];
  reason                : string;
  createdDate           : string;
  updatedDate           : string;

  visibleOnYearCalendar : boolean;
  isMedicalLeave        : boolean;

  //Relations
  employeeId            : string;
  employee              : Employee;

  absenceDocuments      : AbsenceDocument[];

  absenceStateId        : string;
  absenceState          : AbsenceState;
  absenceChanges        : AHRChanges[];

  absenceTypeId         : string;
  absenceType           : AbsenceType;

  constructor(obj?: Partial<Absence>) {
    Object.assign(this, obj);
  }
}
