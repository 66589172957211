// ################################################################################################################
// ## IMPORTS
// ################################################################################################################
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { Employee } from "src/app/models/employee.model";
import {
  MatOption,
  MatSlideToggleChange,
  MatTableDataSource,
  PageEvent,
} from "@angular/material";
import { EmployeeManagementService } from "src/app/services/employee-management.service";
import { NgxSpinnerService } from "ngx-spinner";
import {
  AccountService,
  ResponseNotificationService,
} from "inzo-portalempleado";
import { EmployeeSituationCode } from "src/app/models/employee-situation-enum.model";
import * as _ from "lodash";
import { debounceTime, filter, switchMap } from "rxjs/operators";
import { Subject, Subscription } from "rxjs";
import { FormControl, FormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

import { FilterApi } from "src/app/interfaces/filter-api.interface";
import { IInzoPaginator } from "src/app/modules/pe-common/interfaces/inzo-mat-paginator.interface";
import { IUserRolesPermissions } from "src/app/modules/pe-common/interfaces/permissions.interface";
import { EmployeeEvent } from 'src/app/interfaces/events.interface';

import { InzoMatPaginatorComponent } from "src/app/modules/pe-common/components/inzo-mat-paginator/inzo-mat-paginator.component";

import { FiltersApi } from "src/app/models/filters-api.model";

import { Area } from "src/app/models/area.model";
import { AreaService } from "src/app/services/area.service";
import { EmployeeSelectionService } from "../../../services/employee-selection.service";
import { EmployeeSituation } from "src/app/models/employee-situation.model";
import { EmployeeSituationService } from "src/app/services/employee-situation.service";

export enum EmployeeHierarchyCode {
  ALL       = 'ALL',
  DIRECT    = 'DIRECT',
  HIERARCHY = 'HIERARCHY',
}

// ################################################################################################################
// ## CLASS EmployeeTableComponent
// ################################################################################################################
@Component({
  selector: 'app-employee-table',
  templateUrl: './employee-table.component.html',
  styleUrls: [
    './employee-table.component.css',
    '../../../../../components/maintenance.components.css',
    '../../../../../components/maintenance-list.component.css',
  ],
})
export class EmployeeTableComponent implements OnInit {
  //#region ARGUMENTS
  // ##############################################################################################################
  // ## Arguments
  // ##############################################################################################################
  subscriptions: Subscription[] = [];
  employeeAssociatedToUserLoggedId = null;

  // Título
  @Input() title: string = "";

  // Columnas visibles
  @Input() displayedColumns: string[] = [
    "code",
    "firstSurname",
    "secondSurname",
    "name",
    "companyEmail",
    "companyPhone",
  ];

  @ViewChild("tableFilterInput", { static: true }) tableFilterInput: ElementRef;

  // Permisos
  @Input() userRolesPermissions: IUserRolesPermissions = {
    isAdmin: false,
    isRRHH: false,
    isRA: false,
    isRP: false,
    isEmployee: false,
  }

  // --------------------------------------------------------------------------------------------------------------
  // -- Empleados
  // --------------------------------------------------------------------------------------------------------------
  @Input() employees: Employee[] = [];
  @Input() excludeEmployees: Employee[] = [];
  @Input() selectedEmployees: Employee[] = [];

  filtersApiEmployee: FiltersApi = new FiltersApi();

  dataSource: MatTableDataSource<Employee>;
  inzoPaginatorFirstPage : IInzoPaginator = {
    currentPage: 1,
    pageSize: 10,
  };

  @ViewChild("employeePaginator", { static: false })
  employeePaginator: InzoMatPaginatorComponent = new InzoMatPaginatorComponent();

  @Output() selectEmployee: EventEmitter<EmployeeEvent> = new EventEmitter();
  @Output() shownEmployeesChanged: EventEmitter<Employee[]> = new EventEmitter();

  // --------------------------------------------------------------------------------------------------------------
  // -- Filtros
  // --------------------------------------------------------------------------------------------------------------
  @Input() showEmployeeAreaFilters: boolean = false;
  @Input() showEmployeesHierarchyFilters: boolean = false;
  @Input() showEmployeeSituationFilters: boolean = false;

  // --------------------------------------------------------------------------------------------------------------
  // -- Valores por defecto
  // --------------------------------------------------------------------------------------------------------------
  @Input() hierarchyFilter: boolean = false;
  @Input() takeValidatorHierarchy: boolean = false;

  // --------------------------------------------------------------------------------------------------------------
  // -- Búsquedas
  // --------------------------------------------------------------------------------------------------------------
  @Output() onDebounce: EventEmitter<string> = new EventEmitter();
  debouncer: Subject<string> = new Subject();
  filter = "";

  // --------------------------------------------------------------------------------------------------------------
  // -- Filters Area
  // --------------------------------------------------------------------------------------------------------------
  areas: Area[];
  myAreas: Area[];
  defaultAreaFiltersRRHH: string[] = ['all'];
  defaultAreaFiltersRA: string[] = ['myAreas'];

  areafilters = new FormGroup(
    {
      area: new FormControl(''),
    }
  );
  @ViewChild('allAreaSelected', { static: false }) private allAreaSelected: MatOption;
  @ViewChild('myAreaSelected', { static: false }) private myAreaSelected: MatOption;

  // --------------------------------------------------------------------------------------------------------------
  // -- Hierarchy filters
  // --------------------------------------------------------------------------------------------------------------
  employeeHierarchy: string[] = [
    EmployeeHierarchyCode.DIRECT,
    EmployeeHierarchyCode.HIERARCHY,
    EmployeeHierarchyCode.ALL,
  ];
  @Input() defaultHierarchyFilter: EmployeeHierarchyCode = EmployeeHierarchyCode.ALL;
  currentHierarchyFilter: EmployeeHierarchyCode = this.defaultHierarchyFilter;

  hierarchyFilters = new FormGroup(
    {
      hierarchy: new FormControl(''),
    }
  );

  // --------------------------------------------------------------------------------------------------------------
  // -- Situation Filters
  // --------------------------------------------------------------------------------------------------------------
  employeeSituations: EmployeeSituation[];
  defaultSituationFilter: EmployeeSituationCode = EmployeeSituationCode.ACTIVE;
  currentSituationFilter: EmployeeSituationCode | string = this.defaultSituationFilter;

  situationFilters = new FormGroup(
    {
      situation: new FormControl(''),
    }
  );
  //#endregion

  //#region CONSTRUCTOR
  // ##############################################################################################################
  // ## Constructor
  // ##############################################################################################################
  constructor(
    protected areaService: AreaService,
    protected employeeManagementService: EmployeeManagementService,
    protected employeeSelectionService: EmployeeSelectionService,
    protected employeeSituationService: EmployeeSituationService,
    protected spinner: NgxSpinnerService,
    protected accountService: AccountService,
    protected RNService: ResponseNotificationService,
    protected translate: TranslateService,
  ) {
    this.employeePaginator.showFirstLastButtons = true ;
    this.employeePaginator.pageSizeOptions = [10, 20, 30, 40];
    this.employeePaginator.pageSizeDefault = this.inzoPaginatorFirstPage.pageSize;
    this.employeePaginator.currentPageDefault = this.inzoPaginatorFirstPage.currentPage;

    this.employeePaginator.inzoPaginator = this.inzoPaginatorFirstPage;
  }
  //#endregion

  //#region ANGULAR METHODS
  // ##############################################################################################################
  // ## Angular Methods
  // ##############################################################################################################
  ngOnInit() {
    const userId = this.accountService.currentUser.id;

    this.hierarchyFilters.controls.hierarchy.setValue(this.defaultHierarchyFilter);

    this.setFirstPage();

    this.setDefaultFilters();

    if (
      this.showEmployeeAreaFilters &&
      (this.userRolesPermissions.isAdmin || this.userRolesPermissions.isRRHH || this.userRolesPermissions.isRA)
    ) {
      this.setAreasFilters();

      if (this.userRolesPermissions.isRA) {
        this.subscriptions.push(
          this.areaService.getAreasIManage().subscribe(response => {
            this.myAreas = response;

            if (!this.userRolesPermissions.isAdmin && !this.userRolesPermissions.isRRHH) {
              this.areas = response;
            }

            this.areafilters.get('area').setValue(this.defaultAreaFiltersRA);

            if (this.userRolesPermissions.isAdmin || this.userRolesPermissions.isRRHH) {
              if (this.defaultAreaFiltersRRHH.includes('all')) {
                this.setAllAreasFilter();
              } else if (this.defaultAreaFiltersRRHH.includes('myAreas')) {
                this.setMyAreasFilter();
              } else {
                this.setAreasFilters();
              }
            } else {
              if (this.defaultAreaFiltersRA.includes('myAreas')) {
                this.setMyAreasFilter();
              } else {
                this.setAreasFilters();
              }
            }
          })
        );
      }

      if (this.userRolesPermissions.isAdmin || this.userRolesPermissions.isRRHH) {
        this.subscriptions.push(
          this.areaService.getAllArea().subscribe(response => {
            this.areas = response.items;

            this.areafilters.get('area').setValue(this.defaultAreaFiltersRRHH);

            if (this.defaultAreaFiltersRRHH.includes('all')) {
              this.setAllAreasFilter();
            } else if (this.defaultAreaFiltersRRHH.includes('myAreas')) {
              this.setMyAreasFilter();
            } else {
              this.setAreasFilters();
            }
          })
        );
      }
    }

    if (this.showEmployeeSituationFilters) {
      this.subscriptions.push(
        this.employeeSituationService.getAllEmployeeSituation().subscribe(response => {
          this.employeeSituations = _.filter(response.items, item => item.stateCode != EmployeeSituationCode.NONE);

          if (
            this.showEmployeeSituationFilters &&
            !this.userRolesPermissions.isAdmin &&
            !this.userRolesPermissions.isRRHH
          ) {
            this.employeeSituations = _.filter(this.employeeSituations, item => item.stateCode != EmployeeSituationCode.LEAVING);
          }

          let selectedFilter = this.employeeSituations.find(item => item.stateCode == this.defaultSituationFilter);

          this.situationFilters.get('situation').setValue(selectedFilter);

          this.filtersApiEmployee.add(
            {
              field: "situation",
              value: EmployeeSituationCode[selectedFilter.stateCode]
            },
            true
          );
        })
      );
    }

    this.subscriptions.push(
      this.debouncer.pipe(debounceTime(300)).subscribe((filter) => {
        this.customApplyFilter(filter);
      })
    );

    this.subscriptions.push(
      this.employeeSelectionService.refresh.subscribe((response) => {
        if (response == true) {
          this.refresh();
        }
      })
    );

    this.loadData();
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
  //#endregion

  //#region CUSTOM METHODS
  // ##############################################################################################################
  // ## Custom Methods
  // ##############################################################################################################
  showEmployeeAreaFiltersForm() {
    let result = false;

    if (this.showEmployeeAreaFilters) {
      result = this.userRolesPermissions.isAdmin ||
               this.userRolesPermissions.isRRHH ||
               this.userRolesPermissions.isRA;
    }

    return result;
  }

  showEmployeeSituationFiltersForm() {
    let result = false;

    if (this.showEmployeeSituationFilters) {
      result = this.userRolesPermissions.isAdmin ||
               this.userRolesPermissions.isRRHH ||
               this.userRolesPermissions.isRA;
    }

    return result;
  }

  loadData() {
    this.spinner.show();

    this.filtersApiEmployee.delete("validatorId");

    if (this.excludeEmployees.length > 0) {
      let employessIds = _.map(this.excludeEmployees, 'employeeId');

      this.filtersApiEmployee.add(
        {
          field: "ExcludeEmployees",
          value: JSON.stringify(employessIds)
        }
      );
    }

    const currentValidatorFilter = this.filtersApiEmployee;

    this.employeeManagementService.GetAllEmployeesHierarchy(this.filtersApiEmployee.getStrinFilterApi())
      .subscribe(
        (response) => {
          const {
            currentPage,
            pageSize,
            totalCount,
          } = response;

          this.employeePaginator.inzoPaginator = {
            currentPage,
            pageSize,
            totalCount
          };

          this.employeeManagementService.updateEmployeeManagements(response);
          this.employees = response.items;

          this.employeeManagementService.updateEmployeeManagements(
            this.employees
          );

          this.shownEmployeesChanged.emit(this.employees);

          const filterOr : FilterApi = this.filtersApiEmployee.search("filtersOr");

          // if (this.filtersApiEmployee && filterOr && filterOr.value === "true") {
          //  this.employeeSelectionService.onChangeSelectionEmployees(this.employees);
          // }
          if (totalCount > 0) {
            // this.employeeSelectionService.onChangeSelectionEmployees(this.employees);
            this.employeeSelectionService.onChangeFilterListApi(currentValidatorFilter);
          }

          this.dataSource = new MatTableDataSource<Employee>(this.employees);

          this.spinner.hide();
        },
        (error) => {
          this.RNService.showError(error);
        }
      );
  }

  refresh() {
    this.returnEmployee(null);

    this.setFirstPage();
    this.filter = "";
    this.debouncer.next(this.filter);
  }

  /**
  * Devuelve el objeto Employee selecionado.
  * @param employee objeto Employee selecionado
  */
  returnEmployee(employee: Employee): void {
    this.selectEmployee.emit(
      {
        employee,
        eventType: "SELECT"
      }
    );
  }
  //#endregion

  //#region PAGINATOR
  /* ################################################################################################################## */
  /* ## PAGINATOR
  /* ################################################################################################################## */
  setApiPaginator(inzoPaginator: IInzoPaginator): void {
    this.filtersApiEmployee.add(
      {
        field: "currentPage",
        value: inzoPaginator.currentPage.toString(),
      },
      true
    );

    this.filtersApiEmployee.add(
      {
        field: "pageSize",
        value: inzoPaginator.pageSize.toString(),
      },
      true
    );
  }

  getPage(event: IInzoPaginator): void {
    this.setApiPaginator(event);

    this.loadData();
  }

  setFirstPage() {
    this.setApiPaginator(this.inzoPaginatorFirstPage);
  }
  //#endregion

  //#region ASSIGNMENT AND DISPLAY METHODS
  /* ################################################################################################################## */
  /* ## ASSIGNMENT AND DISPLAY METHODS
  /* ################################################################################################################## */
  /* ****************************************************************************************************************** */
  /* ** FILTERS
  /* ****************************************************************************************************************** */
  keyFilterPressed() {
    this.debouncer.next(this.filter);
  }

  customApplyFilter(filterValue: string) {
    this.setFirstPage();

    if (filterValue === "") {
      this.filtersApiEmployee.delete("filtersOr");
    } else {
      this.filtersApiEmployee.add(
        {
          field: "filtersOr",
          value: "true",
        },
        true
      );
    }

    for (let searchCriteria of this.displayedColumns) {
      if (filterValue === "") {
        this.filtersApiEmployee.delete(searchCriteria);
      } else {
        this.filtersApiEmployee.add(
          {
            field: searchCriteria,
            value: filterValue,
          },
          true
        );
      }
    }

    this.loadData();
  }

  setDefaultFilters() {
    this.setFirstPage();

    // Filtros para búsqueda de Empleados
    this.filtersApiEmployee.add({
      field: "related",
      value: "false",
    });

    if (this.takeValidatorHierarchy) {
      this.filtersApiEmployee.add(
        {
          field: "validator",
          value: this.takeValidatorHierarchy.toString(),
        },
        true
      );
    }

    this.filtersApiEmployee.add(
      {
        field: "hierarchy",
        value: this.hierarchyFilter.toString(),
      },
      true
    );

    this.filtersApiEmployee.add({
      field: "situation",
      value: EmployeeSituationCode[this.currentSituationFilter],
    });
  }

  // --------------------------------------------------------------------------------------------------------------
  // -- Area filters
  // --------------------------------------------------------------------------------------------------------------
  loadAreaFilters(opened) {
    this.spinner.show();

    if (opened == false) {
      this.setAreasFilters();

      this.loadData();
    }
  }

  setAreasFilters() {
    this.filtersApiEmployee.delete("areaIds");

    this.setFirstPage();

    let values = _.filter(this.areafilters.controls.area.value, item => item != 'all' && item != 'myAreas');

    if (values && values.length > 0) {
      this.filtersApiEmployee.add(
        {
          field: "areaIds",
          value: JSON.stringify(values)
        },
        true
      );
    }
  }

  setAllAreasFilter() {
    let values = [];

    values.push('all');

    this.areafilters.controls.area.patchValue(values);
    this.setAreasFilters();
  }

  setMyAreasFilter() {
    let values = _.map(this.myAreas, 'areaId');

    values.push('myAreas');

    this.areafilters.controls.area.patchValue(values);
    this.setAreasFilters();
  }

  addAreasFilter() {
    if (this.areafilters.controls.area.value.includes('all')) {
      this.allAreaSelected.deselect();
    }

    if (this.areafilters.controls.area.value.includes('myAreas')) {
      this.myAreaSelected.deselect();
    }
  }

  // --------------------------------------------------------------------------------------------------------------
  // -- Hierarchy filters
  // --------------------------------------------------------------------------------------------------------------
  loadHierarchyFilters(value) {
    console.log('value :>> ', value);
    this.filtersApiEmployee.delete("onlyHierarchy");
    this.filtersApiEmployee.delete("hierarchy");

    switch(value) {
      case EmployeeHierarchyCode.HIERARCHY:
        this.filtersApiEmployee.add(
          {
            field: "onlyHierarchy",
            value: "true",
          },
          true
        );
      case EmployeeHierarchyCode.ALL:
        this.filtersApiEmployee.add(
          {
            field: "hierarchy",
            value: "true",
          },
          true
        );
        break;
      case EmployeeHierarchyCode.DIRECT:
      default:
        break;
    }

    this.loadData();
  }

  getEmployeeHierarchyFilter(item) {
    let result = "";

    if (item != undefined && item != null) {
      this.translate.get(`EMPLOYEE_FILTERS.HIERARCHY.FORM.LABEL.${item}`).subscribe((a: string) => {
        result = a;
      });
    }

    return result;
  }

  // --------------------------------------------------------------------------------------------------------------
  // -- Situation Filters
  // --------------------------------------------------------------------------------------------------------------
  loadSituationFilters(value) {
    this.spinner.show();

    this.setSituationFilter();

    this.loadData();
  }

  setSituationFilter() {
    let value = this.situationFilters.controls.situation.value;

    this.setFirstPage();

    if (value == "all") {
      this.filtersApiEmployee.delete("situation");
    } else {
      this.filtersApiEmployee.add(
        {
          field: "situation",
          value: (value.employeeSituationId)
            ? value.employeeSituationId
            : EmployeeSituationCode[value.stateCode]
        },
        true
      );
    }
  }

  getEmployeeSituationFilter(item: EmployeeSituation) {
    let result = "";

    if (item != undefined && item != null) {
      this.translate.get(`EMPLOYEE_FILTERS.SITUATION.FORM.LABEL.${EmployeeSituationCode[item.stateCode]}`).subscribe((a: string) => {
        result = a;
      });
    }

    return result;
  }

  showAllEmployeesToogled(toogleChange: MatSlideToggleChange) {
    this.hierarchyFilter = toogleChange.checked;
    const getAllRequests: FilterApi =
    {
      field: "getAll",
      value: "false"
    };

    this.setFirstPage();

    if (!this.hierarchyFilter) {
      this.filtersApiEmployee.delete("validatorId");
      getAllRequests.value = "false";
    } else {
      this.filtersApiEmployee.add(
        {
          field: "validatorId",
          value: this.employeeAssociatedToUserLoggedId,
        },
        true
      );
      getAllRequests.value = "true";
    }

    this.filtersApiEmployee.add(
      {
        field: "hierarchy",
        value: this.hierarchyFilter.toString(),
      },
      true
    );

    const currentValidatorFilter = new FiltersApi();
    currentValidatorFilter.add(getAllRequests);

    if (this.filtersApiEmployee.search("situation")) {
      currentValidatorFilter.add(this.filtersApiEmployee.search("situation"));
    }

    if (this.filtersApiEmployee.search("validator")) {
      currentValidatorFilter.add(this.filtersApiEmployee.search("validator"));
    }

    if (this.filtersApiEmployee.search("hierarchy")) {
      currentValidatorFilter.add(this.filtersApiEmployee.search("hierarchy"));
    }

    this.employeeSelectionService.onChangeFilterListApi(currentValidatorFilter);

    this.loadData();
  }
  //#endregion

  //#region UTILS
  // ##############################################################################################################
  // ## Utils
  // ##############################################################################################################
  /**
   * Selecciona las propiedades del estilo adecuados al estado del item
   */
  getSyleItem(employee: Employee) {
    let style = {
      color: "lightgray",
    };

    if (
      (this.selectedEmployees && this.selectedEmployees.length == 0) ||
      this.selectedEmployees.includes(employee)
    ) {
      style = {
        color: "inherit",
      };
    }

    return style;
  }
  //#endregion
}
