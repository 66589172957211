import { Employee } from "./employee.model";
import { AHRChanges } from "./ahr-changes.model";
import { HolidayRequestState } from "./holiday-request-state.model";

export class HolidayRequest {
  holidaysRequestId       : string;
  startDate               : any;
  finishDate              : any;
  requestedDates          : string[];
  reason                  : string;
  createdDate             : string;
  updatedDate             : string;

  //Relations
  employeeId              : string;
  employee                : Employee;

  holidaysRequestStateId  : string;
  holidaysRequestState    : HolidayRequestState;
  holidaysRequestChanges  : AHRChanges[];

  visibleOnYearCalendar   : boolean;

  constructor(obj?: Partial<HolidayRequest>) {
    Object.assign(this, obj);
  }
}
