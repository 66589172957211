// ################################################################################################################
// ## IMPORTS
// ################################################################################################################
import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DataChangesService } from '../../../services/data-changes.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { AccountService, Hotkeys, Role, User } from 'inzo-portalempleado';
import { IdentificationDocumentFormValidator } from 'src/app/helpers/identificationDocumentValidator';

import { BankAccountFormValidator } from 'src/app/helpers/accountBankValidator';
import { CommonResources } from 'src/app/models/common-resources';
import { Country } from '../../../../../models/country.model';
import { customStartsWith } from 'src/app/helpers/filter-data.helper';
import { Employee } from '../../../../../models/employee.model';
import { IFormEdit, IUserRolesPermissions } from 'src/app/modules/pe-common/interfaces/permissions.interface';
import { NSSFormValidator } from 'src/app/helpers/nssValidator';
import { checkFormDate } from 'src/app/helpers/date.helper';
import { Address } from 'src/app/models/address.model';
import { DataHistoryService } from '../../../services/data-history.service';
import { Province } from 'src/app/models/province.model';
import { LocationApp } from 'src/app/models/location-app.model';
import { getCountryAlfa3, getProvinceName } from 'src/app/helpers/places.helper';
import moment = require('moment');
import { PermissionsService } from 'src/app/modules/pe-common/services/permissions.service';

import { DateMaskDirective } from 'src/app/directives/date-mask.directive';
import { DateTimeMaskDirective } from 'src/app/directives/date-time-mask.directive';
import { ConfirmDialogType } from '../../../../close-year/components/close-year-histroy-confirm-dialog/close-year-histroy-confirm-dialog.component';
import { ClassGetter } from '@angular/compiler/src/output/output_ast';
// ################################################################################################################
// ## CLASS MgntFormProfileComponent
// ################################################################################################################
const TOTAL_AVAILABLE_HOLIDAYS = 22;
const TOTAL_HOLIDAYS_WEEKEND  = 1;

// ################################################################################################################
// ## CLASS MgntFormProfileComponent
// ################################################################################################################
@Component({
  selector    : 'app-mgnt-form-profile',
  templateUrl : './mgnt-form-profile.component.html',
  styleUrls   : [
    './mgnt-form-profile.component.css',
  ],
})
export class MgntFormProfileComponent implements OnInit {
  @Input() employeeView: boolean = true;
  isNewEmployee: boolean = true;
  employee: Employee;
  homeAddress: Address;

  allCountries : Country[] = [];
  filteredCountries: Observable<Country[]>;
  filteredBirthplaceCountries: Observable<Country[]>;
  filteredHomeCountries: Observable<Country[]>;

  allProvinces : Province[] = [];
  filteredBirthplaceProvinces: Observable<Province[]>;
  filteredHomeProvinces: Observable<Province[]>;

  allLocations : LocationApp[];
  filteredLocations: Observable<LocationApp[]>;

  disabilityPercentages = CommonResources.DISABILITY_PERCENTAGES;
  identiDocumTypes = CommonResources.IDENTIFICATION_DOCUMENT_TYPES;
  genders = CommonResources.GENDERS;

  backForm = true;

  subscriptions: Subscription[] = [];

  // --------------------------------------------------------------------------------------------------------------
  // Permisos
  // --------------------------------------------------------------------------------------------------------------
  // allUsers: User[];
  // roles: Role[];

  userRolesPermissions: IUserRolesPermissions = {
    isAdmin     : false,
    isRRHH      : false,
    isRA        : false,
    isRP        : false,
    isEmployee  : false,
  }
  // --------------------------------------------------------------------------------------------------------------

  disabilityChecked;
  enabled = true;
  detail = false;
  deleted = false;
  permissionsLoaded = false;
  visible = true;
  userFilter = '';
  selectable = true;
  removable = true;
  createMode: boolean = false;
  editable: boolean = false;

  //#region FORMS
  /* ****************************************************************************************************************** */
  /* ** FORMS
  /* ****************************************************************************************************************** */
  RAEdit: IFormEdit = {
    ProfileFormGroup      : [
      "companyEmail",
      "companyPhone",
    ],
    HomeAddressFormGroup  : [],
  }

  RPEdit: IFormEdit = {
    ProfileFormGroup      : [
      "companyEmail",
      "companyPhone",
    ],
    HomeAddressFormGroup  : [],
  }
  // ------------------------------------------------------------------------------------------------------------------
  // ProfileFormGroup
  // ------------------------------------------------------------------------------------------------------------------
  ProfileFormGroup = new FormGroup({
    name                    : new FormControl('', Validators.required),
    firstSurname            : new FormControl('', Validators.required),
    secondSurname           : new FormControl(''),
    documentType            : new FormControl('', Validators.required),
    documentNumber          : new FormControl('', Validators.required),
    documentExpirationDate  : new FormControl(''),
    gender                  : new FormControl('', Validators.required),
    bankAccount             : new FormControl(''),
    nss                     : new FormControl(''),
    country                 : new FormControl('', Validators.required),
    birthdate               : new FormControl('', Validators.required),
    birthplace              : new FormControl(''),
    birthplaceProvince      : new FormControl(''),
    disability              : new FormControl(''),
    disabilityPercentage    : new FormControl(''),
    totalAvailableHolidays  : new FormControl(TOTAL_AVAILABLE_HOLIDAYS, [Validators.required, Validators.pattern(CommonResources.ONLY_INTEGERS_PATTERN)]),
    totalHolidaysWeekend    : new FormControl(TOTAL_HOLIDAYS_WEEKEND, [Validators.required, Validators.pattern(CommonResources.ONLY_INTEGERS_PATTERN)]),
    code                    : new FormControl('', Validators.required),

    // CONTACT DATA
    personalEmail           : new FormControl('', Validators.pattern(CommonResources.EMAILPATTERN)),
    companyEmail            : new FormControl('', Validators.pattern(CommonResources.EMAILPATTERN)),
    personalPhone           : new FormControl('', [ Validators.required, Validators.pattern(CommonResources.PHONEPATTERN) ]),
    companyPhone            : new FormControl('', Validators.pattern(CommonResources.PHONEPATTERN)),
  }, { validators: [IdentificationDocumentFormValidator(), BankAccountFormValidator(), NSSFormValidator()] });

  // ------------------------------------------------------------------------------------------------------------------
  // HomeAddressFormGroup
  // ------------------------------------------------------------------------------------------------------------------
  HomeAddressFormGroup = new FormGroup({
    country     : new FormControl('', Validators.required),
    flat        : new FormControl(''),
    location    : new FormControl('', Validators.required),
    location2   : new FormControl(''),
    number      : new FormControl('', Validators.required),
    portal      : new FormControl(''),
    postalCode  : new FormControl(''),
    province    : new FormControl('', Validators.required),
    street      : new FormControl('', Validators.required),
    typeStreet  : new FormControl(''),
  });
  //#endregion

  //#region CONSTRUCTOR
  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    protected permissionsService: PermissionsService,
    private dataChangesService: DataChangesService,
    private dataHistoryService: DataHistoryService,
    public accountService: AccountService,
    public router: Router,
    public hotkeys: Hotkeys,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
  ) {
   this.disableForm();
  }
  //#endregion

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    // Permissions
    this.subscriptions.push(
      this.permissionsService.userRolesPermissions.subscribe(data => {
        this.userRolesPermissions = data;
      })
    );

    // Permissions
    this.subscriptions.push(
      this.route.paramMap.subscribe(params => {
        this.isNewEmployee = params.get('id') === 'new';
      })
    );

    //Is a new employee?
    this.subscriptions.push(
      this.route.paramMap.subscribe(params => {
        const transId = params.get('id');

        this.isNewEmployee = transId === 'new';
      })
    );

    // Get Form Data
    this.subscriptions.push(
      this.dataChangesService.formData.subscribe(data => {
        if (data) {
          this.assignEmployeeData();
        }
      })
    );

    // employeeManagementInfo
    this.subscriptions.push(
      this.dataChangesService.employeeManagementInfo.subscribe(data => {
        this.allCountries = (data && data.allCountries)
          ? data.allCountries
          : [];

        this.allProvinces = (data && data.allProvinces)
          ? data.allProvinces
          : [];

        this.allLocations = (data && data.allLocations)
          ? data.allLocations
          : [];

        if (this.allProvinces.length > 0) {
          let others = this.allProvinces.shift();

          this.allProvinces.sort((a, b) => {
            let result = 0;
            if (a.name > b.name) {
              result = 1;
            }
            if (a.name < b.name) {
              result = -1;
            }

            return result;
          });

          this.allProvinces.unshift(others);
        }

        if (this.allLocations.length > 0) {
          let others = this.allLocations.shift();

          this.allLocations.sort((a, b) => {
            let result = 0;
            if (a.name > b.name) {
              result = 1;
            }
            if (a.name < b.name) {
              result = -1;
            }

            return result;
          });

          this.allLocations.unshift(others);
        }

        if (data && data.employee) {
          this.employee = data.employee;
          this.homeAddress = data.employee.homeAddress;
        }

        this.loadDataOnForm();
      })
    );

    // Employee
    this.subscriptions.push(
      this.dataChangesService.employee.subscribe(data => {
        this.employee = data;
      })
    );

    // HomeAddress
    this.subscriptions.push(
      this.dataChangesService.homeAddress.subscribe(data => {
        this.homeAddress = data;
      })
    );

    // CreateMode
    this.subscriptions.push(
      this.dataChangesService.createMode.subscribe(data => {
        this.createMode = data;
      })
    );

    // EditMode
    this.subscriptions.push(
      this.dataChangesService.editMode.subscribe(data => {
        this.editable = data;

        if (data === true) {
          this.edit();
        } else {
          this.cancel();
        }
      })
    );

    // ResetFormDirty
    this.subscriptions.push(
      this.dataChangesService.resetFormDirty.subscribe(data => {
        if (data) {
          this.resetFormDirty();
        }
      })
    );
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }

    this.cleanForm();
  }
  //#endregion

  //#region ASSIGNMENT AND DISPLAY METHODS
  /* ################################################################################################################## */
  /* ## ASSIGNMENT AND DISPLAY METHODS
  /* ################################################################################################################## */
  /* ****************************************************************************************************************** */
  /* ** COUNTRY
  /* ****************************************************************************************************************** */
  assignCountry(e) {
    this.ProfileFormGroup.get('country').setValue(e.option.value);
  }

  assignBirthplaceCountry(e) {
    if (e == null) {
      this.ProfileFormGroup.get('birthplace').setValue("");
      this.ProfileFormGroup.get('birthplaceProvince').setValue("");
    } else {
      const birthplaceCountry = e.option.value;

      this.ProfileFormGroup.get('birthplace').setValue(birthplaceCountry);

      // Selecciona la provincia OTROS
      this.ProfileFormGroup.get('birthplaceProvince').setValue(this.allProvinces[0]);

      if (birthplaceCountry.alfa3 === "ESP") {
        // Resetea el filtro de provincias
        this.addFiltersHomeBirthplaceProvinces();
      } else {
        // Restringe el filtro de provincias
        this.filteredBirthplaceProvinces = this.ProfileFormGroup.controls['birthplaceProvince'].valueChanges.pipe(startWith(""), map(val =>
          []
        ));
      }
    }
  }

  assignHomeCountry(e) {
    if (e == null) {
      this.HomeAddressFormGroup.get('country').setValue("");
      this.HomeAddressFormGroup.get('location').setValue("");
      this.HomeAddressFormGroup.get('province').setValue("");

      this.addFiltersHomeProvinces();
      this.addFiltersHomeLocations();
    } else {
      const homeCountry = e.option.value;

      this.HomeAddressFormGroup.get('country').setValue(homeCountry);
      this.HomeAddressFormGroup.get('province').setValue("");
      this.HomeAddressFormGroup.get('location').setValue("");

      if (homeCountry.alfa3 === "ESP") {
        // Resetea el filtro de provincias
        this.addFiltersHomeProvinces();

        // Resetea el filtro de municipios
        this.addFiltersHomeLocations();
      } else {        // Selecciona la provincia OTROS
        this.HomeAddressFormGroup.get('province').setValue(this.allProvinces[0]);

        // Restringe el filtro de provincias
        this.filteredHomeProvinces = this.HomeAddressFormGroup.controls['province'].valueChanges.pipe(startWith(""), map(val =>
          []
        ));
        // Selecciona el municipio OTROS
        this.HomeAddressFormGroup.get('location').setValue(this.allLocations[0]);

        // Restringe el filtro de municipios
        this.filteredLocations = this.HomeAddressFormGroup.controls['location'].valueChanges.pipe(startWith(""), map(val =>
          []
        ));
      }
    }
  }

  displayCountry(country: Country): string | undefined {
    return (country)
      ? country.name
      : undefined;
  }

  /* ****************************************************************************************************************** */
  /* ** PROVINCE
  /* ****************************************************************************************************************** */
  assignBirthplaceProvince(e) {
    this.ProfileFormGroup.get('birthplaceProvince').setValue("");

    if (e != null) {
      const birthplaceProvince = e.option.value;

      this.ProfileFormGroup.get('birthplaceProvince').setValue(birthplaceProvince);

      if (birthplaceProvince.name !== "Otros") {
        // Selecciona el país ESPAÑA
        this.ProfileFormGroup.get('birthplace').setValue(this.allCountries.find((item) =>
          item.alfa3 == "ESP"
        ));
      }
    }
  }

  assignHomeProvince(e) {
    if (e == null) {
      this.HomeAddressFormGroup.get('location').setValue("");
      this.HomeAddressFormGroup.get('province').setValue("");

      // this.HomeAddressFormGroup.get('location2').setValue("");
      // this.HomeAddressFormGroup.get('postalCode').setValue("");

      this.filteredLocations = this.HomeAddressFormGroup.controls['location'].valueChanges.pipe(startWith(""), map(val =>
        this.allLocations.filter(location =>
          customStartsWith(location.name, val)
        ))
      );
    } else {
      const homeProvince = e.option.value;

      this.HomeAddressFormGroup.get('province').setValue(homeProvince);
      this.HomeAddressFormGroup.get('location').setValue("");

      // this.HomeAddressFormGroup.get('location2').setValue("");
      // this.HomeAddressFormGroup.get('postalCode').setValue("");

      if (homeProvince.name === "Otros") {
        // Selecciona el municipio OTROS
        this.HomeAddressFormGroup.get('location').setValue(this.allLocations[0]);

        // Restringe el filtro de municipios
        this.filteredLocations = this.HomeAddressFormGroup.controls['location'].valueChanges.pipe(startWith(""), map(val =>
          []
        ));
      } else {
        // Restringe el filtro de municipios a los de la provincia seleccionada
        this.filteredLocations = this.HomeAddressFormGroup.controls['location'].valueChanges.pipe(startWith(""), map(val =>
          this.allLocations.filter(location =>
            location.provinceId == homeProvince.provinceId
          ))
        );
      }
    }
  }

  displayProvince(province: Province): string | undefined {
    return (province)
      ? province.name
      : undefined;
  }

  /* ****************************************************************************************************************** */
  /* ** LOCATION
  /* ****************************************************************************************************************** */
  assignHomeLocation(e) {
    if (e == null) {
      this.HomeAddressFormGroup.get('province').setValue("");
      this.HomeAddressFormGroup.get('location').setValue("");

      // this.HomeAddressFormGroup.get('location2').setValue("");
      // this.HomeAddressFormGroup.get('postalCode').setValue("");
    } else {
      const homeLocation = e.option.value;

      this.HomeAddressFormGroup.get('location').setValue(homeLocation);

      // this.HomeAddressFormGroup.get('location2').setValue("");
      // this.HomeAddressFormGroup.get('postalCode').setValue("");

      if (homeLocation.name === "Otros") {
        // Selecciona la provincia OTROS
        this.HomeAddressFormGroup.get('province').setValue(this.allProvinces[0]);
      } else {
        // Selecciona la provincia del municipio seleccionado
        this.HomeAddressFormGroup.get('province').setValue(this.allProvinces.find((province) =>
          province.provinceId == homeLocation.provinceId
        ));
      }
    }
  }

  displayLocation(location: LocationApp): string | undefined {
    return (location)
      ? location.name
      : undefined;
  }

  /* ****************************************************************************************************************** */
  /* ** EMPLOYEE
  /* ****************************************************************************************************************** */
  assignEmployeeData() {
    if (this.employee == undefined || this.employee == null) {
      this.employee = new Employee();
    }

    // ProfileFormGroup
    this.employee.name = this.ProfileFormGroup.get('name').value;
    this.employee.firstSurname = this.ProfileFormGroup.get('firstSurname').value;
    this.employee.secondSurname = this.ProfileFormGroup.get('secondSurname').value;
    this.employee.gender = this.ProfileFormGroup.get('gender').value;

    this.employee.documentType = this.ProfileFormGroup.get('documentType').value;
    this.employee.documentNumber = this.ProfileFormGroup.get('documentNumber').value;
    this.employee.documentExpirationDate = this.ProfileFormGroup.get('documentExpirationDate').value;
    this.employee.countryId = (this.ProfileFormGroup.get('country').value && this.ProfileFormGroup.get('country').value.countryId)
      ? this.ProfileFormGroup.get('country').value.countryId
      : null;

    this.employee.birthdate = this.ProfileFormGroup.get('birthdate').value;
    this.employee.birthplace = (this.ProfileFormGroup.get('birthplace').value instanceof String)
      ? this.ProfileFormGroup.get('birthplace').value
      : getCountryAlfa3(this.ProfileFormGroup.get('birthplace').value);

    this.employee.birthplaceProvince = (this.ProfileFormGroup.get('birthplaceProvince').value instanceof String)
      ? this.ProfileFormGroup.get('birthplaceProvince').value
      : getProvinceName(this.ProfileFormGroup.get('birthplaceProvince').value);

    this.employee.disability = this.ProfileFormGroup.get('disability').value || false;
    this.employee.disabilityPercentage = this.ProfileFormGroup.get('disabilityPercentage').value || 0;

    // Holidays data
    this.employee.totalAvailableHolidays = this.ProfileFormGroup.get('totalAvailableHolidays').value;

    // HolidayWeekend
    this.employee.totalHolidayWeekend = this.ProfileFormGroup.get('totalHolidaysWeekend').value;

    this.employee.bankAccount = (this.ProfileFormGroup.get('bankAccount').value)
      ? this.ProfileFormGroup.get('bankAccount').value
      : null;
    this.employee.nss = (this.ProfileFormGroup.get('nss').value)
      ? this.ProfileFormGroup.get('nss').value
      : null;

    this.employee.code = this.ProfileFormGroup.get('code').value;

    // ContactsFormGroup
    this.employee.personalEmail = this.ProfileFormGroup.get('personalEmail').value;
    this.employee.companyEmail = this.ProfileFormGroup.get('companyEmail').value;
    this.employee.personalPhone = this.ProfileFormGroup.get('personalPhone').value;
    this.employee.companyPhone = this.ProfileFormGroup.get('companyPhone').value;

    // Se notifica a los observadores de los cambios
    this.dataChangesService.setEmployeeChange(this.employee);
  }


  /* ****************************************************************************************************************** */
  /* ** HOMEADDRESS
  /* ****************************************************************************************************************** */
  assignHomeAddress() {
    if (this.homeAddress == undefined || this.homeAddress == null) {
      this.homeAddress = new Address();
    }

    this.homeAddress.country = this.HomeAddressFormGroup.get('country').value;
    this.homeAddress.flat = this.HomeAddressFormGroup.get('flat').value;
    this.homeAddress.location2 = this.HomeAddressFormGroup.get('location2').value;
    this.homeAddress.number = this.HomeAddressFormGroup.get('number').value;
    this.homeAddress.portal = this.HomeAddressFormGroup.get('portal').value;
    this.homeAddress.postalCode = this.HomeAddressFormGroup.get('postalCode').value;
    this.homeAddress.province = this.HomeAddressFormGroup.get('province').value;
    this.homeAddress.street = this.HomeAddressFormGroup.get('street').value;
    this.homeAddress.location = this.HomeAddressFormGroup.get('location').value;
    this.homeAddress.typeStreet = this.HomeAddressFormGroup.get('typeStreet').value;

    // Se notifica a los observadores de los cambios
    this.dataChangesService.setHomeAddressChange(this.homeAddress);
  }
  //#endregion

  //#region CUSTOM METHODS
  /* ################################################################################################################## */
  /* ## CUSTOM METHODS
  /* ################################################################################################################## */
  //#region FORMS
  /* ****************************************************************************************************************** */
  /* ** FORMS
  /* ****************************************************************************************************************** */
  loadDataOnForm() {
    if (this.employee) {
      var countrySelected = new Country;
      let birthplaceSelected: Country = null;
      let birthplaceProvinceSelected: Province = null;

      //#region Inicializar datos
      // ----------------------------------------------------------------------------------------------------------------
      // Inicializar datos
      // ----------------------------------------------------------------------------------------------------------------
      countrySelected = (this.employee.country)
        ? this.employee.country
        : this.allCountries.find(country => country.countryId === this.employee.countryId);

      if (this.employee.birthplace) {
        birthplaceSelected = this.allCountries.find(item => item.alfa3 == this.employee.birthplace);

        if (birthplaceSelected == undefined || birthplaceSelected == null) {
          birthplaceSelected = new Country();
          birthplaceSelected.name = this.employee.birthplace;
          birthplaceSelected.alfa3 = this.employee.birthplace;

          this.allCountries.push(birthplaceSelected);
        }
      }

      if (this.employee.birthplaceProvince) {
        birthplaceProvinceSelected = this.allProvinces.find(item => item.name == this.employee.birthplaceProvince);

        if (birthplaceProvinceSelected == undefined || birthplaceProvinceSelected == null) {
          birthplaceProvinceSelected = new Province();
          birthplaceProvinceSelected.name = this.employee.birthplaceProvince;

          this.allProvinces.push(birthplaceProvinceSelected);
        }
      }
      //#endregion
      
      // ------------------------------------------------------------------------------------------------------------------
      // ProfileFormGroup
      // ------------------------------------------------------------------------------------------------------------------
      this.ProfileFormGroup.setValue({
        code                    : (this.employee.code)
          ? this.employee.code
          : null,
        name                    : (this.employee.name)
          ? this.employee.name
          : null,
        firstSurname            : (this.employee.firstSurname)
          ? this.employee.firstSurname
          : null,
        secondSurname           : (this.employee.secondSurname)
          ? this.employee.secondSurname
          : null,
        documentNumber          : (this.employee.documentNumber)
          ? this.employee.documentNumber
          : null,
        documentType            : (this.employee.documentType)
          ? this.employee.documentType
          : null,
        documentExpirationDate  : (this.employee.documentExpirationDate)
          ? this.employee.documentExpirationDate
          : null,
        gender                  : (this.employee.gender)
          ? this.employee.gender
          : null,
        nss                     : (this.employee.nss)
          ? this.employee.nss
          : null,
        country                 : (countrySelected)
          ? countrySelected
          : null,
        birthdate               : (this.employee.birthdate)
          ? this.employee.birthdate
          : null,
        birthplace              : (this.employee.birthplace && birthplaceSelected)
          ? birthplaceSelected
          : null,
        birthplaceProvince      : (this.employee.birthplaceProvince && birthplaceProvinceSelected)
          ? birthplaceProvinceSelected
          : null,
        disability              : (this.employee.disability)
          ? this.employee.disability
          : null,
        disabilityPercentage    : (this.employee.disabilityPercentage)
          ? this.employee.disabilityPercentage
          : null,
        bankAccount             : (this.employee.bankAccount)
          ? this.employee.bankAccount
          : null,
        totalAvailableHolidays  : (this.isNewEmployee)
          ? TOTAL_AVAILABLE_HOLIDAYS 
          : (this.employee.totalAvailableHolidays)
            ? this.employee.totalAvailableHolidays
            : 0,
        totalHolidaysWeekend    : (this.isNewEmployee)
          ? TOTAL_HOLIDAYS_WEEKEND
          : (this.employee.totalHolidayWeekend)
            ? this.employee.totalHolidayWeekend
            : 0,

        //contact data
        personalEmail           : (this.employee.personalEmail)
          ? this.employee.personalEmail
          : null,
        companyEmail            : (this.employee.companyEmail)
          ? this.employee.companyEmail
          : null,
        personalPhone           : (this.employee.personalPhone)
          ? this.employee.personalPhone
          : null,
        companyPhone            : (this.employee.companyPhone)
          ? this.employee.companyPhone
          : null,
      });

      this.disabilityChecked = this.employee.disability;

      // ------------------------------------------------------------------------------------------------------------------
      // HomeAddressFormGroup
      // ------------------------------------------------------------------------------------------------------------------
      if (this.homeAddress) {
        this.HomeAddressFormGroup.setValue({
          country     : (this.homeAddress.country)
            ? this.homeAddress.country
            : null,
          flat        : (this.homeAddress.flat)
            ? this.homeAddress.flat
            : null,
          location        : (this.homeAddress.location)
            ? this.homeAddress.location
            : null,
          location2    : (this.homeAddress.location2)
            ? this.homeAddress.location2
            : null,
          number      : (this.homeAddress.number)
            ? this.homeAddress.number
            : null,
          portal      : (this.homeAddress.portal)
            ? this.homeAddress.portal
            : null,
          postalCode  : (this.homeAddress.postalCode)
            ? this.homeAddress.postalCode
            : null,
          province    : (this.homeAddress.province)
            ? this.homeAddress.province
            : null,
          street      : (this.homeAddress.street)
            ? this.homeAddress.street
            : null,
          typeStreet  : (this.homeAddress.typeStreet)
            ? this.homeAddress.typeStreet
            : null,
        });
      } else {
        this.cleanHomeAddressForm();
      }
    }

    // ------------------------------------------------------------------------------------------------------------------
    // Filtros
    // ------------------------------------------------------------------------------------------------------------------
    this.addFilters();

    // ------------------------------------------------------------------------------------------------------------------
    // Informar cambios
    // ------------------------------------------------------------------------------------------------------------------
    this.onChangesForms();
  }

  onChangesForms(): void {
    // ------------------------------------------------------------------------------------------------------------------
    // ProfileFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    this.ProfileFormGroup.valueChanges.subscribe(val => {
      this.assignEmployeeData();
    });

    // ------------------------------------------------------------------------------------------------------------------
    // HomeAddressFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    this.HomeAddressFormGroup.valueChanges.subscribe(val => {
      this.assignHomeAddress();
    });
  }

  cleanForm() {
    this.cleanProfileForm();
    this.cleanHomeAddressForm();
  }

  cleanProfileForm() {
    // ------------------------------------------------------------------------------------------------------------------
    // ProfileFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    Object.keys(this.ProfileFormGroup.controls).forEach((name) => {
      const currentControl = this.ProfileFormGroup.controls[name];

      currentControl.setValue('');
    });
  }

  cleanHomeAddressForm() {
    // ------------------------------------------------------------------------------------------------------------------
    // HomeAddressFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    Object.keys(this.HomeAddressFormGroup.controls).forEach((name) => {
      const currentControl = this.HomeAddressFormGroup.controls[name];

      currentControl.setValue('');
    });
  }

  disableForm() {
    // ------------------------------------------------------------------------------------------------------------------
    // ProfileFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    Object.keys(this.ProfileFormGroup.controls).forEach((name) => {
      const currentControl = this.ProfileFormGroup.controls[name];

      currentControl.disable();
    });

    // ------------------------------------------------------------------------------------------------------------------
    // HomeAddressFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    Object.keys(this.HomeAddressFormGroup.controls).forEach((name) => {
      const currentControl = this.HomeAddressFormGroup.controls[name];

      currentControl.disable();
    });
  }

  enableForm() {
    // ------------------------------------------------------------------------------------------------------------------
    // ProfileFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    Object.keys(this.ProfileFormGroup.controls).forEach((name) => {
      const currentControl = this.ProfileFormGroup.controls[name];
      let continueEnable: boolean = false;

      if (this.userRolesPermissions.isRRHH) {
        continueEnable = true;
      } else if (this.userRolesPermissions.isRA && this.RAEdit.ProfileFormGroup.includes(name)) {
        continueEnable = true;
      } else if (this.userRolesPermissions.isRP && this.RPEdit.ProfileFormGroup.includes(name)) {
        continueEnable = true;
      }

      if (continueEnable) {
        if (name != 'disabilityPercentage') {
          currentControl.enable();
        } else if (this.ProfileFormGroup.get('disability').value == true) {
          currentControl.enable();
        }
      }
    });

    // ------------------------------------------------------------------------------------------------------------------
    // HomeAddressFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    Object.keys(this.HomeAddressFormGroup.controls).forEach((name) => {
      const currentControl = this.HomeAddressFormGroup.controls[name];
      let continueEnable: boolean = false;

      if (this.userRolesPermissions.isRRHH) {
        continueEnable = true;
      } else if (this.userRolesPermissions.isRA && this.RAEdit.HomeAddressFormGroup.includes(name)) {
        continueEnable = true;
      } else if (this.userRolesPermissions.isRP && this.RPEdit.HomeAddressFormGroup.includes(name)) {
        continueEnable = true;
      }

      if (continueEnable) {
        currentControl.enable();
      }
    });
  }

  edit() {
    this.enabled = true;
    this.enableForm();
  }

  cancel() {
    this.enabled = false;
    this.disableForm();
    this.loadDataOnForm();
  }

  resetFormDirty() {
    // Quita las marcas "dirty" de los inputs
    this.ProfileFormGroup.markAsPristine();
    this.HomeAddressFormGroup.markAsPristine();
  }

  resetForm() {
    this.loadDataOnForm();

    // Quita las marcas "dirty" de los inputs
    this.resetFormDirty();
  }
  //#endregion

  //#region UTILS
  /* ################################################################################################################## */
  /* ## UTILS
  /* ################################################################################################################## */
  addFilters() {
    // ------------------------------------------------------------------------------------------------------------------
    // COUNTRIES
    // ------------------------------------------------------------------------------------------------------------------
    if (this.allCountries) {
      // ProfileFormGroup
      this.addFilterCountries();
      this.addFiltersBirthplaceCountries();

      // HomeAddressFormGroup
      this.addFiltersHomeCountries();
    }

    // ------------------------------------------------------------------------------------------------------------------
    // PROVINCES
    // ------------------------------------------------------------------------------------------------------------------
    if (this.allProvinces) {
      // ProfileFormGroup
      this.addFiltersHomeBirthplaceProvinces();

      // HomeAddressFormGroup
      this.addFiltersHomeProvinces();
    }

    // ------------------------------------------------------------------------------------------------------------------
    // LOCATIONS
    // ------------------------------------------------------------------------------------------------------------------
    this.addFiltersHomeLocations();
  }

  addFilterCountries() {
    this.filteredCountries = this.ProfileFormGroup.controls['country'].valueChanges.pipe(startWith(""), map(val =>
      this.allCountries.filter(country =>
        customStartsWith(country.name, val)
      ))
    );
  }

  addFiltersBirthplaceCountries() {
    this.filteredBirthplaceCountries = this.ProfileFormGroup.controls['birthplace'].valueChanges.pipe(startWith(""), map(val =>
      this.allCountries.filter(country =>
        customStartsWith(country.name, val)
      ))
    );
  }

  addFiltersHomeCountries() {
    this.filteredHomeCountries = this.HomeAddressFormGroup.controls['country'].valueChanges.pipe(startWith(""), map(val =>
      this.allCountries.filter(country =>
        customStartsWith(country.name, val)
      ))
    );
  }

  addFiltersHomeBirthplaceProvinces() {
    this.filteredBirthplaceProvinces = this.ProfileFormGroup.controls['birthplaceProvince'].valueChanges.pipe(startWith(""), map(val =>
      this.allProvinces.filter(province =>
        customStartsWith(province.name, val)
      ))
    );
  }

  addFiltersHomeProvinces() {
    this.filteredHomeProvinces = this.HomeAddressFormGroup.controls['province'].valueChanges.pipe(startWith(""), map(val =>
      this.allProvinces.filter(province =>
        customStartsWith(province.name, val)
      ))
    );
  }

  addFiltersHomeLocations() {
    if (this.allLocations) {
      // HomeAddressFormGroup
      this.filteredLocations = this.HomeAddressFormGroup.controls['location'].valueChanges.pipe(startWith(""), map(val =>
        this.allLocations.filter(location =>
          customStartsWith(location.name, val)
        ))
      );
    }
  }

  setDate(event: any, fieldName: string): void {
    const date = moment(event.target.value, 'DD/MM/YYYY');
    this.ProfileFormGroup.get(fieldName).setValue(date);
  }
  //#endregion
}
