import { Component, OnInit, ElementRef, ViewChild, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AbsenceGroupsItems, AbsenceStateLabel } from 'src/app/interfaces/absence.interface';
import { AbsenceEvent } from 'src/app/interfaces/events.interface';
import { IUserRolesPermissions } from 'src/app/modules/pe-common/interfaces/permissions.interface';
import { AbsenceStateCode } from 'src/app/models/absence-state-code-enum.model';
import { AbsenceState } from 'src/app/models/absence-state.model';
import { FiltersApi } from 'src/app/models/filters-api.model';
import { AbsenceChangesService } from 'src/app/services/absence-changes.service';
import { PermissionsService } from 'src/app/modules/pe-common/services/permissions.service';
import { AbsenceManagementCalendarComponent } from '../../absence-management/absence-management-calendar/absence-management-calendar.component';

@Component({
  selector: 'app-absence-tabs',
  templateUrl: './absence-tabs.component.html',
  styleUrls: ['./absence-tabs.component.css']
})
export class AbsenceTabsComponent implements OnInit {
  @Input() absencesGroups: AbsenceGroupsItems[] = [];
  @Input() absenceStates: AbsenceState[] = [];

  @Input() visibleStatesCalendar: AbsenceStateCode[] = [];

  @Input() filtersApi: FiltersApi = new FiltersApi();

  @Input() absenceStatesTabs: AbsenceStateLabel[]= [];

  searching = true;
  filter = '';

  @ViewChild('inputsearch', { static: false }) inputSearch: ElementRef;
  @Input() calendar: AbsenceManagementCalendarComponent;
  @Output() actionAbsence: EventEmitter<AbsenceEvent> = new EventEmitter();
  @Output() refreshData: EventEmitter<boolean> = new EventEmitter();

  // Permissions

  employeeView : boolean = false;
  canDirectAssignment: boolean = false;
  subscriptions: Subscription[] = [];

  constructor(
    private translate: TranslateService,
    protected absenceChangesService: AbsenceChangesService,
    protected permissionsService: PermissionsService,
  ) { }

  ngOnInit() {
    this.subscriptions.push(
      this.absenceChangesService.canDirectAssignment.subscribe(checked => {
        this.canDirectAssignment = checked;
      })
    );

    // employeeView
    this.subscriptions.push(
      this.permissionsService.employeeView.subscribe(data => {
        this.employeeView = data;
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  refresh() {
    this.refreshData.emit(true);
  }

  /**
   * Recoge un evento del componente de listado de solicitudes y ejecuta la acción correspondiente a ese evento.
   *
   * @param absenceEvent evento recogido
   */
  actionAbsenceReply(absenceEvent: AbsenceEvent) {
    this.actionAbsence.emit(absenceEvent);
  }

  /**
   * Devuelve el nombre del estado con valor state
   * @param stateCode código de estado
   */
  getStateName(stateCode) {
    return AbsenceStateCode[stateCode];
  }

  /**
   * Devuelve el de solicitudes que se debe mostrar en las pestañas
   *
   * @param absenceName código de estado
   */
  getTabCount(absenceName: any): string {
    let absencesGroup: any = this.absencesGroups[absenceName];
    let countRequest = 0;

    for (let i = 0; i < absencesGroup.length; i++) {
      countRequest += absencesGroup[i].length;
    }

    return `${countRequest}`;
  }
}
