import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';
import { AreaManagerHistory } from '../models/area-manager-history.model';
import { Employee } from '../models/employee.model';

@Injectable({ providedIn: 'root' })
export class AreaManagerHistoryService {
  private changeSubject: Subject<any> = new Subject;
  public changeObservable: Observable<any> = this.changeSubject.asObservable();
  private areaManagerHistorySubject: ReplaySubject<AreaManagerHistory[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public areaManagerHistoryObservable: Observable<AreaManagerHistory[]> = this.areaManagerHistorySubject.asObservable();

  private areaManagerHistoryRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public areaManagerHistoryRouteObservable: Observable<string> = this.areaManagerHistoryRouteSubject.asObservable();

  get serviceUrl() {
    return Config.apiBaseUrl + '/areaManagerHistory';
  }

  constructor(private http: HttpClient) {
  }

  getAllAreaManagerHistorys(filter?: string) {
    if (filter == undefined || filter == null) {
      filter = "";
    }

    const url = `${this.serviceUrl}${filter}`;

    return this.http.get<any>(url);
  }

  getAreaManagerHistory(areaId: string) {
    const url = `${this.serviceUrl}/${areaId}`;

    return this.http.get<Employee[]>(url);
  }

  getCurrentAreaManagers(areaManagerHistoryId: string, filter?: string) {
    if (filter == undefined || filter == null) {
      filter = "";
    }

    const url = `${this.serviceUrl}/${areaManagerHistoryId}${filter}`;

    return this.http.get<AreaManagerHistory>(url);
  }

  assignAreaManager(areaManagerHistory: AreaManagerHistory) {
      const endpointUrl = `${this.serviceUrl}`;
      return this.http.post<AreaManagerHistory>(endpointUrl, areaManagerHistory);
  }

  deleteAreaManagerHistory(areaManagerHistoryId: string) {
      const endpointUrl = `${this.serviceUrl}/${areaManagerHistoryId}`;
      return this.http.delete<AreaManagerHistory>(endpointUrl);
  }

  modifyAreaManagerHistory(areaManagerHistoryId:string, areaManagerHistory: any) {
      const endpointUrl = `${this.serviceUrl}/${areaManagerHistoryId}`;
      return this.http.patch<AreaManagerHistory>(endpointUrl, areaManagerHistory);
  }

  updateAreaManagerHistorys(areaManagerHistorys: any) {
    this.areaManagerHistorySubject.next(areaManagerHistorys);
  }

  updateAreaManagerHistoryRoutes(id: string) {
    this.areaManagerHistoryRouteSubject.next(id);
  }

  notifyChanges() {
    this.changeSubject.next();
  }
}
