import { Component, OnInit, AfterViewInit } from '@angular/core';
import { EmployeeService } from 'src/app/services/employee.service';
import { AccountService, Permission, User } from 'inzo-portalempleado';
import { EmployeeManagementService } from 'src/app/services/employee-management.service';
import * as jsonpatch from 'fast-json-patch';
import { CommonResources } from 'src/app/models/common-resources';
import { NgxSpinnerService } from 'ngx-spinner';
import { MAT_DATE_FORMATS } from '@angular/material';
import { PermissionsService } from 'src/app/modules/pe-common/services/permissions.service';

@Component({
  selector: 'app-maintenance-personal-data',
  templateUrl: './personal-data.component.html',
  styleUrls: ['./../maintenance.components.css'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: CommonResources.CUSTOM_DATE_FORMAT },
  ],
})
export class PersonalDataComponent implements AfterViewInit, OnInit {
  showForm = false;
  userActual: User;
  permissionList = [
    new Permission("Employee", "Employee", "Employee Permissions", "Permiso para empleado"),
    new Permission("Manager", "Manager", "Manager Permissions", "Permiso para manager"),
    new Permission("RRHH", "RRHH", "RRHH Permissions", "Permiso para rrhh")
  ]
  constructor(
    private accountService: AccountService,
    private authService: PermissionsService,
    private spinner: NgxSpinnerService,
    private employeeManagementService: EmployeeManagementService,
    private employeeService: EmployeeService,
  ) {
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.spinner.show();
    this.employeeManagementService.getEmployeeByUserId(this.accountService.currentUser.id, false).subscribe(employee => {
      if (!employee || employee.userId != this.accountService.currentUser.id) {
        this.associate();
      }
    }, (error) => {
      console.log("Usuario no vinculado, vinculando..:");
      this.associate();
    });
  }

  ngAfterViewInit() {
  }
  //#endregion

  associate(): void {
    this.employeeService.associateUser(this.accountService.currentUser.userName, this.accountService.currentUser.id).subscribe((result) => {
      if (result) {
        this.spinner.hide();
      } else {
        this.authService.loginError();
      }
    }, (error) => {
      console.log("Ha ocurrido un error vinculando el usuario", error.error);
      this.authService.loginError();
    });
  }
}
