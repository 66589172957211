import { Component, OnInit, OnDestroy, ElementRef, ViewChild, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { ResponseNotificationService, BaseListComponent, SecurityStateManagementService, AccountService, Hotkeys, Role } from 'inzo-portalempleado';
import { AppPermissions } from 'src/app/models/app-permission.model';
import { Employee } from 'src/app/models/employee.model';
import { EmployeeManagementService } from 'src/app/services/employee-management.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { EmployeeEvent } from 'src/app/interfaces/events.interface';
import * as _ from 'lodash';
import { FiltersApi } from '../../models/filters-api.model';

@Component({
  selector: 'app-employee-select-dialog',
  templateUrl: './employee-select-dialog.component.html',
  styleUrls: ['./employee-select-dialog.component.css', '../maintenance.components.css', '../maintenance-list.component.css']
})
export class EmployeeSelectDialogComponent extends BaseListComponent<Employee> implements OnInit, OnDestroy {
  displayedColumns: string[] = ['name', 'firstSurname', 'secondSurname', 'documentType', 'documentNumber', 'companyEmail', 'companyPhone'];
  employees: Employee[] = [];
  filterEmployees: FiltersApi = new FiltersApi();

  roles: Role[];

  @ViewChild('inputsearch', { static: false }) inputSearch: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<Employee>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private employeeService: EmployeeManagementService,
    public route: ActivatedRoute,
    public router: Router,
    public SSMService: SecurityStateManagementService,
    private RNService: ResponseNotificationService,
    public accountService: AccountService,
    public hotkeys: Hotkeys,
    private spinner: NgxSpinnerService,
  ) {
    super(accountService, SSMService, router, hotkeys);
    this.viewPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.createPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.deletePermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    if (this.accountService.userHasPermission(AppPermissions.ROLE_DATA.RRHH)) {
    }

    this.onBuild = () => {
    };

    this.loadData = () => {
      // Se usa el setTimeout() para dejar tiempo a que se cargue el spinner
      // setTimeout(() => {
      //   this.spinner.show();
      //   let employessIds = _.map(this.data.employees, 'employeeId');
      //   this.filterEmployees.add(
      //     {
      //       field: "ExcludeEmployees",
      //       value: JSON.stringify(employessIds)
      //     }
      //   );

      //   this.employeeService.getAllEmployees(this.filterEmployees.getStrinFilterApi()).subscribe(employees => {
      //     // Employees que aún no están asociados
      //     this.employees = employees.Items;

      //     this.employeeService.updateEmployeeManagements(this.employees);

      //     this.spinner.hide();
      //   }, error => {
      //     this.spinner.hide();
      //     this.RNService.showError(error);
      //   });
      // });
    };

    this.onInit = () => {
      if (this.data && this.data.displayedColumns) {
        this.displayedColumns = this.data.displayedColumns;
      }

      this.loadData();

      this.employeeService.employeeManagementObservable.subscribe(employees => {
        this.employees = employees;
      });
    };
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.registerPermissionsLoadedSubscription();
  }

  ngOnDestroy() {
    if (this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  registerPermissionsLoadedSubscription() {
    if (!this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription = this.accountService.userPermissionsLoaded.subscribe(() => {
        this.accountService.userRolesLoaded.subscribe((rolesResponse) => {
          this.roles = this.accountService.roles;

          this.onInit();
        });
      });
    } else {
      this.onInit();
    }
  }
  //#endregion

  /**
   * Recoge un evento del componente de listado de Employees y ejecuta la acción correspondiente a ese evento.
   *
   * @param employeeEvent evento recogido
   */
  selectEmployee(employeeEvent: EmployeeEvent) {
    if(employeeEvent.eventType == "SELECT") {
        this.dialogRef.close(employeeEvent.employee);
    }
  }
}

