import { getDateString, isValidDate, stringISO8601ToDate } from '../helpers/date.helper';

export class ListDates {
  dates: Date[] = [];
  format: string = '';
  validDatesRequired: boolean = false;

  constructor(format = 'YYYY-MM-DD', validDatesRequired: boolean = false) {
    this.format = format;
    this.validDatesRequired = validDatesRequired;
  }

  /**
   * Añade una fecha a la colección.
   *
   * @param date fecha a añadir a la lista
   * @param duplicates indica si se permite introducir un valor duplicado
   */
  add(date: Date, duplicates: boolean = false) {
    const index = this.find(date, true);

    if (this.validDatesRequired && !isValidDate(date)) {
      return;
    }

    if (index < 0 || duplicates) {
      this.dates.push(date);
      this.sort();
    }
  }

  /**
   * Elimina una fecha de la colección.
   *
   * @param date fecha a eliminar de la lista
   * @param duplicates indica si se deben borrar los valores duplicados
   * @param exactTime determina si se tiene en cuenta la hora exacta
   */
  delete(date: Date, duplicates: boolean = false, exactTime: boolean = true) {
    let index = 0;

    do {
      index = this.find(date, exactTime);

      if (index >= 0) {
        this.dates.splice(index, 1);

        if (!duplicates) {
          break;
        }
      }

    } while (index >= 0);
  }

  /**
   * Vacía la colección de fechas
   */
  clear() {
    this.dates = [];
  }

  /**
   * Borra las fechas duplicadas de la colección. Se pueden borrar sólo las que sean exactamente
   * las mismas o las que coincidan en fecha pero no en hora.
   *
   * @param exactTime determina si se tiene en cuenta la hora exacta
   */
  deleteDuplicates(exactTime: boolean = false) {
    let result = [];

    while (this.dates.length > 0) {
      // let item = this.dates[0];
      let item = this.dates.splice(0, 1)[0];

      if (this.find(item, exactTime, result) < 0) {
        result.push(item);
      }

    }

    this.dates = result;
    this.sort();
  }

  /**
   * Busca una fecha y devuelve el indice en la colección.
   *
   * @param date fecha buscada
   * @param exactTime determina si se tiene en cuenta la hora exacta
   * @param dates colección de fechas sobre la que operar
   * @returns indice de la fecha buscada
   */
  find(date: Date, exactTime: boolean = false, dates: Date[] = this.dates): number {
    let result = -1;

    if (exactTime) {
      result = dates.findIndex(
        x => x.valueOf() === date.valueOf()
      );
    } else {
      result = dates.findIndex(
        x =>
          x.getFullYear() === date.getFullYear() &&
          x.getMonth() === date.getMonth() &&
          x.getDate() === date.getDate()
      );
    }

    return result;
  }

  /**
   * Devuelve la fecha de la colección que se encuetra en el indice pasado o null en caso de no
   * existir ninguna fecha para ese indice.
   *
   * @param index indice de fecha buscada
   * @returns fecha buscada o null
   */
  get(index: number): Date | null {
    let result = null;

    if (this.dates.length > 0 && index < this.dates.length) {
      result = this.dates[index];
    }

    return result;
  }

  /**
   * Devuelve el primer elemento de una colección de fechas o null si esta está vacía.
   *
   * @param dates colección de fechas sobre la que operar
   * @returns una fecha o null
   */
  first(dates: Date[] = this.dates): Date | null {
    let result = null;

    if (dates.length > 0) {
      result = dates[0];
    }

    return result;
  }

  /**
   * Devuelve el último elemento de una colección de fechas o null si esta está vacía.
   *
   * @param dates colección de fechas sobre la que operar
   * @returns una fecha o null
   */
  last(dates: Date[] = this.dates): Date | null {
    let result = null;

    if (this.dates.length > 0) {
      result = dates[dates.length - 1];
    }

    return result;
  }

  /**
   * Comprueba si la colección de fechas está vacía
   *
   * @param dates colección de fechas sobre la que operar
   * @returns un boolean
   */
  empty(dates: Date[] = this.dates): boolean {
    let result = true;

    if (dates.length > 0) {
      result = false;
    }

    return result;
  }

  /**
   * Devuelve el número de fechas en la colección.
   *
   * @param dates colección de fechas sobre la que operar
   * @returns número
   */
  length(dates: Date[] = this.dates): number {
    return dates.length;
  }

  /**
   * Ordena la colección de fechas de menor a mayor.
   *
   * @param dates colección de fechas sobre la que operar
   */
  sort(dates: Date[] = this.dates) {
    dates.sort((a, b) => {
      let result = 0;

      if (a > b) {
        result = 1;
      }
      if (a < b) {
        result = -1;
      }

      return result;
    });
  }

  /**
   * Devuelve una fecha de la colección en el formato especificado.
   *
   * @param index Indice de la fecha en la colección
   * @param format formato en el que se quiere sacar la fecha
   * @param dates colección de fechas sobre la que operar
   * @returns un string con una fecha en el formato especificado
   */
   getDateStringByIndex(index: number, format: string = this.format, dates: Date[] = this.dates): string {
    let result = "";

    if (
      this.dates.length > 0 &&
      index >= 0 &&
      index <= dates.length - 1
    ) {
      result = this.getDateString(dates[index], format);
    }

    return result;
  }

  /**
   * Devuelve un string de una fecha en el formato especificado.
   *
   * @param date Fecha a convertir
   * @param format formato en el que se quiere sacar la fecha
   * @returns un string con una fecha en el formato especificado
   */
   getDateString(date: Date, format: string = this.format): string {
    return getDateString(date, format);
  }

  /**
   * Copia la colección de fechas en un nuevo array.
   *
   * @param dates colección de fechas a clonar
   * @returns una nueva colección de fechas
   */
  getCloneDates(dates: Date[] = this.dates): Date[] {
    let result: Date[] = [];

    dates.forEach((item) => {
      result.push(new Date(item));
    });

    return result;
  }

  /**
   * Establece la colección de fechas pasadas como el valor del atributo "this.dates".
   *
   * @param dates colección de fechas a clonar
   * @returns una nueva colección de fechas
   */
  setDates(dates: Date[] | string[]): void {
    let result: Date[] = [];

    try {
      dates.forEach((item) => {
        if (this.validDatesRequired && !isValidDate(item)) {
          return;
        }

        if (!(item instanceof Date)) {
          let tempItem = stringISO8601ToDate(item, true);
          result.push(tempItem);
        } else {
          result.push(item);
        }
      });
    } catch (e) {
      console.error('e :>> ', e);
    }

    this.dates = result;
  }

  /**
   * Copia la colección de fechas en un nuevo array de strings en formato ISO.
   *
   * @param dates colección de fechas a clonar
   * @param format formato en el que se quiere sacar la fecha
   * @returns una nueva colección de fechas
   */
  toDatesStrings(dates: Date[] = this.dates, format: string = this.format): string[] {
    let result: string[] = [];

    dates.forEach((item) => {
      result.push(getDateString(item, format));
    });

    return result;
  }

  /**
   * Devuelve todas las fechas de una colección que se encuentran entre los parámetros establecidos.
   *
   * @param startDate fecha inicial desde la que buscar
   * @param finishDate fecha final hasta la que buscar
   * @param dates colección de fechas sobre las que buscar
   * @returns colección de fechas que se encuentran en la colección dentro del margen establecido
   */
  getRangeDates(startDate: Date | null, finishDate: Date | null, dates: Date[] = this.dates): Date[] {
    let result: Date[] = [];

    if (dates.length > 0) {
      if (startDate == null) {
        startDate = dates[0];
      }

      if (finishDate == null) {
        finishDate = dates[dates.length - 1];
      }

      dates.forEach((item) => {
        let tempDate = new Date(item);
        if (tempDate >= startDate && tempDate <= finishDate) {
          result.push(tempDate);
        }
      });
    }

    return result;
  }
}
